import makeStyles from '@mui/styles/makeStyles';
import { gray } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles(theme => ({
  icon: {
    display: 'table',
    margin: '0 auto 16px',

    [theme.breakpoints.down('sm')]: {
      marginBottom: 12,

      '& > svg': {
        width: 72,
        height: 72,
      },
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: 24,

    [theme.breakpoints.down('sm')]: {
      marginBottom: 8,
      fontSize: '1.25rem',
    },
  },
  text: {
    textAlign: 'center',
    margin: '0 auto 32px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  controls: {
    borderTop: `1px solid ${gray[275]}`,
    paddingTop: 24,
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    padding: '6px 30px',
  },
}));
