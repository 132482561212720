import React, { FC, useEffect } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as BigidMeIconErrorGeneric } from '../../assets/icons/icon-whreareyou.svg';

import { useStylesUnexpected } from './ErrorsStyles';
import { setPageTitle } from '../../utils/helpers';

// @TODO: use translation

export const ErrorGeneric: FC = () => {
  const classes = useStylesUnexpected();

  useEffect(() => {
    setPageTitle('Error');
  }, []);

  return (
    <div className={classes.container}>
      <BigidMeIconErrorGeneric />
      <Typography variant="h4" color="textPrimary" className={classes.title}>
        Unexpected error
      </Typography>
    </div>
  );
};
