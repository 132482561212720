import React, { Dispatch, FC, MouseEvent, SetStateAction, useState } from 'react';
import { Typography } from '@mui/material';
import { BigidBIEventData } from '@bigid-ui/utils';
import { BigidMeIconPinCode } from '../../assets/icons/BigidMeIconPinCode';
import { ErrorCodes, ErrorMessages } from '../../constants/errorCodes';
import { LanguageContainer } from '../../state/languageContainer';
import { useStyles } from './BigidMeSmsVerificationStyles';
import { e2e } from '../../utils';
import { BigidMeTextField } from '../BigidMeTextField';
import { MeSubmitButton } from '../MeSubmitButton';
import { PasscodeType } from '../../services';
import { BigidMeIconEmailCode } from '../../assets/icons/BigidMeIconEmailCode';

export interface BigidMeSmsVerificationPropsType {
  loading: boolean;

  errorCode?: ErrorCodes;
  wasDownloadError?: boolean;
  wasResendError?: boolean;

  submitTitle: string;
  submitClick: () => Promise<unknown>;
  sendCode: () => void;
  onCodeChange: Dispatch<SetStateAction<string>>;
  passcodeType: PasscodeType;
  bi: BigidBIEventData;
}

export const BigidMeSmsVerification: FC<BigidMeSmsVerificationPropsType> = ({
  loading,
  sendCode,
  onCodeChange,

  errorCode,
  wasDownloadError,
  wasResendError,

  submitTitle,
  submitClick,
  passcodeType = PasscodeType.SMS,
  bi,
}) => {
  const classes = useStyles();
  const [code, setCode] = useState('');
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  const handleCodeChange = (value: string): void => {
    setCode(value);
    onCodeChange(value);
  };

  const handleResendCode = (e: MouseEvent): void => {
    e.preventDefault();

    if (loading) {
      return;
    }

    setCode('');
    sendCode();
  };

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        {passcodeType === PasscodeType.SMS ? <BigidMeIconPinCode /> : <BigidMeIconEmailCode />}
      </div>
      <Typography variant="h4" color="textPrimary" className={classes.title}>
        {BigIdMeTranslate('consumer_web_sms_security-verification')}
      </Typography>
      <Typography variant="h6" color="textPrimary" fontWeight={400} className={classes.text}>
        {passcodeType === PasscodeType.SMS
          ? BigIdMeTranslate('consumer_web_sms-sent-verification-code-to-device')
          : BigIdMeTranslate('consumer_web_email-sent-verification-code')}
      </Typography>
      <div className={classes.input}>
        <Typography variant="h6" color="textPrimary" fontWeight={400} className={classes.text}>
          {BigIdMeTranslate('consumer_web_sms_enter-code-to-continue')}
        </Typography>
        <BigidMeTextField
          error={errorCode ? BigIdMeTranslate(ErrorMessages[errorCode!]) : ''}
          disabled={loading}
          placeholder={BigIdMeTranslate('consumer_web_sms_enter-verification-code')}
          onChange={handleCodeChange}
          {...e2e(`input_enter-verification-code`)}
        />
      </div>
      <div className={classes.button}>
        {!loading && code && !wasDownloadError && (
          <MeSubmitButton text={submitTitle} onClick={submitClick} type="primary" {...e2e(`btn_verify-code`)} bi={bi} />
        )}
      </div>
      {!wasResendError && !wasDownloadError && (
        <div className={classes.resendWrapper}>
          <Typography variant="subtitle2" color="textPrimary" align="center" gutterBottom>
            {BigIdMeTranslate('consumer_web_sms_didnt-get-code')}{' '}
            <button className={classes.link} onClick={handleResendCode}>
              {BigIdMeTranslate('consumer_web_resend')}
            </button>
          </Typography>
        </div>
      )}
    </div>
  );
};
