import React, { ReactNode } from 'react';
import { RequestStatusTypes } from '../../services';
import { BigidMeIconView } from '../../assets/icons/BigidMeIconView';
import { BigidMeIconUpdate } from '../../assets/icons/BigidMeIconUpdate';
import { BigidMeIconDelete } from '../../assets/icons/BigidMeIconDelete';
import { ReactComponent as BigidMeIconErrorExclamation } from '../../assets/icons/icon-alarm-red.svg';
import { BigidMeIconPreferences } from '../../assets/icons/BigidMeIconPreferences';
import { BigIdMeTranslateType } from '../../state/languageContainer';

export const getIcon = (
  type: RequestStatusTypes,
  status: 'failed' | 'success' | 'manual',
  translate: BigIdMeTranslateType,
): ReactNode => {
  if (status === 'failed') {
    return <BigidMeIconErrorExclamation title={translate('consumer_web_error')} />;
  }

  switch (type) {
    case RequestStatusTypes.VIEW:
      return <BigidMeIconView title={translate('consumer_web_submit_request-view_name')} />;
    case RequestStatusTypes.UPDATE:
      return <BigidMeIconUpdate title={translate('consumer_web_update')} />;
    case RequestStatusTypes.DELETE:
      return <BigidMeIconDelete title={translate('consumer_web_submit_request-delete_name')} />;
    case RequestStatusTypes.PREFERENCES:
      return <BigidMeIconPreferences title={translate('consumer_web_preferences')} />;
    default:
      return <BigidMeIconView title={translate('consumer_web_submit_request-view_name')} />;
  }
};
