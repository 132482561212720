import React, { FC } from 'react';
import { useTheme } from '@mui/material';
import { BigidPrivacyDontShareIllustration } from '@bigid-ui/icons';

import { LanguageContainer } from '../../state/languageContainer';

export const BigidMeIconOptOut: FC<{ title?: string }> = ({ title }) => {
  const theme = useTheme();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  return (
    <BigidPrivacyDontShareIllustration
      fillColor={theme.palette.bigidMe.illustrationColor}
      titleId="optOutTitle"
      title={title || BigIdMeTranslate('brand_requests.OptOut.title') || "Don't Sell/Share"}
    />
  );
};
