import { ErrorCodes } from '../constants/errorCodes';

export const getResponseError = (errorCode: number) => {
  switch (errorCode) {
    case ErrorCodes.APPEAL_REQUEST_DOES_NOT_EXIST:
      return {
        text: 'consumer_web_errors_appeal_request_does_not_exist_with_id',
        withNode: true,
      };

    case ErrorCodes.APPEAL_REQUEST_IS_PROGRESS:
      return {
        text: 'consumer_web_errors_request-in-progress',
      };

    case ErrorCodes.APPEAL_REQUEST_OPEN:
      return {
        text: 'consumer_web_errors_appeal_request_open',
      };

    case ErrorCodes.APPEAL_REQUEST_EXPIRED:
      return {
        text: 'consumer_web_errors_appeal_request_expired_with_id',
        withNode: true,
      };

    case ErrorCodes.APPEAL_REQUEST_CAN_NOT_CREATE:
      return {
        text: 'consumer_web_errors_appeal_request_cannot_be_appealed',
      };

    default:
      return null;
  }
};
