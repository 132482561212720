import { styled } from '@mui/styles';
import { gray } from '../../assets/theme/BigidMeColors';

const PREFIX = 'SendMessage';

export const classes = {
  wrapper: `${PREFIX}-wrapper`,
  messageSubject: `${PREFIX}-messageSubject`,
  sendMessageContainer: `${PREFIX}-sendMessageContainer`,
  inputRoot: `${PREFIX}-inputRoot`,
  uploaderWrapper: `${PREFIX}-uploaderWrapper`,
  filesWrapper: `${PREFIX}-filesWrapper`,
  messageInfo: `${PREFIX}-messageInfo`,
  fileContainer: `${PREFIX}-fileContainer`,
  filesContainer: `${PREFIX}-filesContainer`,
  icon: `${PREFIX}-icon`,
  filesInfo: `${PREFIX}-filesInfo`,
};

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.wrapper}`]: {
    fontSize: '18px',
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0',

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  [`& .${classes.inputRoot}`]: {
    borderRadius: '4px',
    width: '100%',
    minHeight: '100px',
  },
  [`& .${classes.messageSubject}`]: {
    fontWeight: '700',
    marginBottom: '8.5px',
  },
  [`& .${classes.sendMessageContainer}`]: {
    padding: '8px 32px 32px 32px',
    position: 'relative',
    overflow: 'hidden',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '8px 0',
    display: 'flex',
    boxShadow: '0 2px 25px 0 rgba(0,0,0,0.1)',
    borderRadius: 12,
    backgroundColor: '#fff',

    [theme.breakpoints.down('sm')]: {
      padding: 0,
      backgroundColor: '#f6f6f6',
      boxShadow: 'none',
      marginTop: 0,
    },
  },
  [`& .${classes.uploaderWrapper}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    bottom: 29,

    [theme.breakpoints.down('sm')]: {
      '& button': {
        width: '100%',
      },

      '& > button': {
        marginLeft: 10,
      },
    },
  },
  [`& .${classes.filesWrapper}`]: {
    position: 'relative',
    padding: '20px 0',
    borderBottom: `1px solid ${gray[300]}`,
    '& > button': {
      marginTop: 9,
      marginRight: 9,
    },
  },
  [`& .${classes.messageInfo}`]: {
    fontSize: '0.666rem',
  },
  [`& .${classes.fileContainer}`]: {
    '&:hover': {
      opacity: 0.6,
    },
  },
  [`& .${classes.filesContainer}`]: {
    display: 'flex',
    marginBottom: '10px',
  },
  [`& .${classes.icon}`]: {
    height: '9px',
  },
  [`& .${classes.filesInfo}`]: {
    fontSize: '12px',
    marginBottom: 10,
  },
}));
