import React, { FC, useEffect } from 'react';
import { BigidMeWireframe } from '../../components/BigidMeWireframe';
import { TenantContainer } from '../../state/tenantContainer';
import { LanguageContainer } from '../../state/languageContainer';
import { Typography } from '@mui/material';
import { ReactComponent as BigidMeIconErrorGeneric } from '../../assets/icons/icon-whreareyou.svg';

import { useStylesAction } from './ErrorsStyles';
import { e2e } from '../../utils';

export const ErrorAction: FC = () => {
  const { tenant, loadTenantSettings } = TenantContainer.useContainer();
  const { BigIdMeTranslate, BigIdMeTranslateWithNode } = LanguageContainer.useContainer();
  const classes = useStylesAction();

  useEffect(() => {
    loadTenantSettings({});
  }, [loadTenantSettings]);

  return (
    <BigidMeWireframe {...tenant} pageTitle={BigIdMeTranslate('consumer_web_error')}>
      <div className={classes.container} {...e2e('consumer_web_errors_action')}>
        <BigidMeIconErrorGeneric title={BigIdMeTranslate('consumer_web_error')} />
        <Typography variant="h1" color="textPrimary" className={classes.title}>
          {BigIdMeTranslate('consumer_web_errors_action-unavailable')}
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center" className={classes.text}>
          {BigIdMeTranslateWithNode('consumer_web_errors_please-try-again', [
            {
              key: 'contact_us',
              value: <a href={tenant.supportLink}>{BigIdMeTranslate('consumer_web_general_contact')}</a>,
            },
          ])}
        </Typography>
      </div>
    </BigidMeWireframe>
  );
};
