import { useEffect, useState } from 'react';
import {
  AuthorizedAgentType,
  CountriesType,
  RequestDefinition,
  RequestorTypesGetType,
  requestService,
} from '../../../services';
import { userProfileService } from '../../../services/userprofile/userProfileService';
import { UserProfile } from '../../../services/userprofile/types';
import { LanguageContainer } from '../../../state/languageContainer';
import { defaultAuthorizedAgentData } from '../config';

export const useSubmitRequest = ({ publishName, token, employeeToken, integrationName, idToken }: any) => {
  const [countries, setCountries] = useState<CountriesType[]>([]);
  const [userProfiles, setUserProfiles] = useState<UserProfile[]>([]);
  const [requestorTypes, setRequestorTypes] = useState<RequestorTypesGetType>();
  const [requestDefinitions, setRequestDefinitions] = useState<RequestDefinition[]>([]);
  const [authorizedAgent, setAuthorizedAgent] = useState<AuthorizedAgentType>(defaultAuthorizedAgentData);

  const { language } = LanguageContainer.useContainer();

  useEffect(() => {
    if (!language) {
      return;
    }

    requestService
      .getSelectCounties(language)
      .then(resp => {
        setCountries(resp.countries);
      })
      .catch(err => console.log('Todo:', err));
  }, [language]);

  useEffect(() => {
    userProfileService
      .getProfiles(publishName)
      .then(resp => {
        setUserProfiles(resp.profiles);
      })
      .catch(err => console.log('Todo:', err));
  }, [publishName]);

  useEffect(() => {
    if (!language) {
      return;
    }

    requestService
      .getRequestFormByPublishName({ publishName, language, token, employeeToken, integrationName, idToken })
      .then(({ requestorTypes, flows, brandAuthorizedAgent }) => {
        setRequestorTypes(requestorTypes);
        setRequestDefinitions(flows);
        setAuthorizedAgent(brandAuthorizedAgent);
      })
      .catch(err => console.log('Todo:', err));
  }, [publishName, language, token, employeeToken, integrationName, idToken]);

  return {
    countries,
    userProfiles,
    requestorTypes,
    requestDefinitions,
    authorizedAgent,
  };
};
