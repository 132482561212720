import makeStyles from '@mui/styles/makeStyles';
import { white } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    backgroundColor: white,
    height: '100%',
  },
  layout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto 70px',
    maxWidth: 612,
    padding: '10px 32px',
    height: '100%',
  },
  status: {
    textAlign: 'center',
    marginBottom: 24,
  },
  button: {
    marginTop: 36,
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    '& .MuiInputBase-root': {
      width: '190px',
      height: '32px',
      marginTop: '36px !important',
    },
    '& button': {
      marginTop: '24px',
    },
    '& #inputKey': {
      textAlign: 'center',
    },
  },
});
