import makeStyles from '@mui/styles/makeStyles';
import { BigidMeStepperPropsType } from './BigidMeStepper';
import {
  gray,
  stepperActive,
  stepperCompletedInactive,
  stepperInactive,
  white,
} from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles({
  root: ({ isMobile }: Pick<BigidMeStepperPropsType, 'disabled' | 'isMobile'>) => ({
    width: isMobile ? 'auto' : '100%',
    maxWidth: isMobile ? 'auto' : 732,
    minHeight: isMobile ? 241 : 'auto',
    padding: 0,
    margin: '0 auto',
  }),
  subLabel: ({ disabled, isMobile }: Pick<BigidMeStepperPropsType, 'disabled' | 'isMobile'>) => ({
    marginTop: isMobile ? 0 : 7,
    fontSize: isMobile ? '1rem' : '0.75rem',
    fontWeight: disabled ? 600 : 300,
    display: 'block',
    ...(disabled ? { color: stepperInactive } : {}),
  }),
});

export const defaultGorizontalConnectorStyles = ({ disabled }: Pick<BigidMeStepperPropsType, 'disabled'>) => ({
  alternativeLabel: {
    top: 20,
    ...(disabled
      ? {
          left: 'calc(-50% + 15px)',
          right: 'calc(50% + 15px)',
        }
      : {}),
  },
  active: {
    '& $line': {
      backgroundColor: 'unset',
      backgroundImage: `linear-gradient(90deg, ${stepperActive}, ${stepperActive} 33%, transparent 33%, transparent 100%)`,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: disabled ? stepperCompletedInactive : stepperActive,
    },
  },
  line: {
    borderWidth: 0,
    height: 1,
    backgroundColor: gray[300],
    backgroundSize: '6px 1px',
  },
});

export const defaultVericalConnectorStyles = ({ disabled }: Pick<BigidMeStepperPropsType, 'disabled'>) => ({
  alternativeLabel: {
    top: -15,
    left: 8,
    right: 'auto',
  },
  active: {
    '& $line': {
      backgroundColor: stepperActive,
      borderLeftStyle: 'dashed',
      borderColor: white,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: disabled ? stepperCompletedInactive : stepperActive,
    },
  },
  line: {},
});

export const defaultStepLabelStyles = ({
  isMobile,
  disabled,
  hasError,
}: Pick<BigidMeStepperPropsType, 'hasError' | 'disabled' | 'isMobile'>) => ({
  iconContainer: {
    zIndex: 2,
  },
  active: {
    '&$label': {
      color: hasError ? gray[775] : stepperInactive,
      fontWeight: 600,
      '&:lang(ru)': {
        fontWeight: 500,
      },
      '&:lang(ja), &:lang(zh)': {
        fontWeight: 400,
      },
    },
  },
  completed: {
    '&$label': {
      color: disabled ? stepperInactive : gray[775],
      fontWeight: 600,
      '&:lang(ru)': {
        fontWeight: 500,
      },
      '&:lang(ja), &:lang(zh)': {
        fontWeight: 400,
      },
    },
  },
  label: {
    color: stepperInactive,
    fontWeight: 600,
    '&:lang(ru)': {
      fontWeight: 500,
    },
    '&:lang(ja), &:lang(zh)': {
      fontWeight: 400,
    },
    fontSize: '0.8125rem',
  },
  alternativeLabel: {
    '&': {
      flexDirection: isMobile ? 'row !important' : 'column',
    },
    '&$label': {
      marginTop: isMobile ? 0 : 7,
      textAlign: isMobile ? 'left' : 'center',
      fontSize: isMobile ? '1rem' : '0.8125rem',
    },
  },
});
