import makeStyles from '@mui/styles/makeStyles';
import { gray, white } from '../../assets/theme/BigidMeColors';
import { DEFAULT_LOGO_SIZE } from './BigidMeWireframe';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  topBar: ({ logoHeight }: { logoWidth: number; logoHeight: number; logoUrl: string }) => ({
    background: white,
    height: logoHeight,
    minHeight: logoHeight <= DEFAULT_LOGO_SIZE ? 48 : logoHeight + 8,
    borderBottom: '1px solid #D8D8D8',
    display: 'flex',
    alignItems: 'center',
    padding: '7px 48px 7px 48px',
    width: '100%',
    zIndex: 9,
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px',
      textAlign: 'end',
    },
  }),
  logoContainer: ({ logoWidth, logoHeight, logoUrl }: { logoWidth: number; logoHeight: number; logoUrl: string }) => ({
    marginRight: 10,
    width: logoWidth,
    height: logoHeight,
    '&:before': {
      content: '""',
      width: logoWidth,
      height: logoHeight,
      display: 'inline-block',
      backgroundImage: `url(${logoUrl})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  }),
  hiddenLogo: {
    width: 0,
    height: 0,
    paddingRight: 10,
  },
  caption: {
    flexGrow: 1,
    fontSize: '1.125rem',
    fontWeight: 300,
    [theme.breakpoints.down(400)]: {
      fontSize: '1rem',
    },
  },
  container: {
    flex: 1,
  },
  bottomBar: {
    background: gray[100],
    display: 'flex',
    minHeight: '48px',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 48px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-evenly',
      minHeight: '36px',
      padding: 0,
    },
  },
  bottomBarItems: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    textAlign: 'center',
    padding: '0 12px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  grow: {
    flexGrow: 1,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    '& > div:not(:last-child)': {
      position: 'relative',
      paddingRight: 10,
      marginRight: 17,
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-50%)',
        height: 20,
        borderRight: `1px solid ${gray[275]}`,
      },
    },
  },
  locationButtonText: {
    marginLeft: 10,
    paddingTop: 1,
  },
  langIconWrapper: {
    '& > svg': {
      display: 'block',
    },
  },
  langSelectorContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      margin: 0,
    },
  },
  langSelector: {
    width: '125px !important',
    height: '28px !important',
    outline: 'none !important',
    '& [class*=-control]': {
      height: 28,
      minHeight: 28,
      border: 'none !important',
      '& > div': {
        overflow: 'visible',
        '& [class*=-singleValue]': {
          fontSize: '0.8125rem',
          fontWeight: 600,
          '&:lang(ru)': {
            fontWeight: 500,
          },
          '&:lang(ja), &:lang(zh)': {
            fontWeight: 400,
          },
        },
      },
    },
    '& [class*=-menu]': {
      borderRadius: 12,
      boxShadow: '0 6px 23px 0 rgba(0,0,0,0.23)',
      '& > div': {
        paddingBottom: 0,
        paddingTop: 0,
        borderRadius: 12,
        '& > div': {
          fontSize: '0.8125rem',
          padding: '6px 12px',
          '& > span': {
            '&:lang(ja), &:lang(zh)': {
              fontWeight: 300,
            },
          },
        },
      },
    },
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: -20,
  },
  '@global': {
    /* scrollbar custom styles */
    '*::-webkit-scrollbar': {
      width: '4px',
      height: '5px',
    },
    '*::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 4px trasparent`,
      borderRadius: '4px',
    },
    '*::-webkit-scrollbar-thumb': {
      background: gray[275],
      borderRadius: '4px',
    },
    '*::-webkit-scrollbar-thumb:hover': {
      background: gray[725],
    },
  },
}));
