import React, { FC, ReactNode } from 'react';
import { StyledEngineProvider, Button } from '@mui/material';
import { getBIAnalyticsAttributes, BigidBIEventData } from '@bigid-ui/utils';
import { e2e, WithTest } from '../../utils';
import { noop } from 'lodash';
import { CONSUMER_APP_MAIN_MODULE } from '../../state/analyticsContainer';

type ButtonType = 'primary' | 'secondary' | 'tertiary';

export interface MeButtonPropsType extends WithTest {
  text?: string;
  type: ButtonType;
  isDisabled?: boolean;
  classes?: any;
  onClick?: () => void;
  onDoubleClick?: (event?: MouseEvent) => void;
  children?: ReactNode;
  bi: BigidBIEventData;
}

export const MeButton: FC<MeButtonPropsType> = ({
  text,
  type,
  isDisabled,
  classes,
  onDoubleClick,
  onClick,
  children,
  bi,
  ...rest
}) => {
  const styleOverrides = {
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      borderRadius: '20px',
      padding: '6px 16px',
      fontSize: '14px',
      boxShadow:
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      '&.primary:hover': {
        backgroundColor: '#292929',
        opacity: '.8',
        boxShadow: 'none',
      },
      '&.secondary:hover': {
        backgroundColor: '#292929',
        boxShadow: 'none',
        color: '#fff',
      },
      '&:focus': {
        outline: 'auto',
      },
    },
    '&.MuiButton-outlined': {
      padding: '5px 25px',
    },
    '&.MuiButtonBase-root': {
      '&.primary': {
        backgroundColor: '#292929',
        color: '#fff',
      },
      '&.secondary': {
        backgroundColor: '#fff',
        color: '#292929',
        borderColor: '#292929',
        '&:focus': {
          outline: '2px solid #888',
          outlineStyle: 'auto',
        },
      },
      '&.tertiary': {
        backgroundColor: '#fff',
        color: '#292929',
        '&:focus': {
          outline: '2px solid #888',
          outlineStyle: 'auto',
        },
      },
    },
    '&.Mui-disabled': {
      color: '#00000042!important',
      backgroundColor: '#0000001f!important',
      boxShadow: 'none',
    },
  };

  const biAttributes: BigidBIEventData = {
    ...bi,
    eventType: bi?.eventType && `${CONSUMER_APP_MAIN_MODULE}_${bi.eventType}_BUTTON_CLICK`,
  };

  return (
    <StyledEngineProvider injectFirst>
      {type === 'primary' && (
        <Button
          classes={{
            root: classes,
          }}
          sx={styleOverrides}
          variant="contained"
          className={'primary'}
          onClick={onClick}
          onDoubleClick={onDoubleClick || noop}
          disabled={isDisabled}
          disableRipple
          {...getBIAnalyticsAttributes(biAttributes)}
          {...e2e(rest['data-aid'])}
        >
          {text || children}
        </Button>
      )}
      {type === 'secondary' && (
        <Button
          classes={{
            root: classes,
          }}
          sx={styleOverrides}
          variant="outlined"
          className={'secondary'}
          onClick={onClick}
          onDoubleClick={onDoubleClick || noop}
          disabled={isDisabled}
          disableRipple
          {...getBIAnalyticsAttributes(biAttributes)}
          {...e2e(rest['data-aid'])}
        >
          {text || children}
        </Button>
      )}
      {type === 'tertiary' && (
        <Button
          classes={{
            root: classes,
          }}
          sx={styleOverrides}
          className={'tertiary'}
          onClick={onClick}
          onDoubleClick={onDoubleClick || noop}
          disabled={isDisabled}
          disableRipple
          {...getBIAnalyticsAttributes(biAttributes)}
          {...e2e(rest['data-aid'])}
        >
          {text || children}
        </Button>
      )}
    </StyledEngineProvider>
  );
};
