import React, { ElementType, FC } from 'react';
import { Step, StepConnector, StepLabel, Stepper } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Orientation } from '@mui/material/Stepper/Stepper';
import { progressStepStateTypes, ProgressStepType, RequestStatusTypes } from '../../services';
import {
  defaultGorizontalConnectorStyles,
  defaultStepLabelStyles,
  defaultVericalConnectorStyles,
  useStyles,
} from './BigidMeStepperStyles';
import { e2e } from '../../utils';
import { RequestStatus } from '../../services/request/types';
import { BigIdMeTranslateType, LanguageContainer } from '../../state/languageContainer';
import { UserRequestStatus } from '../../constants/userRequestStatus';
import { getActiveStepIndex } from '../../screens/RequestProcessingStatus/utils';
import { ReactComponent as BigidMeIconDisabled } from '../../assets/icons/disabled.svg';
import { ReactComponent as BigidMeIconFailed } from '../../assets/icons/fail.svg';
import { ReactComponent as BigidMeIconWarning } from '../../assets/icons/alarm.svg';
import { ReactComponent as BigidMeIconSuccess } from '../../assets/icons/progress-success.svg';
import { ReactComponent as BigidMeIconSuccessDisabled } from '../../assets/icons/progress-success-disabled.svg';

export interface BigidMeStepperPropsType {
  isMobile?: boolean;
  requestStatus: RequestStatus;
  activeStepIndex: number;
  disabled?: boolean;
  orientation?: Orientation;
  hasError?: boolean;
}

const getIcon = (translate: BigIdMeTranslateType, state?: progressStepStateTypes, disabled?: boolean) => {
  if (disabled) return () => <BigidMeIconSuccessDisabled title={translate('consumer_web_disabled')} />; // eslint-disable-line react/display-name
  switch (state) {
    case 'success':
      return () => <BigidMeIconSuccess title={translate('consumer_web_success')} />; // eslint-disable-line react/display-name
    case 'failed':
      return () => <BigidMeIconFailed title={translate('consumer_web_success')} />; // eslint-disable-line react/display-name
    case 'nodata':
      return () => <BigidMeIconWarning title={translate('consumer_web_failed')} />; // eslint-disable-line react/display-name
    default:
      return () => <BigidMeIconDisabled title={translate('consumer_web_disabled')} />; // eslint-disable-line react/display-name
  }
};

export const BigidMeStepper: FC<BigidMeStepperPropsType> = ({
  isMobile,
  requestStatus,
  activeStepIndex,
  disabled = false,
  orientation = isMobile ? 'vertical' : 'horizontal',
  hasError = false,
}) => {
  const classes = useStyles({ disabled, isMobile });
  const { BigIdMeTranslate, language } = LanguageContainer.useContainer();
  const Connector = withStyles(
    isMobile ? defaultVericalConnectorStyles({ disabled }) : defaultGorizontalConnectorStyles({ disabled }),
  )(StepConnector);
  const StepLabelStyled = withStyles(defaultStepLabelStyles({ isMobile, disabled, hasError }))(StepLabel);
  activeStepIndex = activeStepIndex + (hasError ? 0 : 1);

  const getProgressSteps = (requestStatus: RequestStatus): ProgressStepType[] =>
    requestStatus.history.map((item, index) => {
      const subLabel = item.changeDate
        ? new Date(item.changeDate).toLocaleDateString(language, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        : undefined;
      const getState = (): { state?: progressStepStateTypes } => {
        if (item.status === UserRequestStatus.NoDataFound) {
          return { state: 'nodata' };
        }
        if (
          item.status === UserRequestStatus.IdentityVerificationFailed ||
          item.status === UserRequestStatus.DataModificationDeclined
        ) {
          return { state: 'failed' };
        }
        return getActiveStepIndex(requestStatus) >= index ? { state: 'success' } : {};
      };
      const label =
        requestStatus.requestType == RequestStatusTypes.UPDATE && item.status == UserRequestStatus.InformationProcessed
          ? BigIdMeTranslate(`consumer_web_status_data-retrieved`)
          : BigIdMeTranslate(`consumer_web_status_${item.status.replace(/\s/g, '-').toLowerCase()}`);
      return {
        label,
        subLabel,
        ...getState(),
      };
    });

  return (
    <Stepper
      alternativeLabel
      className={classes.root}
      activeStep={activeStepIndex}
      connector={<Connector />}
      orientation={orientation}
    >
      {getProgressSteps(requestStatus).map(({ label, subLabel, state }, idx) => (
        <Step key={label}>
          <StepLabelStyled StepIconComponent={getIcon(BigIdMeTranslate, state, disabled) as ElementType}>
            <span {...e2e(`progress_item-${idx}`)}>{label}</span>
            {subLabel && (
              <span className={classes.subLabel} {...e2e(`progress_item_date-${idx}`)}>
                {subLabel}
              </span>
            )}
          </StepLabelStyled>
        </Step>
      ))}
    </Stepper>
  );
};
