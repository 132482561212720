import React, { Dispatch, FC, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useParams } from 'react-router-dom';
import { Stack, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import cx from 'classnames';
import queryString from 'query-string';

import {
  AuthorizedAgentFieldNameType,
  AuthorizedAgentPhoneSubmitPostType,
  AuthorizedAgentType,
  CountriesType,
  DeliveryMethodType,
  FieldDefinition,
  FieldsType,
  FieldValidation,
  FieldValue,
  RequestAuthorizedAgentFormStep,
  RequestData,
  RequestDefinition,
  RequestFormStep,
  RequestorTypeGetType,
  RequestorTypesGetType,
  RequestorTypeType,
  requestService,
  RequestStatusTypes,
  UserFilesType,
  UserPhoneSubmitPostType,
  UserPhoneType,
} from '../../services';
import { BigidMeWireframe } from '../../components/BigidMeWireframe';
import { TenantContainer } from '../../state/tenantContainer';
import { LanguageContainer } from '../../state/languageContainer';
import { BigidMeThumbnail } from '../../components/BigidMeThumbnail';
import { ReactComponent as BigidMeIconStepActive } from '../../assets/icons/cur-step.svg';
import { ReactComponent as BigidMeIconStepCompleted } from '../../assets/icons/success.svg';
import { ReactComponent as BigidMeIconEditPencil } from '../../assets/icons/edit.svg';
import { ReactComponent as BigidMeStepInactive } from '../../assets/icons/icon-step-disabled.svg';
import { ReactComponent as BigidMeIconUser } from '../../assets/icons/icon-user.svg';
import { ReactComponent as BigidMeIconPolicy } from '../../assets/icons/icon-policy.svg';

import { BigidMeAllowOutsideRequestors, BigidMeRequestStatusSubmitted } from '../../components/BigidMeRequestStatus';
import { AnalyticEventType, e2e, getRequestPublicInformation } from '../../utils';
import { RedirectUrls } from '../../constants/routes';
import { ErrorCodes } from '../../constants/errorCodes';
import {
  BigidMeSessionController,
  BigidMeSessionEffect,
  SessionClosingStages,
} from '../../components/BigidMeSessionController';
import { UserProfile } from '../../services/userprofile/types';
import { signInService } from '../../services/sign-in/signinService';
import { SignInType } from '../../services/sign-in/types';
import { VerificationProviderEnum } from '../../services/verification-questionnaire/types';
import { MePageHeader } from '../../components/MePageHeader/MePageHeader';

import { ResponsiveStepperConnector, useStyles } from './SubmitRequestStyles';
import { UserDetails } from './UserDetails';
import { MeButton, MeSubmitButton } from '../../components';
import { DEFAULT_DELIVERY_METHOD } from './DeliveryMethod';
import { BigidMeIconView } from '../../assets/icons/BigidMeIconView';
import { BigidMeIconUpdate } from '../../assets/icons/BigidMeIconUpdate';
import { BigidMeIconDelete } from '../../assets/icons/BigidMeIconDelete';
import { BigidMeIconPreferences } from '../../assets/icons/BigidMeIconPreferences';
import { BigidMeIconOptOut } from '../../assets/icons/BigidMeIconOptOut';
import {
  mergeAgentDefaultValues,
  mergeDefaultValues,
  normalizeAgentDefaultFields,
  normalizeDefaultFields,
} from './DeliveryMethod/utils';
import { useSetPageTitle } from './useSetPageTitle';
import { BigidMeIconAppeal } from '../../assets/icons/BigidMeIconAppeal';
import { BigidMeIconRequestorTypeSelf } from '../../assets/icons/BigidMeIconRequestorTypeSelf';
import { BigidMeIconRequestorTypeAuthorizedAgent } from '../../assets/icons/BigidMeIconRequestorTypeAgent';
import { AuthorizedAgent } from './AuthorizedAgent';
import { isAuthAgentFieldEnabledDefault, isUserFieldEnabledDefault } from '../../utils/submitFields';
import { HttpStatusCodes } from '../../constants/httpStatusCodes';
import { useUserDetails } from './hooks/useUserDetails';
import { useAuthorizedAgent } from './hooks/useAutorizedAgent';
import { ReactComponent as BigidMeIconLock } from '../../assets/icons/lock.svg';
import { normalizeCountriesResponceToOptions } from '../../utils/data-normalizer';
import { defaultAuthorizedAgentData } from './config';
import { analyticsService } from '../../state/analyticsContainer';
import { isEmpty } from 'lodash';

enum ConfirmationEmailResendResult {
  Successful,
  Limit,
}

export type SubmitRequestType = {
  navigate: NavigateFunction;
  action?: string;
  requestorTypeAction?: string;
  countries: CountriesType[];
  userProfiles: UserProfile[];
  requestorTypes?: RequestorTypesGetType;
  requestDefinitions: RequestDefinition[];
  authorizedAgent?: AuthorizedAgentType;
  isPreview?: boolean;
  isUserDetailsPreFilled?: boolean;
} & (
  | {
      isPreview?: false | undefined;
      fieldDefinitionsForPreview?: never;
      activeStepForPreview?: never;
      brandRequestNameForPreview?: never;
      previewRequestTypeHover?: never;
      expandAllSteps?: never;
    }
  | {
      // fields are using only for preview
      isPreview: true;
      fieldDefinitionsForPreview: FieldDefinition[];
      activeStepForPreview: RequestFormStep | RequestAuthorizedAgentFormStep;
      brandRequestNameForPreview?: string;
      previewRequestTypeHover?: boolean;
      expandAllSteps?: boolean;
    }
);

const emailFieldId = 'userEmail';
const phoneFieldId = 'userPhone';

export const SubmitRequest: FC<SubmitRequestType> = ({
  countries,
  userProfiles,
  requestorTypes,
  requestDefinitions,
  authorizedAgent = defaultAuthorizedAgentData,
  navigate,
  action,
  requestorTypeAction,
  isPreview = false,
  fieldDefinitionsForPreview,
  activeStepForPreview,
  brandRequestNameForPreview,
  previewRequestTypeHover,
  expandAllSteps,
  isUserDetailsPreFilled = true,
}) => {
  const { publishName } = useParams();
  const { tenant, loadTenantSettings } = TenantContainer.useContainer();
  const { BigIdMeTranslate, BigIdMeTranslateWithNode, language } = LanguageContainer.useContainer();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedRequestorType, setSelectedRequestorType] = useState<RequestorTypeGetType>();
  const [selectedRequestDefinition, setSelectedRequestDefinition] = useState<RequestDefinition>();
  const [fieldDefinitions, setFieldDefinitions] = useState<FieldDefinition[]>([]);
  const [request, setRequest] = useState<RequestData>();
  const [confirmationEmailResendResult, setConfirmationEmailResendResult] = useState<ConfirmationEmailResendResult>();
  const [validation, setValidation] = useState<FieldValidation>();
  const [fieldValues, setFieldValues] = useState<Record<string, FieldValue | UserFilesType>>({});
  const [touchedFields, setTouchedFields] = useState<Record<string, boolean>>({});
  const [userFiles, setUserFiles] = useState<UserFilesType[]>([]);
  const [signIn, setSignIn] = useState<SignInType>();
  const [requestedAction, setRequestedAction] = useState<string | undefined>();
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const [authorizedAgentFieldValues, setAuthorizedAgentFieldValues] = useState<
    Record<string, FieldValue | UserFilesType>
  >({});
  const [authorizedAgentTouchedFields, setAuthorizedAgentTouchedFields] = useState<Record<string, boolean>>({});
  const [authorizedAgentFiles, setAuthorizedAgentFiles] = useState<UserFilesType[]>([]);
  const [authorizedAgentValidation, setAuthorizedAgentValidation] = useState<FieldValidation>();

  const classes = useStyles();

  const isRequestorTypesEnabled =
    requestorTypes?.enabled && !!requestDefinitions.filter(item => item.authorizedAgentEnabled).length;

  const requestFormStep = isRequestorTypesEnabled
    ? {
        SetRequestorType: 0,
        SetDefinition: 1,
        SetDetails: 2,
      }
    : {
        SetDefinition: 0,
        SetDetails: 1,
      };

  const thumbnailIconsMap = new Map<string, ReactNode>([
    [RequestStatusTypes.VIEW, <BigidMeIconView key={'view'} />],
    [RequestStatusTypes.UPDATE, <BigidMeIconUpdate key={'update'} />],
    [RequestStatusTypes.DELETE, <BigidMeIconDelete key={'delete'} />],
    [RequestStatusTypes.PREFERENCES, <BigidMeIconPreferences key={'preferences'} />],
    [RequestStatusTypes.OPT_OUT, <BigidMeIconOptOut key={'optOut'} />],
    [RequestStatusTypes.APPEAL, <BigidMeIconAppeal key={'appeal'} />],
    [RequestorTypeType.SELF, <BigidMeIconRequestorTypeSelf key={'self'} />],
    [RequestorTypeType.AUTHORIZED_AGENT, <BigidMeIconRequestorTypeAuthorizedAgent key={'authorizedAgent'} />],
    ['Default', <BigidMeIconView key={'default'} />],
  ]);

  const {
    sessionClosingStage,
    setSessionClosingStage,
  }: {
    sessionClosingStage: SessionClosingStages;
    setSessionClosingStage: Dispatch<SetStateAction<SessionClosingStages>>;
  } = BigidMeSessionEffect({
    tenantId: tenant?.tenantId,
    navigate,
    startOver: true,
    isPreview,
    publishName,
  });

  const countriesOptions = useMemo(() => normalizeCountriesResponceToOptions(countries), [countries]);

  const { validateFields: validateUserFields } = useUserDetails({
    countriesOptions,
    userProfiles,
    fieldValues,
    fieldDefinitions,
  });

  const { validateFields: validateAuthorizedAgentField } = useAuthorizedAgent({
    fields: authorizedAgent?.fields || [],
    fieldValues: authorizedAgentFieldValues,
    countriesOptions,
    layout: authorizedAgent?.layout,
  });

  useEffect(() => {
    if (requestorTypes && !isRequestorTypesEnabled) {
      setSelectedRequestorType(undefined);
    }
  }, [isRequestorTypesEnabled, setSelectedRequestorType, requestorTypes]);

  useEffect(() => {
    !tenant?.isPreviewPP && loadTenantSettings({ publishName, language, navigate });
  }, [publishName, tenant?.isPreviewPP, language, loadTenantSettings, navigate]);

  useEffect(() => {
    if (!language || !isPreview) {
      return;
    }

    setSelectedRequestDefinition(
      brandRequestNameForPreview
        ? requestDefinitions.find((d: RequestDefinition) => d.id === brandRequestNameForPreview)
        : requestDefinitions[0],
    );
    setSelectedRequestorType(
      authorizedAgent
        ? requestorTypes?.requestorTypes.find(i => i.requestorType === RequestorTypeType.AUTHORIZED_AGENT)
        : requestorTypes?.requestorTypes.find(i => i.requestorType === RequestorTypeType.SELF),
    );
    if (fieldDefinitionsForPreview) {
      setFieldDefinitions(fieldDefinitionsForPreview);
      setFieldValues(
        mergeDefaultValues({
          fields: fieldDefinitionsForPreview,
          language,
          smsValidationOn: tenant.smsValidationOn,
          countriesOptions,
        }),
      );
    }
    setAuthorizedAgentFieldValues(
      mergeAgentDefaultValues({
        fields: authorizedAgent?.fields || [],
        language,
        smsValidationOn: tenant.smsValidationOn,
        countriesOptions,
      }),
    );

    // Do not add dispatchers to dependencies, as for previewDataPP they used as common state setters
  }, [
    isPreview,
    brandRequestNameForPreview,
    fieldDefinitionsForPreview,
    language,
    isRequestorTypesEnabled,
    tenant.smsValidationOn,
  ]);

  useEffect(() => {
    setTouchedFields({});
    setAuthorizedAgentTouchedFields({});
  }, [language]);

  useEffect(() => {
    isPreview && activeStepForPreview !== undefined && setActiveStep(activeStepForPreview);
  }, [isPreview, activeStepForPreview, setActiveStep]);

  useEffect(() => {
    if (selectedRequestDefinition?.submitFormId) {
      signInService
        .getSignIn(selectedRequestDefinition.submitFormId)
        .then(signIn => setSignIn(signIn))
        .catch(err => console.log('Todo:', err));
    }
  }, [selectedRequestDefinition]);

  useEffect(() => {
    if (isPreview) {
      return;
    }

    const selectedDef = requestDefinitions.find(d => d.id === selectedRequestDefinition?.id);
    if (selectedDef && activeStep === requestFormStep.SetDetails) {
      handleRequestDefinitionSelected(selectedDef);
    }
    //Do not add dispatchers to dependencies, as for previewDataPP they used as common state setters
  }, [isPreview, requestDefinitions, activeStep, requestFormStep.SetDetails, selectedRequestDefinition?.id]);

  useEffect(() => {
    if (isPreview) {
      return;
    }

    const requestorType = requestorTypes?.requestorTypes.find(
      rt => rt.requestorType === selectedRequestorType?.requestorType,
    );

    if (isRequestorTypesEnabled && requestorType) {
      setSelectedRequestorType(requestorType);
    }
  }, [isPreview, requestorTypes, isRequestorTypesEnabled, selectedRequestorType, setSelectedRequestorType]);

  useSetPageTitle({
    enable: !isPreview, // no need to set title if component is using on privacy portal as preview
    isDefault: activeStep === requestFormStep.SetDefinition,
    type: selectedRequestDefinition?.id as RequestStatusTypes,
  });

  const handleRequestDefinitionSelected = (definition: RequestDefinition) => {
    setSelectedRequestDefinition(definition);
    const { defaultFieldNormalized, fieldValuesWithDefaults, fieldValidation } = normalizeDefaultFields({
      fields: definition.fields,
      language,
      smsValidationOn: tenant.smsValidationOn,
      translate: BigIdMeTranslate,
      countriesOptions,
    });
    setFieldValues(fieldValuesWithDefaults);
    setValidation(fieldValidation);
    setFieldDefinitions(defaultFieldNormalized);
    setActiveStep(requestFormStep.SetDetails);

    return Promise.resolve(definition.fields);
  };

  const handleRequestorTypeSelected = (requestorType: RequestorTypeGetType) => {
    setSelectedRequestorType(requestorType);

    const { fieldValuesWithDefaults, fieldValidation } = normalizeAgentDefaultFields({
      fields: authorizedAgent.fields,
      language,
      smsValidationOn: tenant.smsValidationOn,
      translate: BigIdMeTranslate,
      countriesOptions,
    });

    setAuthorizedAgentFieldValues(fieldValuesWithDefaults);
    setAuthorizedAgentValidation(fieldValidation);

    return Promise.resolve(requestorType);
  };

  useEffect(() => {
    const requestorType = requestorTypes?.requestorTypes.find(i => i.requestorType === requestorTypeAction);
    const definition = requestDefinitions.find(definition => definition.id.toLowerCase() === action?.toLowerCase());
    if (action && requestorTypeAction) {
      if (definition && requestorType) {
        handleRequestorTypeSelected(requestorType);
        handleRequestDefinitionSelected(definition);
      }
    }
    if (action && !requestorTypeAction) {
      if (definition) {
        handleRequestDefinitionSelected(definition);
      }
    }
    if (requestorTypeAction && !action) {
      if (requestorType) {
        handleRequestorTypeSelected(requestorType);
        setActiveStep(requestFormStep.SetDefinition);
      }
    }
  }, [action, requestDefinitions, requestorTypeAction, requestorTypes?.requestorTypes]);

  const handleError = (errorCode: ErrorCodes, fields?: Record<string, unknown>) => {
    if (errorCode === ErrorCodes.DUPLICATE_REQUEST || errorCode === ErrorCodes.APPEAL_REQUEST_IS_PROGRESS) {
      const requestIdentifier =
        fieldValues.deliveryMethod === DeliveryMethodType.EMAIL
          ? fieldValues[emailFieldId]
          : (fieldValues[phoneFieldId] as UserPhoneType)?.phone;
      if (requestIdentifier) {
        navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}${RedirectUrls.DUPLICATE_REQUEST}/${requestIdentifier}`);
      } else {
        navigate(
          `/${tenant.tenantId}${RedirectUrls.REQUEST_ERROR}/${errorCode}${
            fields ? `?${queryString.stringify(fields)}` : ''
          }`,
        );
      }
      return;
    }
    if (errorCode === ErrorCodes.ACTION_UNAVAILABLE) {
      navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}${RedirectUrls.ACTION_ERROR}`);
      return;
    }
    navigate(
      `/${tenant.tenantId}${RedirectUrls.REQUEST_ERROR}/${errorCode}${
        fields ? `?${queryString.stringify(fields)}` : ''
      }`,
    );
  };

  const isAuthorizedAgentEnabled =
    isRequestorTypesEnabled &&
    selectedRequestDefinition?.authorizedAgentEnabled &&
    selectedRequestorType?.requestorType === RequestorTypeType.AUTHORIZED_AGENT;

  const handleSubmitRequest = useCallback(async () => {
    const validationUserFieldsResult = validateUserFields(fieldValues);
    const validationAutorizedAgentFieldsResult = validateAuthorizedAgentField(authorizedAgentFieldValues);
    if (!validationUserFieldsResult.valid) {
      setValidation(validationUserFieldsResult);
      return Promise.resolve();
    }

    if (isAuthorizedAgentEnabled && !validationAutorizedAgentFieldsResult.valid) {
      setAuthorizedAgentValidation(validationAutorizedAgentFieldsResult);
      return Promise.resolve();
    }

    const userFields = fieldDefinitions
      .filter(field => isUserFieldEnabledDefault({ field, fieldDefinitions, fieldValues }))
      .map(fd => {
        if (fd.id === FieldsType.USER_PHONE && typeof fieldValues[fd.id] === 'object') {
          return {
            id: FieldsType.USER_PHONE,
            value: (fieldValues[fd.id] as UserPhoneType).phone,
            validationId: (fieldValues[fd.id] as UserPhoneType).validationId,
          } as UserPhoneSubmitPostType;
        }
        if (fd.id === FieldsType.USER_PHOTO && typeof fieldValues[fd.id] === 'object') {
          return {
            id: FieldsType.USER_PHOTO,
            value: (fieldValues[fd.id] as UserFilesType).uploadId,
          };
        }
        return {
          id: fd.id,
          value: fieldValues[fd.id] || '',
        };
      })
      .filter(a => a.value !== '');

    const authorizedAgentFields = authorizedAgent?.fields
      .filter(field => isAuthAgentFieldEnabledDefault({ field, fieldValues: authorizedAgentFieldValues }))
      .map(field => {
        if (
          (field.name === AuthorizedAgentFieldNameType.MOBILE_PHONE_NUMBER ||
            field.name === AuthorizedAgentFieldNameType.PHONE_NUMBER) &&
          typeof authorizedAgentFieldValues[field.name] === 'object'
        ) {
          return {
            id: field.name,
            value: (authorizedAgentFieldValues[field.name] as UserPhoneType).phone,
            validationId: (authorizedAgentFieldValues[field.name] as UserPhoneType).validationId,
          } as AuthorizedAgentPhoneSubmitPostType;
        }
        if (
          field.name === AuthorizedAgentFieldNameType.UPLOAD_DOCUMENTS &&
          typeof authorizedAgentFieldValues[field.name] === 'object'
        ) {
          return {
            id: AuthorizedAgentFieldNameType.UPLOAD_DOCUMENTS,
            value: (authorizedAgentFieldValues[field.name] as UserFilesType).uploadId,
          };
        }
        return {
          id: field.name,
          value: authorizedAgentFieldValues[field.name] || '',
        };
      })
      .filter(a => a.value !== '');

    try {
      if (selectedRequestDefinition) {
        const submitRequest = await requestService.submitRequest({
          definitionId: selectedRequestDefinition.id,
          fields: userFields,
          userLocale: language,
          captchaToken,
          userFiles,
          publishName: publishName || '',
          requestorType: selectedRequestorType?.requestorType,
          requestorFields:
            selectedRequestorType?.requestorType === RequestorTypeType.AUTHORIZED_AGENT ? authorizedAgentFields : [],
          requestorFiles: authorizedAgentFiles,
          submitFormId: selectedRequestDefinition.submitFormId,
        });

        setRequest({
          id: submitRequest.id,
          rc: submitRequest.rc,
          emailConfirmationRequired: submitRequest.emailConfirmationRequired,
          deliveryMethod: fieldDefinitions.filter(a => a.id === 'deliveryMethod').map(a => fieldValues[a.id])[0],
          onScreenMessage: submitRequest.onScreenMessage,
          requestKey: submitRequest.requestKey,
          emailConfirmationRecipient: submitRequest.emailConfirmationRecipient,
        });
        setRequestedAction(selectedRequestDefinition.id);

        analyticsService.trackManualEvent(AnalyticEventType.CreateRequest, {
          requestId: submitRequest.id,
          language,
          requestType: selectedRequestDefinition.id,
          ...getRequestPublicInformation(userFields),
        });

        return submitRequest;
      }
    } catch (e: any) {
      if (e?.response?.data.errorCode) {
        handleError(e.response.data.errorCode, {
          id: fieldValues.originalRequestId,
        });
        return;
      }

      switch (e?.response?.status) {
        case HttpStatusCodes.BAD_REQUEST: // 400
          handleError(ErrorCodes.DUPLICATE_REQUEST);
          break;
        default:
          handleError(ErrorCodes.ACTION_UNAVAILABLE);
      }

      return Promise.resolve();
    } finally {
      setActiveStep(requestFormStep.SetDefinition);
      setSelectedRequestDefinition(undefined);
    }
  }, [
    authorizedAgent?.fields,
    authorizedAgentFieldValues,
    authorizedAgentFiles,
    captchaToken,
    fieldDefinitions,
    fieldValues,
    isRequestorTypesEnabled,
    language,
    publishName,
    selectedRequestDefinition,
    selectedRequestorType?.requestorType,
    setActiveStep,
    handleError,
    setSelectedRequestDefinition,
    userFiles,
    validateAuthorizedAgentField,
    validateUserFields,
  ]);

  useEffect(() => {
    if (!fieldValues?.deliveryMethod) {
      setFieldValues(prevState => ({ ...prevState, [FieldsType.DELIVERY_METHOD]: DEFAULT_DELIVERY_METHOD }));
    }
  }, [fieldValues]);

  const handleStartOver = (): void => {
    if (activeStep === requestFormStep.SetDefinition && requestFormStep?.SetRequestorType !== undefined) {
      setActiveStep(requestFormStep?.SetRequestorType);
      setSelectedRequestorType(undefined);
    } else {
      setActiveStep(requestFormStep.SetDefinition);
    }

    setSelectedRequestDefinition(undefined);
    setRequest(undefined);
    setTouchedFields({});
  };

  const handleStartOverRequestorType = (): void => {
    setActiveStep(requestFormStep.SetRequestorType !== undefined ? requestFormStep.SetRequestorType : 0);
    setSelectedRequestorType(undefined);
    setSelectedRequestDefinition(undefined);
    setRequest(undefined);
    setTouchedFields({});
  };

  const handleResend = () => {
    request &&
      requestService
        .resendConfirmationEmail(request.id)
        .then()
        .catch(err => {
          switch (err?.response?.data?.errorCode) {
            case ErrorCodes.EMAIL_RESEND_LIMIT:
              setConfirmationEmailResendResult(ConfirmationEmailResendResult.Limit);
              break;
            default:
              navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}`);
          }
        });
  };

  const renderUserDetails = () => (
    <div className={classes.container}>
      <UserDetails
        resetSelect={isPreview}
        fieldDefinitions={fieldDefinitions}
        signIn={signIn}
        validation={validation}
        setValidation={setValidation}
        fieldValues={fieldValues}
        setFieldValues={setFieldValues}
        countriesOptions={countriesOptions}
        userProfiles={userProfiles}
        touchedFields={touchedFields}
        setTouchedFields={setTouchedFields}
        selectedRequestDefinition={selectedRequestDefinition}
        setUserFiles={setUserFiles}
        userFiles={userFiles}
        setCaptchaToken={setCaptchaToken}
        withAuthorizedAgent={!!isAuthorizedAgentEnabled}
      />
      <div className={classes.wrapperSubmit}>
        <MeSubmitButton
          isDisabled={
            (isAuthorizedAgentEnabled && !authorizedAgentValidation?.valid) ||
            !validation?.valid ||
            (!captchaToken && tenant.captchaOn) ||
            !!request ||
            (isEmpty(touchedFields) && !isUserDetailsPreFilled)
          }
          onClick={handleSubmitRequest}
          type="primary"
          text={BigIdMeTranslate('consumer_web_submit_request-preferences_submit')}
          classes={classes.submitButton}
          {...e2e(`btn_submit`)}
          bi={{ eventType: 'submit_request' }}
        />
        <div className={cx(classes.helperText, classes.submitButtonHelperText)}>
          <BigidMeIconLock title={BigIdMeTranslate('consumer_web_secure')} />
          <Typography fontSize="0.813rem" fontWeight={300}>
            {BigIdMeTranslate('consumer_web_submit_we-secure')}
          </Typography>
        </div>
      </div>
    </div>
  );

  const renderAuthorizedAgent = () => (
    <AuthorizedAgent
      resetSelect={isPreview}
      authorizedAgent={authorizedAgent}
      validation={authorizedAgentValidation}
      setValidation={setAuthorizedAgentValidation}
      fieldValues={authorizedAgentFieldValues}
      setFieldValues={setAuthorizedAgentFieldValues}
      countriesOptions={countriesOptions}
      touchedFields={authorizedAgentTouchedFields}
      setTouchedFields={setAuthorizedAgentTouchedFields}
      authorizedAgentFiles={authorizedAgentFiles}
      setAuthorizedAgentFiles={setAuthorizedAgentFiles}
    />
  );

  const renderRequestStatus = () =>
    request &&
    requestedAction && (
      <BigidMeRequestStatusSubmitted
        email={request.emailConfirmationRecipient}
        contactLinkUrl={tenant?.supportLink}
        requestedAction={requestedAction}
        resendExceeded={confirmationEmailResendResult === ConfirmationEmailResendResult.Limit}
        onResend={handleResend}
        useEmailConfirmation={true}
        translate={BigIdMeTranslate}
        translateWithNode={BigIdMeTranslateWithNode}
      />
    );

  useEffect(() => {
    if (!request || !navigate || request.onScreenMessage || request.emailConfirmationRequired) {
      return;
    }

    if (
      selectedRequestDefinition?.id !== RequestStatusTypes.PREFERENCES &&
      selectedRequestDefinition?.id !== RequestStatusTypes.OPT_OUT
    ) {
      if (signIn?.verificationProvider === VerificationProviderEnum.EXPERIAN) {
        navigate(`/${tenant.tenantId}/${request.id}/verification/experian/${request.rc}`);
        return;
      }
      if (signIn?.verificationProvider === VerificationProviderEnum.LEXIS_NEXIS) {
        navigate(`/${tenant.tenantId}/${request.id}/verification/lexisnexis/${request.rc}`);
        return;
      }
    }

    navigate(`/${tenant.tenantId}/${request.id}/status/${request.rc}?skip=true`);
  }, [request, tenant, navigate, selectedRequestDefinition, signIn?.verificationProvider]);

  // auto reject
  if (request && request.onScreenMessage) {
    return (
      <BigidMeWireframe {...tenant}>
        <BigidMeAllowOutsideRequestors onScreenMessage={request.onScreenMessage.translations} />
      </BigidMeWireframe>
    );
  }

  return (
    <BigidMeWireframe {...tenant}>
      {!request ? (
        <div>
          <div className={classes.navigationMobile}>
            <div>
              {activeStep > 0 && (
                <MeButton
                  type="tertiary"
                  aria-label="Back"
                  classes={classes.mobileBackButton}
                  onClick={handleStartOver}
                  bi={{ eventType: 'mobile_submit_request_back' }}
                >
                  <ArrowBackIcon />
                  {BigIdMeTranslate('consumer_web_back')}
                </MeButton>
              )}
            </div>
            <div>
              {BigIdMeTranslateWithNode('consumer_web_active_step', [
                { key: 'current', value: activeStep + 1 },
                { key: 'total', value: isRequestorTypesEnabled ? 3 : 2 },
              ])}
            </div>
          </div>
          <MePageHeader />
          <div className={classes.stepperWrapper}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              classes={{ root: classes.stepper }}
              connector={<ResponsiveStepperConnector />}
            >
              {isRequestorTypesEnabled && (
                <Step
                  key={requestFormStep.SetRequestorType}
                  completed={
                    requestFormStep?.SetRequestorType !== undefined
                      ? activeStep > requestFormStep.SetRequestorType
                      : false
                  }
                >
                  <StepLabel
                    onClick={handleStartOverRequestorType}
                    classes={{ root: classes.stepLabelRoot }}
                    icon={
                      activeStep === requestFormStep.SetRequestorType ? (
                        <BigidMeIconStepActive />
                      ) : (
                        <BigidMeIconStepCompleted title={BigIdMeTranslate('consumer_web_completed_step')} />
                      )
                    }
                    onKeyPress={handleStartOverRequestorType}
                  >
                    {activeStep === requestFormStep.SetRequestorType ? (
                      <Typography variant="h3" color="textPrimary" className={classes.stepHeader}>
                        <span className={classes.extraBold}>
                          {BigIdMeTranslate('consumer_web_submit_a_request_for')}
                        </span>
                      </Typography>
                    ) : (
                      <Typography
                        variant="h3"
                        className={classes.stepHeader}
                        {...e2e(`pencil-edit-agent-action`)}
                        tabIndex={0}
                      >
                        <span>{BigIdMeTranslate('consumer_web_submit_a_request_for')}: </span>
                        {selectedRequestorType?.requestorType === RequestorTypeType.SELF && (
                          <div className={classes.requestorTypeSelf}>
                            <BigidMeIconUser title={BigIdMeTranslate('consumer_web_myself')} />
                            {selectedRequestorType?.translations[language]?.title}
                          </div>
                        )}
                        {selectedRequestorType?.requestorType === RequestorTypeType.AUTHORIZED_AGENT && (
                          <div className={classes.requestorTypeAgent}>
                            <BigidMeIconPolicy title={BigIdMeTranslate('consumer_web_authorized_agent')} />
                            {selectedRequestorType?.translations[language]?.title}
                          </div>
                        )}
                        <BigidMeIconEditPencil />
                      </Typography>
                    )}
                  </StepLabel>
                  <StepContent classes={{ root: cx(classes.requestThumbnailsWrapper, classes.stepContent) }}>
                    <div className={cx(classes.wrapperFlex, classes.requestThumbnails)}>
                      {requestorTypes?.requestorTypes.map(rt => (
                        <BigidMeThumbnail
                          key={rt.id}
                          title={rt.translations[language]?.title}
                          tooltip={rt.translations[language]?.description}
                          icon={thumbnailIconsMap.get(rt.requestorType) || thumbnailIconsMap.get('default')}
                          onClick={() => {
                            setActiveStep(requestFormStep.SetDefinition);
                            return handleRequestorTypeSelected(rt);
                          }}
                          id={String(rt.requestorType)}
                          testId={String(rt.requestorType)}
                          bi={{ eventType: `thumbnail_${rt.requestorType}` }}
                        />
                      ))}
                    </div>
                  </StepContent>
                </Step>
              )}
              <Step
                expanded={expandAllSteps}
                key={requestFormStep.SetDefinition}
                completed={activeStep > requestFormStep.SetDefinition}
              >
                <StepLabel
                  onClick={activeStep > requestFormStep.SetDefinition ? handleStartOver : undefined}
                  classes={{ root: classes.stepLabelRoot }}
                  icon={
                    activeStep < requestFormStep.SetDefinition ? (
                      <BigidMeStepInactive />
                    ) : activeStep === requestFormStep.SetDefinition ? (
                      <BigidMeIconStepActive />
                    ) : (
                      <BigidMeIconStepCompleted title={BigIdMeTranslate('consumer_web_completed_step')} />
                    )
                  }
                  onKeyPress={activeStep > requestFormStep.SetDefinition ? handleStartOver : undefined}
                >
                  {activeStep < requestFormStep.SetDetails ? (
                    <Typography
                      variant="h3"
                      color="textPrimary"
                      className={cx(classes.stepHeader, {
                        [classes.extraBold]: activeStep === requestFormStep.SetDefinition,
                      })}
                    >
                      {BigIdMeTranslate('consumer_web_submit_select-action')}
                    </Typography>
                  ) : (
                    <Typography
                      variant="h3"
                      color="textPrimary"
                      className={classes.stepHeader}
                      {...e2e(`pencil-edit-action`)}
                      tabIndex={0}
                    >
                      <Stack flexDirection="row" alignItems="center">
                        <span>{BigIdMeTranslate('consumer_web_submit_select-action')}:</span>
                        <span className={classes.italic}>
                          {selectedRequestDefinition?.translations[language]?.title || ''}
                        </span>
                        <BigidMeIconEditPencil />
                      </Stack>
                    </Typography>
                  )}
                </StepLabel>
                <StepContent classes={{ root: cx(classes.requestThumbnailsWrapper, classes.stepContent) }}>
                  <div className={cx(classes.wrapperFlex, classes.requestThumbnails)}>
                    {requestDefinitions
                      .filter(item =>
                        isRequestorTypesEnabled &&
                        selectedRequestorType?.requestorType === RequestorTypeType.AUTHORIZED_AGENT
                          ? item.authorizedAgentEnabled
                          : true,
                      )
                      .map((definition, index) => (
                        <BigidMeThumbnail
                          key={definition.id}
                          title={definition.translations[language]?.title || ''}
                          tooltip={definition.translations[language]?.description || ''}
                          icon={thumbnailIconsMap.get(definition.id) || thumbnailIconsMap.get('default')}
                          onClick={() => handleRequestDefinitionSelected(definition)}
                          id={definition.id}
                          testId={definition.id}
                          hoverPreview={previewRequestTypeHover && index === 0}
                          bi={{ eventType: `thumbnail_${definition.id}` }}
                        />
                      ))}
                  </div>
                </StepContent>
              </Step>
              <Step expanded={expandAllSteps} key={requestFormStep.SetDetails}>
                <StepLabel
                  classes={{ root: classes.stepLabelRoot }}
                  icon={
                    activeStep !== requestFormStep.SetDetails ? (
                      <BigidMeStepInactive title={BigIdMeTranslate('consumer_web_disabled')} />
                    ) : (
                      <BigidMeIconStepActive title={BigIdMeTranslate('consumer_web_current_step')} />
                    )
                  }
                >
                  <Typography variant="h3" className={classes.stepHeader}>
                    {activeStep === requestFormStep.SetDetails && (
                      <b>{BigIdMeTranslate('consumer_web_submit_tell-us')}</b>
                    )}
                    {activeStep !== requestFormStep.SetDetails && (
                      <span>{BigIdMeTranslate('consumer_web_submit_tell-us')}</span>
                    )}
                  </Typography>
                </StepLabel>

                <StepContent classes={{ root: cx(classes.stepTwoContentRoot, classes.stepContent) }}>
                  {activeStep === requestFormStep.SetDetails && (
                    <Typography variant="h5" className={classes.userDetailsTitleMobile}>
                      {BigIdMeTranslate('consumer_web_submit_tell-us')}
                    </Typography>
                  )}
                  {isAuthorizedAgentEnabled && renderAuthorizedAgent()}
                  {renderUserDetails()}
                </StepContent>
              </Step>
            </Stepper>
          </div>
        </div>
      ) : (
        renderRequestStatus()
      )}
      {!tenant.isPreviewPP && (
        <BigidMeSessionController
          sessionClosingStage={sessionClosingStage}
          setSessionClosingStage={setSessionClosingStage}
        />
      )}
    </BigidMeWireframe>
  );
};
