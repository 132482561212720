import { CSSProperties } from 'react';
import { blue } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

import { gray, white } from '../../assets/theme/BigidMeColors';

type SelectStylesOptionsType = Partial<{
  isMulti: boolean;
  isDisabled: boolean;
  isFocused: boolean;
  isSelected: boolean;
}>;

type SelectStylesType = (
  breakpointSmall: number,
) => Record<string, (base: CSSProperties, options: SelectStylesOptionsType) => CSSProperties>;

export const selectStyles: SelectStylesType = breakpointSmall => ({
  placeholder: (base: CSSProperties) => ({
    ...base,
    color: gray[650],
  }),
  container: (base: CSSProperties) => ({
    ...base,
    width: 240,

    '&:focus-within': {
      outline: '2px solid #888',
      outlineStyle: 'auto',
    },

    [`@media (max-width: ${breakpointSmall}px)`]: {
      width: 288,
    },
  }),
  control: (base: CSSProperties, { isDisabled, isFocused }) => {
    const borderColor = isDisabled ? gray[200] : isFocused ? blue[600] : gray[300];
    const background = isDisabled ? gray[50] : white;

    return {
      ...base,
      fontSize: '0.875rem',
      height: '34px',
      color: gray[700],
      background,
      borderColor,
      boxShadow: 'none',
      border: '1px solid #888888',
      borderRadius: '18px',
      '&:hover': {
        background,
        cursor: 'pointer',
      },
    };
  },
  indicatorsContainer: (base: CSSProperties) => ({
    ...base,
  }),
  clearIndicator: (base: CSSProperties) => ({
    ...base,
    color: gray[600],
    cursor: 'pointer',
    paddingTop: '0',
    paddingBottom: '0',
    '&:hover': {
      color: gray[600],
    },
  }),
  dropdownIndicator: (base: CSSProperties, { isDisabled }) => ({
    ...base,
    color: isDisabled ? gray[200] : gray[600],
    cursor: 'pointer',
    paddingTop: '0',
    paddingBottom: '0',
    '&:hover': {
      color: isDisabled ? gray[200] : gray[600],
    },
  }),
  indicatorSeparator: (base: CSSProperties, { isMulti, isDisabled }) => ({
    ...base,
    color: isDisabled ? gray[150] : gray[300],
    paddingTop: '0',
    paddingBottom: '0',
    display: isMulti && !isDisabled ? 'inline' : 'none',
  }),
  option: (base: CSSProperties, { isSelected, isFocused }) => {
    const color = isSelected ? white : gray[700];

    return {
      ...base,
      color,
      overflow: 'hidden',
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      fontSize: '0.875rem',
      backgroundColor: isSelected ? blue[500] : isFocused ? blue[100] : 'transparent',
      '&:hover': {
        backgroundColor: isSelected ? blue[500] : blue[100],
        color,
      },
    };
  },
  valueContainer: (base: CSSProperties) => ({
    ...base,
    ['flexWrap' as any]: 'nowrap',
  }),
  multiValue: (base: CSSProperties) => ({
    ...base,
    color: gray[700],
    minWidth: '35px',
    backgroundColor: gray[150],
    borderRadius: '16px',
  }),
  multiValueLabel: (base: CSSProperties, { isDisabled }) => ({
    ...base,
    color: isDisabled ? gray[500] : gray[700],
    fontSize: '0.8125rem',
  }),
  multiValueRemove: (base: CSSProperties, { isDisabled }) => ({
    ...base,
    color: isDisabled ? gray[200] : gray[700],
    cursor: 'pointer',
    fontSize: '0.8125rem',
    '&:hover': {
      backgroundColor: 'transparent',
      color: isDisabled ? gray[200] : gray[700],
    },
  }),
  menu: (base: CSSProperties) => ({
    ...base,
    borderRadius: '4px',
    borderColor: gray[300],
    zIndex: 3,
  }),
  noOptionsMessage: (base: CSSProperties) => ({ ...base, color: gray[700] }),
});

export const useClasses = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
  },
  error: () => {
    return {
      fontSize: '0.875rem',
      marginTop: '5px',
      color: gray[700],
    };
  },
  containerWithLabel: ({ hiddenLabel }: { hiddenLabel?: boolean }) => ({
    marginTop: !hiddenLabel ? `${theme.spacing(6)} !important` : 0,
  }),
}));
