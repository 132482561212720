import React, { FC, ReactNode } from 'react';
import { BigidMeWireframe } from '../BigidMeWireframe';
import { TenantContainer } from '../../state/tenantContainer';
import { Typography } from '@mui/material';
import { LanguageContainer } from '../../state/languageContainer';
import { BigidMeIconEmailConfirmed } from '../../assets/icons/BigidMeIconEmailConfirmed';
import { ReactComponent as BigidMeIconErrorExclamation } from '../../assets/icons/icon-alarm-red.svg';
import { BigidMeIconReportWarning } from '../../assets/icons/BigidMeIconReportWarning';
import { ReactComponent as BigidMeIconExpired } from '../../assets/icons/expired with bg.svg';
import { BigidMeRequestInfoFooter } from './BigidMeRequestInfoFooter';
import { e2e } from '../../utils';

import { useStyles } from './BigidMeRequestInfoWrapperStyles';

type ComponentViewType =
  | 'AlreadyConfirmedRequestError'
  | 'ConsumerDataAlreadyUpdated'
  | 'DuplicateRequestError'
  | 'SessionClosedError'
  | 'ExpiredLinkError';

export const componentView = {
  AlreadyConfirmedRequestError: {
    icon: BigidMeIconEmailConfirmed,
    title: 'consumer_web_errors_already-confirmed',
  },
  ConsumerDataAlreadyUpdated: {
    icon: BigidMeIconErrorExclamation,
    title: 'consumer_web_errors_already-updated',
  },
  DuplicateRequestError: {
    icon: BigidMeIconReportWarning,
    title: 'consumer_web_errors_request-in-progress',
  },
  SessionClosedError: {
    icon: BigidMeIconExpired,
    title: 'consumer_web_errors_session-closed',
  },
  ExpiredLinkError: {
    icon: BigidMeIconExpired,
    title: 'consumer_web_errors_link-expired',
  },
};

export const BigidMeRequestInfoWrapper: FC<{
  componentNaming: ComponentViewType;
  children: ReactNode;
  pageTitle?: string;
}> = ({ componentNaming, children, pageTitle }) => {
  const { tenant } = TenantContainer.useContainer();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const classes = useStyles();
  const componentInfo = componentView[componentNaming];

  return (
    <BigidMeWireframe {...tenant} pageTitle={pageTitle}>
      <div className={classes.container}>
        <componentInfo.icon title={BigIdMeTranslate(componentInfo.title)} />
        <Typography variant="h1" color="textSecondary" className={classes.title} {...e2e('request_info_title')}>
          {BigIdMeTranslate(componentInfo.title)}
        </Typography>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          align="center"
          className={classes.text}
          {...e2e('request_info_subtitle')}
        >
          {children}
        </Typography>
        <BigidMeRequestInfoFooter />
      </div>
    </BigidMeWireframe>
  );
};
