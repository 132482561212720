import makeStyles from '@mui/styles/makeStyles';
import { blue, white } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    padding: '50px 0',
    minHeight: '100%',
    backgroundColor: white,
  },
  title: {
    margin: '32px 0 24px 0',
  },
  subTitle: {
    textAlign: 'center',
    marginBottom: 16,
  },
  wrapper: {
    borderRadius: 12,
    backgroundColor: white,
    boxShadow: '0 2px 25px 0 rgba(0,0,0,0.1)',
    padding: '32px 45px',
    width: '100%',
    maxWidth: 625,
  },
  fields: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  addressInputRoot: {
    width: '100%',
  },
  textRoot: {
    marginBottom: 40,
    width: 'auto',
  },
  labelRoot: {
    fontSize: '0.9375rem',
    top: -8,
    transform: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
  },
  textAreaInputRoot: {
    padding: '10px 16px',
    '& > textarea:first-child': {
      minHeight: 95,
    },
  },
  selectPlaceholder: {
    color: blue[750],
  },
  selectPlaceholderIcon: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  selectPlaceholderText: {
    verticalAlign: 2,
  },
  controls: {
    borderTop: '1px solid #D6D6D6',
    marginTop: 64,
    paddingTop: 40,
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    textTransform: 'none',
  },
}));
