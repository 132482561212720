import { styled } from '@mui/styles';
import { BigidColorsV2 } from '@bigid-ui/components/lib/assets/theme/BigidColorsV2';

const PREFIX = 'NotificationPanel';

export const classes = {
  richText: `${PREFIX}-richText`,
  wrapper: `${PREFIX}-wrapper`,
};

export const Root = styled('div')<{ visible: boolean; transparentBackground: boolean }>(({ theme }) => ({
  [`& .${classes.wrapper}`]: {
    display: theme.visible ? 'flex' : 'none',
    padding: '13px 18px',
    width: 'fit-content',
    borderRadius: '6px',
    backgroundColor: theme.transparentBackground ? 'transparent' : BigidColorsV2.gray[150],
    alignItems: 'center',

    '& > svg': {
      margin: '0 13px 0 0',
      flexShrink: 0,
    },
  },
  [`& .${classes.richText}`]: {
    '& > p:nth-of-type(1)': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
}));
