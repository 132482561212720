import { styled } from '@mui/styles';

const PREFIX = 'BigidMeRadioGroup';

export const classes = {
  labelRoot: `${PREFIX}-labelRoot`,
  radioLabel: `${PREFIX}-radioLabel`,
  divider: `${PREFIX}-divider`,
  fromControl: `${PREFIX}-fromControl`,
};

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.labelRoot}`]: {
    color: `${theme.palette.text.primary} !important`,
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.6em',
    position: 'static',
  },
  [`& .${classes.radioLabel}`]: {
    '&:focus-within': {
      outline: '2px solid #888',
      outlineStyle: 'auto',
      outlineOffset: '1px',
    },
  },
  [`& .${classes.divider}`]: {
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  [`& .${classes.fromControl}`]: {
    '& .MuiFormGroup-root': {
      gap: '2px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '0.853rem',
    },
  },
}));
