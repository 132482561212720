import { useCallback } from 'react';
import { unstable_BlockerFunction, unstable_useBlocker as useBlocker } from 'react-router-dom';

const normalizePath = (path: string) => (path.endsWith('/') ? path.slice(0, -1) : path);

export const usePreventNavigation = (when = false, allowedPaths?: string[]) => {
  const shouldBlock: unstable_BlockerFunction = useCallback(
    ({ nextLocation: { pathname } }) => {
      if (allowedPaths?.includes(`${normalizePath(pathname)}`)) {
        return false;
      }
      return when;
    },
    [when, allowedPaths],
  );

  const blocker = useBlocker(shouldBlock);

  return {
    showWarning: blocker.state === 'blocked',
    isProceeding: blocker.state === 'proceeding',
    reset: blocker.reset,
    retry: blocker.proceed,
  };
};
