import React, { useState, FC, MouseEvent, useEffect } from 'react';
import { Typography } from '@mui/material';
import cx from 'classnames';

import { ReactComponent as BigidMeIconEmail } from '../../assets/icons/icon-verify-email.svg';
import { e2e } from '../../utils';
import { BigIdMeTranslateType, BigIdMeTranslateWithNodeType } from '../../state/languageContainer';

import { useStylesSubmitted } from './BigidMeRequestStatusStyles';

export interface BigidMeRequestStatusSubmittedPropsType {
  requestedAction: string;
  email?: string;
  contactLinkUrl: string;
  onResend: () => void;
  resendExceeded: boolean;
  useEmailConfirmation: boolean;
  translate: BigIdMeTranslateType;
  translateWithNode: BigIdMeTranslateWithNodeType;
  className?: string;
}

export const BigidMeRequestStatusSubmitted: FC<BigidMeRequestStatusSubmittedPropsType> = ({
  requestedAction,
  email,
  contactLinkUrl,
  onResend,
  resendExceeded = false,
  useEmailConfirmation,
  translate,
  translateWithNode,
  className,
}) => {
  const classes = useStylesSubmitted();
  const [resendBlockingTimer, setResendBlockingTimer] = useState<number | null>(null);
  let resendBlockingTimerId: ReturnType<typeof setInterval>;

  const handleOnResend = (e: MouseEvent): void => {
    e.preventDefault();
    setResendBlockingTimer(60);
    resendBlockingTimerId = setInterval(() => {
      setResendBlockingTimer(resendBlockingTimer => {
        if (resendBlockingTimer === 1) {
          clearInterval(resendBlockingTimerId);
          return null;
        }
        return resendBlockingTimer ? resendBlockingTimer - 1 : 60;
      });
    }, 1000);
    onResend();
  };

  useEffect(
    () => () => {
      resendBlockingTimerId && clearInterval(resendBlockingTimerId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const SentLink = () => (
    <>
      {translateWithNode(
        useEmailConfirmation ? 'consumer_web_submit_we-sent-a-link' : 'consumer_web_submit_we-sent-a-track-link',
        [
          {
            key: 'email',
            value: (
              <b key="email" {...e2e('submitted-email')}>
                {email}
              </b>
            ),
          },
        ],
      )}
    </>
  );

  const goInbox = translate(
    useEmailConfirmation ? 'consumer_web_submit_please-go' : 'consumer_web_submit_please-go-track',
  );

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.layout}>
        <BigidMeIconEmail title={translate('consumer_web_email_verified')} />
        <div className={classes.title}>
          <Typography variant="h1" color="textPrimary" align="center" gutterBottom {...e2e('submitted-verify-email')}>
            {translate('consumer_web_submit_please-verify')}
          </Typography>
        </div>
        <div className={classes.text1}>
          <Typography variant="subtitle1" color="textPrimary" align="center" gutterBottom>
            {translate('consumer_web_submit_your-request', [
              {
                key: 'action',
                value: translate(`consumer_web_submit_request-${requestedAction?.toLowerCase()}_name`),
              },
            ])}
          </Typography>
          <Typography variant="subtitle1" color="textPrimary" align="center" gutterBottom>
            <SentLink />
          </Typography>
          <Typography variant="subtitle1" color="textPrimary" align="center">
            {goInbox}
          </Typography>
        </div>
        <div className={classes.text2}>
          {useEmailConfirmation && (
            <div>
              <Typography variant="subtitle2" color="textPrimary" align="center" gutterBottom>
                {translate('consumer_web_submit_didnt-get')}{' '}
                {!resendExceeded ? (
                  <button
                    className={resendBlockingTimer !== null ? classes.disabledLink : classes.link}
                    onClick={handleOnResend}
                    {...e2e('submitted_resend')}
                  >
                    {resendBlockingTimer !== null
                      ? translate('consumer_web_submit_next-email', [
                          { key: 'resend_timer', value: resendBlockingTimer.toString() },
                        ])
                      : translate('consumer_web_submit_resend')}
                  </button>
                ) : (
                  <span className={classes.resendError}>{translate('consumer_web_submit_this-action-no-longer')}</span>
                )}
              </Typography>
            </div>
          )}
          <Typography variant="subtitle2" color="textPrimary" align="center">
            {translateWithNode('consumer_web_submit_if-you-have', [
              {
                key: 'contact_us',
                value: (
                  <a key="contactLinkUrl" href={contactLinkUrl} {...e2e('submitted_contact-us')}>
                    {translate('consumer_web_general_contact')}
                  </a>
                ),
              },
            ])}
          </Typography>
        </div>
      </div>
    </div>
  );
};
