import { styled } from '@mui/styles';

const PREFIX = 'RequestMessages';

export const classes = {
  wrapper: `${PREFIX}-wrapper`,
  allCommunicationsButton: `${PREFIX}-allCommunicationsButton`,
  sentContainer: `${PREFIX}-sentContainer`,
  sentLayout: `${PREFIX}-sentLayout`,
  sentIcon: `${PREFIX}-sentIcon`,
  sentStatus: `${PREFIX}-sentStatus`,
  sentText: `${PREFIX}-sentText`,
  header: `${PREFIX}-header`,
  title: `${PREFIX}-title`,
  backButton: `${PREFIX}-backButton`,
  backButtonMobile: `${PREFIX}-backButtonMobile`,
  messageReply: `${PREFIX}-messageReply`,
  showView: `${PREFIX}-showView`,
  hideView: `${PREFIX}-hideView`,
};

export const Root = styled('div')(({ theme }) => ({
  marginTop: 50,
  position: 'relative',

  [theme.breakpoints.up('sm')]: {
    padding: '8px 38px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    padding: '8px 16px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: 30,
    marginTop: 0,
  },

  [`& .${classes.header}`]: {
    display: 'flex',
    justifyContent: 'end',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',

      '& > div': {
        width: '100%',
      },
    },
  },
  [`& .${classes.title}`]: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 30,

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: 10,
      lineHeight: '21px',
      marginBottom: 15,
    },
  },
  [`& .${classes.messageReply}`]: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: -25,
    },
  },
  [`& .${classes.wrapper}`]: {
    maxWidth: 709,
    margin: 'auto',
    textAlign: 'left',
  },
  [`& .${classes.allCommunicationsButton}`]: {
    fontSize: '14px',
    textDecorationLine: 'underline',
    marginTop: 50,
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  [`& .${classes.sentContainer}`]: {
    display: 'flex',
    height: '100%',
  },
  [`& .${classes.sentLayout}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '45px auto 70px',
    maxWidth: 612,
    padding: '10px 32px',
    height: '100%',
  },
  [`& .${classes.sentIcon}`]: {
    marginBottom: 32,
  },
  [`& .${classes.sentStatus}`]: {
    textAlign: 'center',
    marginBottom: 24,
  },
  [`& .${classes.sentText}`]: {
    textAlign: 'center',
    marginBottom: 16,
  },
  [`& .${classes.backButton}`]: {
    fontSize: '14px',
    textDecorationLine: 'underline',
    margin: '50px 0 25px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.backButtonMobile}`]: {
    position: 'absolute',
    top: 80,
  },
  [`& .${classes.showView}`]: {
    display: 'block!important',
  },
  [`& .${classes.hideView}`]: {
    display: 'none',
  },
}));
