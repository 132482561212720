import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  helperWrapper: {
    display: 'flex',
    padding: '15px 0',
    '& > svg': {
      minWidth: 20,
    },
  },
  labelRoot: {
    color: `${theme.palette.text.primary} !important`,
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.6em',
    position: 'static',
  },
  italic: {
    fontStyle: 'italic',
  },
}));
