import React, { FC } from 'react';
import { useTheme } from '@mui/material';

import { LanguageContainer } from '../../state/languageContainer';

export const BigidMeIconEmailCode: FC<{ title?: string }> = ({ title }) => {
  const theme = useTheme();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  return (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title id="emailCodeTitle">{title || BigIdMeTranslate('consumer_web_email_code') || 'Email Code'}</title>
      <circle cx="45" cy="45" r="45" fill={theme.palette.bigidMe?.backgroundElement} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.2837 40.1328L59 32.7067V35.5627L64.8558 41.5332L65.0193 41.7102C65.5493 42.3201 65.8811 43.0752 65.9735 43.8746L50.4802 52.9883L47.5307 51.1555L47.3258 51.035C45.8071 50.1913 43.9563 50.2315 42.4693 51.1555L39.5198 52.9883L24.0265 43.8746C24.128 42.996 24.5188 42.1708 25.1442 41.5332L31 35.5624V32.706L23.7163 40.1328C22.6162 41.2545 22 42.7629 22 44.334V62.431C22 65.7447 24.6863 68.431 28 68.431H62C65.3137 68.431 68 65.7447 68 62.431V44.334C68 42.7629 67.3838 41.2545 66.2837 40.1328ZM24 46.1794V62.431C24 62.4974 24.0016 62.5635 24.0048 62.6292L37.6015 54.1803L24 46.1794ZM52.3985 54.1803L66 46.1794V62.431L65.9951 62.6292L52.3985 54.1803ZM24.634 64.5929C25.312 65.6463 26.4708 66.3607 27.8004 66.4261L28 66.431H62C63.4127 66.431 64.6543 65.6987 65.366 64.5929L46.4751 52.8543C45.6237 52.3251 44.5739 52.2921 43.6977 52.755L43.5249 52.8543L24.634 64.5929ZM49.2012 22.7163L53.5297 27.1295H50.7287L47.8008 24.1442C46.2761 22.6489 43.8588 22.6221 42.3021 24.0467L42.1442 24.1992L39.2704 27.1295H36.4691L40.7163 22.7988C43.0365 20.433 46.8353 20.3961 49.2012 22.7163Z"
        fill={theme.palette.bigidMe?.primary}
      />
      <path d="M60 32V34H30V32H60Z" fill={theme.palette.bigidMe?.primary} />
      <circle cx="55" cy="30" r="1" fill={theme.palette.bigidMe?.primary} />
      <circle cx="51" cy="30" r="1" fill={theme.palette.bigidMe?.primary} />
      <path
        d="M57 26C58.5977 26 59.9037 27.2489 59.9949 28.8237L60 29V49.3015H58V29C58 28.4872 57.614 28.0645 57.1166 28.0067L57 28H33C32.4872 28 32.0645 28.386 32.0067 28.8834L32 29V49.3015H30V29C30 27.4023 31.2489 26.0963 32.8237 26.0051L33 26H57Z"
        fill={theme.palette.bigidMe?.primary}
      />
      <path
        d="M34.2929 38.2929C34.6534 37.9324 35.2206 37.9047 35.6129 38.2097L35.7071 38.2929L39.7071 42.2929C40.0976 42.6834 40.0976 43.3166 39.7071 43.7071C39.3466 44.0676 38.7794 44.0953 38.3871 43.7903L38.2929 43.7071L34.2929 39.7071C33.9024 39.3166 33.9024 38.6834 34.2929 38.2929Z"
        fill={theme.palette.bigidMe?.primary}
      />
      <path
        d="M42.2929 38.2929C42.6534 37.9324 43.2206 37.9047 43.6129 38.2097L43.7071 38.2929L47.7071 42.2929C48.0976 42.6834 48.0976 43.3166 47.7071 43.7071C47.3466 44.0676 46.7794 44.0953 46.3871 43.7903L46.2929 43.7071L42.2929 39.7071C41.9024 39.3166 41.9024 38.6834 42.2929 38.2929Z"
        fill={theme.palette.bigidMe?.primary}
      />
      <path
        d="M50.2929 38.2929C50.6534 37.9324 51.2206 37.9047 51.6129 38.2097L51.7071 38.2929L55.7071 42.2929C56.0976 42.6834 56.0976 43.3166 55.7071 43.7071C55.3466 44.0676 54.7794 44.0953 54.3871 43.7903L54.2929 43.7071L50.2929 39.7071C49.9024 39.3166 49.9024 38.6834 50.2929 38.2929Z"
        fill={theme.palette.bigidMe?.primary}
      />
      <path
        d="M39.7071 38.2929C39.3466 37.9324 38.7794 37.9047 38.3871 38.2097L38.2929 38.2929L34.2929 42.2929C33.9024 42.6834 33.9024 43.3166 34.2929 43.7071C34.6534 44.0676 35.2206 44.0953 35.6129 43.7903L35.7071 43.7071L39.7071 39.7071C40.0976 39.3166 40.0976 38.6834 39.7071 38.2929Z"
        fill={theme.palette.bigidMe?.primary}
      />
      <path
        d="M47.7071 38.2929C47.3466 37.9324 46.7794 37.9047 46.3871 38.2097L46.2929 38.2929L42.2929 42.2929C41.9024 42.6834 41.9024 43.3166 42.2929 43.7071C42.6534 44.0676 43.2206 44.0953 43.6129 43.7903L43.7071 43.7071L47.7071 39.7071C48.0976 39.3166 48.0976 38.6834 47.7071 38.2929Z"
        fill={theme.palette.bigidMe?.primary}
      />
      <path
        d="M55.7071 38.2929C55.3466 37.9324 54.7794 37.9047 54.3871 38.2097L54.2929 38.2929L50.2929 42.2929C49.9024 42.6834 49.9024 43.3166 50.2929 43.7071C50.6534 44.0676 51.2206 44.0953 51.6129 43.7903L51.7071 43.7071L55.7071 39.7071C56.0976 39.3166 56.0976 38.6834 55.7071 38.2929Z"
        fill={theme.palette.bigidMe?.primary}
      />
    </svg>
  );
};
