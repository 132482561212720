import React, { FC, useState, useCallback, useMemo, ChangeEvent } from 'react';
import axios, { AxiosError } from 'axios';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  InputLabel,
  Radio,
  RadioGroup,
  StyledEngineProvider,
} from '@mui/material';
import { ReactComponent as BigidMeIconShield } from '../../assets/icons/shield.svg';
import { ReactComponent as BigidMeIconRadioSelectedBlue } from '../../assets/icons/Radio_on_blue.svg';
import { ReactComponent as BigidMeIconRadioEmpty } from '../../assets/icons/Radio_off_black.svg';
import { BigidMeFileUploader } from '../BigidMeFileUploader';
import { requestService, ValidationPhotoType, UserFilesType, FieldsType } from '../../services';
import { LanguageContainer } from '../../state/languageContainer';
import { ErrorMessages } from '../../constants/errorCodes';
import { ErrorResponse, WithTest } from '../../utils';
import { useResponsive } from '../../hooks/useResponsive';

import { useStyles } from './BigidMePhotoFieldStyles';

export interface BigidMePhotoFieldProps extends WithTest {
  onChange: (userFiles: UserFilesType | string) => void;
  onBlur?: () => void;
  value?: string;
  error?: string;
  required?: boolean;
  label?: string;
  tooltip?: string;
}

export const BigidMePhotoField: FC<BigidMePhotoFieldProps> = ({
  error,
  onChange,
  onBlur,
  required,
  label,
  tooltip,
}) => {
  const isMobile = useResponsive();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const [loadError, setLoadError] = useState('');
  const [validationType, setValidationType] = useState(ValidationPhotoType.NATIONAL_STATE_ID);
  const classes = useStyles();

  const onSelectFile = useCallback(
    async (fileFormData: FormData) => {
      try {
        const uploadId = await requestService.uploadVerificationPhoto(fileFormData);
        onBlur && onBlur();
        onChange({ uploadId, type: validationType, id: FieldsType.USER_PHOTO });
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const axiosError: AxiosError = err;
          setLoadError(BigIdMeTranslate(ErrorMessages[(axiosError?.response?.data as ErrorResponse).errorCode]));
        }
      }
    },
    [onChange, validationType, onBlur, BigIdMeTranslate],
  );

  const onRemove = useCallback(() => {
    onChange('');
  }, [onChange]);

  const onValidationTypeChange = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setValidationType(value as ValidationPhotoType);
  }, []);

  const validationPhotoTypes = useMemo(
    () => [
      {
        label: BigIdMeTranslate('consumer_web_photo_type_national_state_id'),
        value: ValidationPhotoType.NATIONAL_STATE_ID,
      },
      {
        label: BigIdMeTranslate('consumer_web_photo_type_driving_license'),
        value: ValidationPhotoType.DRIVING_LICENSE,
      },
      { label: BigIdMeTranslate('consumer_web_photo_type_passport'), value: ValidationPhotoType.PASSPORT },
    ],
    [BigIdMeTranslate],
  );

  return (
    <StyledEngineProvider injectFirst>
      {!isMobile && (
        <FormControl
          component="fieldset"
          sx={{
            '.MuiFormGroup-root': {
              flexDirection: 'row',
              paddingBottom: '15px',
            },
            '[dir=rtl] & .MuiFormControlLabel-root': {
              marginRight: '-11px',
              marginLeft: '16px',
            },
            '.MuiFormControlLabel-label': {
              fontSize: '0.853rem',
            },
          }}
        >
          <FormLabel component="legend">
            <InputLabel shrink classes={{ root: classes.labelRoot }}>
              <div>
                {label || BigIdMeTranslate('consumer_web_submit_field.userPhoto.label')}
                {required && (
                  <span className={classes.italic}>&nbsp;({BigIdMeTranslate('consumer_web_validation_required')})</span>
                )}
                <br />
                {BigIdMeTranslate('consumer_web_field_upload_type_id')}
              </div>
            </InputLabel>
          </FormLabel>
          <RadioGroup name="validation-photo" value={validationType}>
            {validationPhotoTypes.map(({ label, value }, idx) => (
              <FormControlLabel
                key={`${value}_${idx}`}
                value={value}
                htmlFor={value}
                control={
                  <Radio
                    disableRipple
                    onChange={onValidationTypeChange}
                    icon={<BigidMeIconRadioEmpty />}
                    checkedIcon={<BigidMeIconRadioSelectedBlue />}
                    id={value}
                  />
                }
                label={label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
      {isMobile && (
        <InputLabel shrink classes={{ root: classes.labelRoot }}>
          <div>
            {label || BigIdMeTranslate('consumer_web_submit_field.userPhoto.label')}
            {required && (
              <span className={classes.italic}>&nbsp;({BigIdMeTranslate('consumer_web_validation_required')})</span>
            )}
          </div>
        </InputLabel>
      )}
      <BigidMeFileUploader
        subtext={BigIdMeTranslate('consumer_web_submit_fileloader_png_jpeg_or_pdf_format_no_larger_than_10_mb')}
        format=".jpg, .jpeg, .png, .pdf"
        onSelect={onSelectFile}
        onRemove={onRemove}
        onMobileValidationTypeChange={onValidationTypeChange}
        label="Click here to upload your file"
      />
      {loadError && <FormHelperText error>{loadError}</FormHelperText>}
      {error && <FormHelperText error>{error}</FormHelperText>}
      <div className={classes.helperWrapper}>
        <BigidMeIconShield title={BigIdMeTranslate('consumer_web_submit_field.userPhoto.tooltip')} />
        &nbsp;&nbsp; {tooltip || BigIdMeTranslate('consumer_web_submit_field.userPhoto.tooltip')}
      </div>
    </StyledEngineProvider>
  );
};
