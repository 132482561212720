import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: 288,
      margin: '0 auto',
    },
  },
  captchaWrapper: {
    [theme.breakpoints.down('sm')]: {
      transform: 'scale(0.94)',
      transformOrigin: '0 0',
    },
  },
}));
