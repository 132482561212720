import makeStyles from '@mui/styles/makeStyles';
import { errorColor, gray } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles(theme => ({
  inputRoot: {
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.text.primary,
    background: '#fff',
    padding: '4px 15px 3px 15px',
    marginTop: `${theme.spacing(6)} !important`,
    width: 240,
    border: '1px solid #888888',
    borderRadius: '18px',
    '&::placeholder': {
      fontWeight: theme.typography.fontWeightLight,
      color: theme.palette.text.primary,
    },
    '&:disabled': {
      color: theme.palette.text.disabled,
    },

    [theme.breakpoints.down('sm')]: {
      width: 288,
    },
  },
  input: {
    '&::placeholder': {
      color: `${gray[650]}`,
      opacity: 1,
    },
  },
  inputError: {
    border: `1px solid ${errorColor} !important`,
    boxShadow: '0 0 8px 0 rgba(252,92,66,0.5)',
  },
  inputDisabled: {
    backgroundColor: '#F8F8F8',
  },
  inputFocus: {
    outline: '1px solid #888',
  },
  helperTextRoot: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: theme.typography.h6.lineHeight,
    color: `${errorColor} !important`,
    // to mach with input width, with margin it will be 240
    width: 212,
  },
  multiline: {
    width: '504px',
    minHeight: '94px',
    maxHeight: '160px',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 288,
    },
  },
}));
