import React, { FC, useCallback, ChangeEvent } from 'react';
import {
  Divider,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  StyledEngineProvider,
  FormControl,
  FormLabel,
} from '@mui/material';
import { ReactComponent as BigidMeIconRadioSelectedBlue } from '../../../assets/icons/Radio_on_blue.svg';
import { ReactComponent as BigidMeIconRadioEmpty } from '../../../assets/icons/Radio_off_black.svg';
import { e2e, WithTest } from '../../../utils';
import { useStyles } from './DeliveryMethodStyles';
import { useResponsive } from '../../../hooks/useResponsive';
import { DeliveryMethodTypes, FieldsType } from '../../../services';
import { extractLabelsFromHint } from './deliveryMethodHelper';

export const DEFAULT_DELIVERY_METHOD = DeliveryMethodTypes.email;
export interface DeliveryMethodPropsType extends WithTest {
  placeholder: string;
  onChange: (method: string) => void;
  label: string;
}

export const DeliveryMethod: FC<DeliveryMethodPropsType> = ({ placeholder, onChange, label, ...rest }) => {
  const isMobile = useResponsive();
  const classes = useStyles();

  const labels = extractLabelsFromHint(placeholder);

  const onMethodChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <StyledEngineProvider injectFirst>
      <FormControl
        component="fieldset"
        {...e2e(rest['data-aid'])}
        sx={{
          '.MuiFormGroup-root': {
            gap: '2px',
          },
          '[dir=rtl] & .MuiFormControlLabel-root': {
            marginRight: '-11px',
            marginLeft: '16px',
          },
          '.MuiFormControlLabel-label': {
            fontSize: '0.853rem',
          },
        }}
      >
        <FormLabel component="legend">
          <InputLabel shrink classes={{ root: classes.labelRoot }} id="delvery_method_head">
            {label}
          </InputLabel>
        </FormLabel>
        <Divider className={classes.divider} />
        <RadioGroup
          name={FieldsType.DELIVERY_METHOD}
          defaultValue={DEFAULT_DELIVERY_METHOD}
          row={!isMobile}
          defaultChecked={true}
        >
          <FormControlLabel
            key={DeliveryMethodTypes.email}
            value={DeliveryMethodTypes.email}
            htmlFor="delivery_method_email"
            className={classes.radioLabel}
            control={
              <Radio
                id="delivery_method_email"
                disableRipple
                onChange={onMethodChange}
                icon={<BigidMeIconRadioEmpty />}
                checkedIcon={<BigidMeIconRadioSelectedBlue />}
                {...e2e(`${rest['data-aid']}_email`)}
              />
            }
            label={labels.email}
          />
          <FormControlLabel
            className={classes.radioLabel}
            key={DeliveryMethodTypes.physicalMail}
            value={DeliveryMethodTypes.physicalMail}
            htmlFor="delivery_method_physical_mail"
            control={
              <Radio
                id="delivery_method_physical_mail"
                disableRipple
                onChange={onMethodChange}
                icon={<BigidMeIconRadioEmpty />}
                checkedIcon={<BigidMeIconRadioSelectedBlue />}
                {...e2e(`${rest['data-aid']}_physical_mail`)}
              />
            }
            label={labels.physicalMail}
          />
        </RadioGroup>
      </FormControl>
    </StyledEngineProvider>
  );
};
