import makeStyles from '@mui/styles/makeStyles';
import { gray, white } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    minHeight: 290,
    padding: '0 23px',
    textAlign: 'center',
    backgroundColor: white,
  },
  title: {
    marginTop: 32,
  },
  text: {
    marginTop: 24,
  },
  note: {
    borderTop: `1px solid ${gray[250]}`,
    margin: '56px auto 0',
    paddingTop: 16,
    width: '100%',
    maxWidth: 400,
  },
  link: {
    fontWeight: 600,
    color: '#2177CC',
    textDecoration: 'none',
    '&:lang(ru)': {
      fontWeight: 500,
    },
    '&:lang(ja), &:lang(zh)': {
      fontWeight: 400,
    },
  },
}));
