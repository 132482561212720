import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { TenantContainer } from '../../state/tenantContainer';
import { BigidMeWireframe } from '../../components';
import { LanguageContainer } from '../../state/languageContainer';
import { BigidMeStatus } from '../../components/BigidMeStatus/BigidMeStatus';
import { statusDetails } from './config';
import { getIcon } from './utils';
import { RequestStatusTypes } from '../../services';

export const VerificationStatus = () => {
  const { requestId, status } = useParams<{
    requestId: 'string';
    status: 'success' | 'failed' | 'manual';
  }>();
  const { tenant, loadTenantSettings } = TenantContainer.useContainer();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const tenantLoaded = !!tenant.tenantId;
  const [searchParams] = useSearchParams();
  const requestType = searchParams.get('type') || 'View';

  useEffect(() => {
    !tenantLoaded && loadTenantSettings({ requestId });
  }, [tenantLoaded, requestId, loadTenantSettings]);

  if (!status || !requestId) {
    return null;
  }

  return (
    <BigidMeWireframe {...tenant} hideLanguageControls pageTitle={BigIdMeTranslate('page_title_request_status')}>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%', height: '100%' }}
      >
        <Box width="100%" flex="auto">
          <BigidMeStatus
            icon={getIcon(requestType as RequestStatusTypes, status, BigIdMeTranslate)}
            title={BigIdMeTranslate(statusDetails[status].title)}
            requestId={requestId}
            description={BigIdMeTranslate(statusDetails[status].description, [
              {
                key: 'type',
                value: requestType,
              },
              {
                key: 'request_id',
                value: requestId,
              },
            ])}
          />
        </Box>
      </Stack>
    </BigidMeWireframe>
  );
};
