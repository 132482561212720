import { useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { getEnv } from '../utils/commonUtils';
import { datadogRum } from '@datadog/browser-rum';
import { tenantService } from '../services';
import { useParams } from 'react-router-dom';

const startDataDogRum = ({
  tenantId,
  applicationId,
  clientToken,
  version,
}: {
  tenantId: string;
  applicationId: string;
  clientToken: string;
  version: string;
}) => {
  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    service: 'consumer-app',
    env: getEnv(),
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    // allowedTracingUrls: [url => url.startsWith('http')],

    // defaultPrivacyLevel: 'mask-user-input',
    defaultPrivacyLevel: 'mask',
  });

  datadogRum.setUserProperty('tenant', tenantId);
  datadogRum.startSessionReplayRecording();
};
const useMonitoring = () => {
  const { requestId } = useParams();

  useEffect(() => {
    const initMonitoringService = async () => {
      const settings = await tenantService.getSettings({ publishName: '', language: 'en', requestId });
      if (settings && settings.monitoringConfig?.enabled) {
        startDataDogRum({
          tenantId: settings.id,
          applicationId: settings.monitoringConfig?.applicationIdConsumerApp,
          clientToken: settings.monitoringConfig?.clientTokenConsumerApp,
          version: settings.monitoringConfig?.ddVersion,
        });
      }
    };
    initMonitoringService();

    return () => {
      datadogRum.stopSessionReplayRecording();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {};
};

export const MonitoringContainer = createContainer(useMonitoring);
