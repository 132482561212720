import { useEffect, useState, useCallback } from 'react';
import { createContainer } from 'unstated-next';
import { tenantService } from '../services';
import { AxiosError } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { Tenant } from '../utils';
import { BigidMeColorSchemesVars, ColorSchemes } from '../assets/theme';

const initialTenant = {
  tenantId: '',
  logoUrl: '',
  logoWidth: 'auto',
  logoHeight: 'auto',
  tenantName: '',
  supportLink: '',
  privacyPolicyLink: '',
  termsConditionsLink: '',
  copyrightEnabled: false,
  whitelabelBigidmeIcon: false,
  captchaOn: false,
  smsValidationOn: false,
  correctionFlowEnabled: false,
  translations: {},
  favicon: '',
  theme: {
    name: ColorSchemes.LIGHT,
    ...BigidMeColorSchemesVars.light,
  },
  analyticsConfig: {
    enabled: false,
    apiKey: '',
    serverUrl: '',
    environment: '',
    tenantId: '',
    companyName: '',
  },
  monitoringConfig: {
    enabled: false,
    applicationIdPrivacyPortalApp: '',
    applicationIdConsumerApp: '',
    clientTokenPrivacyPortalApp: '',
    clientTokenConsumerApp: '',
    ddVersion: '',
  },
};

export const useTenant = (initial?: Tenant) => {
  const [tenant, setTenant] = useState<Tenant>(initial || initialTenant);

  // Privacy Portal tenant initiation
  useEffect(() => {
    if (initial) {
      setTenant(initial);
    }
  }, [initial]);

  const loadTenantSettings = useCallback(
    async ({
      publishName = '',
      language = '',
      requestId,
      navigate,
    }: {
      publishName?: string;
      language?: string;
      requestId?: string;
      navigate?: NavigateFunction;
    }) => {
      try {
        const settings = await tenantService.getSettings({ publishName, language, requestId });

        setTenant(
          initial
            ? {
                ...initial,
                ...settings,
              }
            : {
                tenantId: settings.id,
                tenantName: decodeURIComponent(settings.name),
                favicon: settings.logoUrl,
                ...settings,
              },
        );
      } catch (error) {
        const e = error as AxiosError;
        if (e.response?.status === 404) {
          navigate && navigate('/404');
        } else {
          navigate && navigate('/error');
        }
      }
    },
    [initial],
  );

  return { tenant, setTenant, loadTenantSettings };
};

export const TenantContainer = createContainer(useTenant);
