import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { KeyboardBackspace } from '@mui/icons-material';

import { MeButton } from '../MeButton';
import {
  ManagePreferencesSectionsType,
  ManagePreferencesSectionTypes,
  ManagePreferencesStepType,
} from '../../services';
import { e2e } from '../../utils';

import { useStyles } from './BigidMeManagePreferencesStyles';
import { BigidMeSurvey } from '../BigidMeSurvey';
import { BigidMePreferences } from '../BigidMePreferences';
import { stepperInactive } from '../../assets/theme/BigidMeColors';
import { LanguageContainer } from '../../state/languageContainer';
import { TenantContainer } from '../../state/tenantContainer';

export enum InternalStepsType {
  PREFERENCES,
  SURVEY,
}

export interface BigidMeManagePreferencesType {
  data: ManagePreferencesStepType[];
  onApply: (data: ManagePreferencesStepType[], otherReason: string) => void;
  step?: InternalStepsType;
  submitDisabled?: boolean;
  setStep: (step: InternalStepsType) => void;
}

export const BigidMeManagePreferences: FC<BigidMeManagePreferencesType> = ({
  data: preferencesData,
  onApply,
  step,
  submitDisabled,
  setStep,
}) => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const { tenant } = TenantContainer.useContainer();
  const [preferences, setPreferences] = useState<ManagePreferencesSectionsType[]>([]);
  const [initialPreferences, setInitialPreferences] = useState<ManagePreferencesSectionsType[]>([]);
  const [survey, setSurvey] = useState<ManagePreferencesSectionsType[]>([]);
  const [otherReason, setOtherReason] = useState('');

  const classes = useStyles({ isFirstStep: step === InternalStepsType.PREFERENCES });

  useEffect(() => {
    if (!preferencesData.length) {
      return;
    }

    const preferences = preferencesData[InternalStepsType.PREFERENCES]?.sections.sort(
      (o1, o2) => o1.sectionOrder - o2.sectionOrder,
    );
    setInitialPreferences(preferences);
    setPreferences(preferences);
    setSurvey(preferencesData[InternalStepsType.SURVEY]?.sections);
  }, [preferencesData]);

  const isOptedOut = useMemo(
    () =>
      preferences.some((section, sectionIndex) => {
        if (
          section.type === ManagePreferencesSectionTypes.MULTI_SELECT ||
          section.type === ManagePreferencesSectionTypes.TOGGLE
        ) {
          return section.items.some(
            (item, itemIndex) => !item.selected && initialPreferences[sectionIndex].items[itemIndex].selected,
          );
        }

        if (
          section.type === ManagePreferencesSectionTypes.RADIO ||
          section.type === ManagePreferencesSectionTypes.DROPDOWN
        ) {
          // NOTE: Here we assume that all items except the first one are opt-out options
          const selectedConsentItemIndex = preferences[sectionIndex].items.findIndex(item => item.selected);

          return selectedConsentItemIndex > 0;
        }

        return false;
      }),
    [initialPreferences, preferences],
  );

  const handleNext = useCallback(() => {
    if (step === InternalStepsType.PREFERENCES && survey?.length && isOptedOut) {
      setStep(InternalStepsType.SURVEY);
    } else {
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      const newPreferences = preferencesData[InternalStepsType.PREFERENCES];
      newPreferences.sections = preferences;
      const newSurvey = preferencesData[InternalStepsType.SURVEY] || {};
      newSurvey.sections = survey;

      onApply([newPreferences, newSurvey], otherReason);
    }
  }, [step, survey, isOptedOut, setStep, preferencesData, preferences, onApply, otherReason]);

  const handleBack = () => {
    setStep(InternalStepsType.PREFERENCES);
  };

  if (!preferences.length) return null;

  return (
    <div className={classes.root}>
      <div className={cx(classes.outer, { [classes.stepTwo]: step === InternalStepsType.SURVEY })}>
        <div className={classes.inner}>
          {step === InternalStepsType.PREFERENCES && (
            <BigidMePreferences sections={preferences} isPreviewPP={tenant.isPreviewPP} onChange={setPreferences} />
          )}
          {step === InternalStepsType.SURVEY && (
            <BigidMeSurvey
              sections={survey}
              onChange={(data, value) => {
                setSurvey(data);
                setOtherReason(value);
              }}
              isPreviewPP={tenant.isPreviewPP}
            />
          )}
        </div>
      </div>
      <div className={classes.controls}>
        {step === InternalStepsType.SURVEY ? (
          <MeButton
            onClick={handleBack}
            type="secondary"
            classes={cx(classes.clear, classes.buttonBack)}
            aria-label="Back"
            {...e2e(`preferences-button-back`)}
            bi={{ eventType: 'manage_preferences_back' }}
          >
            <KeyboardBackspace fill={stepperInactive} />
          </MeButton>
        ) : (
          <div />
        )}
        <div>
          {step === InternalStepsType.SURVEY && (
            <MeButton
              {...e2e('preferences_skip')}
              onClick={handleNext}
              type="tertiary"
              classes={classes.controlsButton}
              text={BigIdMeTranslate('consumer_web_submit_request-preferences_skip')}
              bi={{ eventType: 'manage_preferences_skip' }}
            />
          )}
          <MeButton
            {...e2e('preferences_next')}
            onClick={handleNext}
            type="primary"
            classes={classes.controlsButton}
            text={BigIdMeTranslate('consumer_web_submit_request-preferences_submit')}
            isDisabled={submitDisabled}
            bi={{ eventType: 'manage_preferences_submit' }}
          />
        </div>
      </div>
    </div>
  );
};
