import { styled } from '@mui/styles';

const PREFIX = 'BigidMeUploadDocumentsField';

export const classes = {
  helperWrapper: `${PREFIX}-helperWrapper`,
  labelRoot: `${PREFIX}-labelRoot`,
  italic: `${PREFIX}-italic`,
};

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.labelRoot}`]: {
    color: `${theme.palette.text.primary} !important`,
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.6em',
    position: 'static',

    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'wrap',
      marginBottom: -20,
      marginLeft: -15,
    },
  },
  [`& .${classes.helperWrapper}`]: {
    display: 'flex',
    padding: '15px 0',
    '& > svg': {
      minWidth: 20,
    },
  },
  [`& .${classes.italic}`]: {
    fontStyle: 'italic',
  },
}));
