import React, { FC, ReactElement, useMemo } from 'react';

import { ReactComponent as StatusErrorIcon } from '../../assets/icons/manage-preferences-status-error.svg';
import StatusPendingIcon1 from '../../assets/icons/manage-preferences-status-pending.gif';
import { ReactComponent as StatusNotFoundIcon } from '../../assets/icons/manage-preferences-status-not-found.svg';
import { ReactComponent as StatusSubmittedIcon } from '../../assets/icons/manage-preferences-status-submitted.svg';
import { ManagePreferencesStatus } from '../../services';
import { BigIdMeTranslateType, LanguageContainer } from '../../state/languageContainer';
import { MeSubmitButton } from '../../components';
import { e2e } from '../../utils';

import { useStyles } from './ManagePreferencesStyles';

type StuatusType = Exclude<ManagePreferencesStatus, ManagePreferencesStatus.COMPLETED>;
//
const getConfig = (
  translate: BigIdMeTranslateType,
): Record<StuatusType, { icon: ReactElement; title: string; subTitle: string[]; buttonText?: string }> => ({
  [ManagePreferencesStatus.PENDING]: {
    icon: <img src={StatusPendingIcon1} />,
    title: 'consumer_web_preferences_request-pending-title',
    subTitle: ['consumer_web_preferences_request-pending-description1'],
  },
  [ManagePreferencesStatus.ERROR]: {
    icon: <StatusErrorIcon title={translate('consumer_web_error')} />,
    title: 'consumer_web_preferences_request-error-title',
    subTitle: ['consumer_web_preferences_request-error-description1'],
    buttonText: 'consumer_web_preferences_request-error-button',
  },
  [ManagePreferencesStatus.NOT_FOUND]: {
    icon: <StatusNotFoundIcon title={translate('page_title_request_not_found')} />,
    title: 'consumer_web_preferences_request-not_found-title',
    subTitle: ['consumer_web_preferences_request-not_found-description1'],
    buttonText: 'consumer_web_preferences_request-not_found-button',
  },
  [ManagePreferencesStatus.SUBMITTED]: {
    icon: <StatusSubmittedIcon title={translate('consumer_web_success')} />,
    title: 'consumer_web_preferences_request-success-title',
    subTitle: [
      'consumer_web_preferences_request-success-description1',
      'consumer_web_preferences_request-success-description2',
    ],
  },
});

type PreferencesRequestStatusPropsType = {
  status: ManagePreferencesStatus;
  onSubmit?: () => Promise<unknown>;
};

export const PreferencesRequestStatus: FC<PreferencesRequestStatusPropsType> = ({ status, onSubmit }) => {
  const classes = useStyles();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const formConfig = getConfig(BigIdMeTranslate)[status as StuatusType];

  const subTitle = useMemo(() => {
    return formConfig.subTitle.map(translationKey => (
      <div key={translationKey}>{BigIdMeTranslate(translationKey)}</div>
    ));
  }, [BigIdMeTranslate, formConfig.subTitle]);

  return (
    <div className={classes.statusWrapper}>
      {formConfig.icon}
      <h1 className={classes.statusTitle} {...e2e(`preferencesStatus-${status}-title`)}>
        {BigIdMeTranslate(formConfig.title)}
      </h1>
      <h6 className={classes.statusSubTitle} {...e2e(`preferencesStatus-${status}-subTitle`)}>
        {subTitle}
      </h6>
      {onSubmit && (
        <MeSubmitButton
          onClick={onSubmit}
          type="primary"
          text={BigIdMeTranslate(formConfig.buttonText || '') || formConfig.buttonText}
          {...e2e(`preferencesStatus-${status}-submitButton`)}
          bi={{ eventType: 'preferences_request_status_submit' }}
        />
      )}
    </div>
  );
};
