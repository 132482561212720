import React, { FC, useState, ReactNode, FormEvent } from 'react';
import { Typography } from '@mui/material';
import { csrService, RequestStatus, RequestStatusTypes } from '../../services';
import { UserRequestStatus } from '../../constants/userRequestStatus';

import { e2e } from '../../utils';
import { BigidMeTextField } from '../BigidMeTextField';
import { MeButton } from '../MeButton';
import { LanguageContainer } from '../../state/languageContainer';
import { useStyles } from './BigidMeRequestStatusByIdStyles';

export interface BigidMeRequestStatusByIdPropsType {
  type: RequestStatusTypes;
  icon: ReactNode;
  state: UserRequestStatus;
  requestId: string;
  expirationDate?: string;
  expirationDays?: number;
  submitNewRequestLink?: string;
  contactLink?: string;
  setDataToRequestObject: (data: RequestStatus) => void;
  onErrorAction: (isError: boolean) => void;
  apiKey: string;
  tenantId: string;
}

export const BigidMeRequestStatusCsr: FC<BigidMeRequestStatusByIdPropsType> = ({
  setDataToRequestObject,
  onErrorAction,
  apiKey,
  tenantId,
}) => {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [requestId, setRequestId] = useState('');
  const [isInvalidInput, setIsInvalidInput] = useState(false);
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  const onChangeTextField = (text: string) => {
    setRequestId(text);
    if (text.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    const digitalRegex = new RegExp('^[0-9]+$');
    if (!text.match(digitalRegex) && text) {
      setIsInvalidInput(true);
    } else {
      setIsInvalidInput(false);
    }
  };

  const getRequestStatusCsr = () => {
    if (tenantId) {
      csrService
        .getRequestStatusCsr(requestId, apiKey)
        .then(fs => {
          document.documentElement.setAttribute('lang', fs.userLocale);
          setDataToRequestObject(fs);
        })
        .catch(() => {
          onErrorAction(true);
        });
    }
  };

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    getRequestStatusCsr();
  };

  return (
    <div className={classes.root}>
      <div className={classes.layout}>
        <div className={classes.status}>
          <Typography variant="h1" color="textSecondary" {...e2e('request_status_csr_title')}>
            {BigIdMeTranslate('consumer_web_customer_rep_find_request_status')}
          </Typography>
        </div>
        <form onSubmit={handleFormSubmit} className={classes.innerContainer}>
          <BigidMeTextField
            label={BigIdMeTranslate('consumer_web_customer_rep_enter_request_status')}
            placeholder={BigIdMeTranslate('consumer_web_customer_rep_request_id_placeholder')}
            onChange={onChangeTextField}
            error={isInvalidInput ? BigIdMeTranslate('consumer_web_customer_rep_not_valid_request_id') : ''}
            {...e2e(`request_id_input`)}
          />
          <MeButton
            isDisabled={disabled || isInvalidInput}
            classes={classes.button}
            type={'primary'}
            text={BigIdMeTranslate('consumer_web_customer_rep_find')}
            onClick={getRequestStatusCsr}
            {...e2e(`btn_find_request`)}
            bi={{ eventType: 'request_status_csr_find_request' }}
          />
        </form>
      </div>
    </div>
  );
};
