import { ActiveViewType } from './types';

export const getViewByErrorCode = (code: number): ActiveViewType => {
  if (code === 12463) {
    return 'errorSecret';
  } else if (code === 12464) {
    return 'errorInvalidEmail';
  } else if (code === 12465) {
    return 'errorAlreadySubmitted';
  } else if (code === 12466) {
    return 'errorManyFiles';
  } else if (code === 12467) {
    return 'errorCanceledRequest';
  } else {
    return 'failed';
  }
};
