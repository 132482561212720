export enum ErrorCodes {
  UNEXPECTED = 1,
  ACTION_UNAVAILABLE = 2,
  DUPLICATE_REQUEST = 3,
  REPORT_EXPIRED = 4,
  SMS_VALIDATION_INVALID_CODE = 5,
  SMS_VALIDATION_ATTEMPTS_EXCEEDED = 11442,
  EMAIL_ALREADY_CONFIRMED = 11401,
  REQUEST_ALREADY_CLOSED = 11402,
  REQUEST_DOESNT_EXIST = 11403,
  HASH_DOESNT_MATCH = 11404,
  EMAIL_RESEND_LIMIT = 11405,
  SEND_RESEND_PIN_CODE = 12403,
  DOWNLOAD_REPORT_ATTEMPTS_EXCEEDED = 12404,
  DOWNLOAD_REPORT_INVALID_CODE = 12405,
  DOWNLOAD_REPORT_REQUEST_EXPIRED = 12406,
  UNKNOWN_REPORT_ATTRIBUTE = 12408,
  CANNOT_EDIT_CONSUMER_DATA = 12409,
  CONSUMER_DATA_ALREADY_UPDATED = 12419,
  INVALID_FILE = 11426,
  MESSAGES_UNAVAILABLE = 12452,
  APPEAL_REQUEST_DOES_NOT_EXIST = 12401,
  APPEAL_REQUEST_IS_PROGRESS = 12421, // general
  APPEAL_REQUEST_OPEN = 12454,
  APPEAL_REQUEST_EXPIRED = 12455,
  APPEAL_REQUEST_CAN_NOT_CREATE = 12456,
}

export const ErrorMessages = {
  [ErrorCodes.SEND_RESEND_PIN_CODE]: 'consumer_web_errors_create-code-exceeded',
  [ErrorCodes.SMS_VALIDATION_ATTEMPTS_EXCEEDED]: 'consumer_web_sms_error_too-many-times-please-try-again',
  [ErrorCodes.SMS_VALIDATION_INVALID_CODE]: 'consumer_web_sms_error_code-incorrect',
  [ErrorCodes.DOWNLOAD_REPORT_ATTEMPTS_EXCEEDED]: 'consumer_web_errors_download-exceeded',
  [ErrorCodes.DOWNLOAD_REPORT_INVALID_CODE]: 'consumer_web_errors_invalid-code',
  [ErrorCodes.DOWNLOAD_REPORT_REQUEST_EXPIRED]: 'consumer_web_errors_request-expired',
  [ErrorCodes.INVALID_FILE]: 'consumer_web_errors_wrong_format_size',
} as unknown as Record<ErrorCodes, string>;
