/* eslint-disable i18next/no-literal-string */
import React from 'react';
import {
  BigidAiiSetIllustration,
  BigidCredentialsIllustration,
  BigidRiskAlertIllustration,
  BigidAssessmentIllustration,
  BigidErrorIllustration,
} from '@bigid-ui/icons';
import { ActiveViewType, ViewType } from './types';

export const views: Record<ActiveViewType, ViewType> = {
  form: {
    icon: <BigidCredentialsIllustration size="small" />,
    title: 'Verify your email',
    description:
      'To access the request details, please verify your email. Check your inbox for a verification message.',
    showTryAgain: false,
  },
  success: {
    icon: <BigidAiiSetIllustration size="small" />,
    title: 'Check your inbox',
    generateDescription: email => (
      <>
        {`We've sent a verification email to `}
        <strong>{email}</strong>
        {`. If you don't see it, please check your spam folder.`}
      </>
    ),
    showTryAgain: false,
  },
  resolutionSent: {
    icon: <BigidAssessmentIllustration size="small" />,
    title: 'Closing Resolution Submitted Successfully',
    description: 'Your closing resolution has been successfully received. Thank you for your response.',
    generateDescription: (email, date) => (
      <>
        {`Closing resolution has been successfully sent by `}
        <strong>{email}</strong> on <strong>{date}</strong>
      </>
    ),
    showTryAgain: false,
  },
  errorInvalidEmail: {
    icon: <BigidRiskAlertIllustration size="small" />,
    title: 'Invalid Email Address',
    description: 'Please enter a valid work email address.',
    showTryAgain: true,
  },
  errorSecret: {
    icon: <BigidErrorIllustration size="small" />,
    title: 'Expired Link',
    description: 'The vendor secret is invalid or could not be verified after repeated attempts.',
    showTryAgain: false,
  },
  errorManyFiles: {
    icon: <BigidErrorIllustration size="small" />,
    title: 'Email Verification Failed',
    description: 'Too many files',
    showTryAgain: false,
  },
  errorAlreadySubmitted: {
    icon: <BigidErrorIllustration size="small" />,
    title: 'Email Verification Failed',
    description: 'Vendor resolution has already need saved before',
    showTryAgain: false,
  },
  errorCanceledRequest: {
    icon: <BigidErrorIllustration size="small" />,
    title: 'Canceled request',
    description: 'This request is no longer active. We will notify you with an updated request if applicable.',
    showTryAgain: false,
  },
  failed: {
    icon: <BigidErrorIllustration size="small" />,
    title: 'Something went wrong',
    description: 'Please try again later or contact support for assistance.',
    showTryAgain: true,
  },
};
