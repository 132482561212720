import React, { FC, memo } from 'react';
import { useParams } from 'react-router-dom';
import { BigidMeRequestInfoWrapper } from '../../components/BigidMeRequestInfoWrapper';
import { LanguageContainer } from '../../state/languageContainer';

import { e2e } from '../../utils';

export const DuplicateRequestError: FC = memo(function DuplicateRequestError() {
  const { BigIdMeTranslateWithNode, BigIdMeTranslate } = LanguageContainer.useContainer();
  const { deliveryIndicator } = useParams();

  return (
    <BigidMeRequestInfoWrapper
      componentNaming="DuplicateRequestError"
      pageTitle={BigIdMeTranslate('page_title_duplicated_request')}
    >
      {BigIdMeTranslateWithNode('consumer_web_errors_request-for', [
        {
          key: 'indicator',
          value: <b {...e2e('duplicate_indicator')}>{deliveryIndicator}</b>,
        },
      ])}
    </BigidMeRequestInfoWrapper>
  );
});
