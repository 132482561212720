export const DEFAULT_LABEL_EMAIL = 'Email';
export const DEFAULT_LABEL_PHYSICAL_MAIL = 'Physical Mail';

export interface JsonStringifiedValueInHint {
  email?: string;
  physicalMail?: string;
}

/**
 * workaround while we don't have dedicated field
 * in the hint property of deliveryMethod field
 * this should be fixed by adding a dedicated field for this case on the BE side
 * we expect to set a JSON value in the hint property of deliveryMethod field
 */
export function extractLabelsFromHint(hint: string): JsonStringifiedValueInHint {
  let parsedValues;
  try {
    // only extract the known values (security reasons)
    const { email, physicalMail } = hint ? JSON.parse(hint) : ({} as any);
    parsedValues = { email, physicalMail };
  } catch (e) {
    console.warn('error while parsing JSON of delivery method values');
  }

  return {
    email: parsedValues?.email || DEFAULT_LABEL_EMAIL,
    physicalMail: parsedValues?.physicalMail || DEFAULT_LABEL_PHYSICAL_MAIL,
  };
}
