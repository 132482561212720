import React, { FC, useEffect, useState, ChangeEvent, useCallback } from 'react';
import { TenantContainer } from '../../state/tenantContainer';
import { BigidMeWireframe, MeButton } from '../../components';
import { useStyles } from './ExperianVerificationFormStyles';
import {
  Divider,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  FormControl,
  FormLabel,
  CircularProgress,
} from '@mui/material';
import { verificationQuestionnaireService } from '../../services/verification-questionnaire/verificationQuestionnaireService';
import {
  ExperianResponseStatus,
  QuestionsResponse,
  QuestionType,
} from '../../services/verification-questionnaire/types';
import { ReactComponent as BigidMeIconRadioEmpty } from '../../assets/icons/Radio_off_black.svg';
import { ReactComponent as BigidMeIconRadioSelected } from '../../assets/icons/Radio_on_black.svg';
import { LanguageContainer } from '../../state/languageContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { AnalyticEventType, e2e } from '../../utils';
import { usePreventNavigation } from '../../hooks/usePreventNavigation';
import { usePreventLeavePage } from '../../hooks/usePreventLeavePage';
import { ExitDialog } from '../../components/ExitDialog/ExitDialog';
import { RedirectUrls } from '../../constants/routes';
import { analyticsService } from '../../state/analyticsContainer';
import { requestService } from '../../services';

export const ExperianVerificationForm: FC = () => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const { tenant, loadTenantSettings } = TenantContainer.useContainer();
  const navigate = useNavigate();
  const { requestId, rc } = useParams();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [sessionId, setSessionId] = useState('');
  const [questions, setQuestions] = useState<QuestionType[] | []>([]);
  const [selectedAnswers, setSelectedAnswers] = useState<(number | undefined)[]>([]);
  const [preventNavigation, setPreventNavigation] = useState(true);
  const [requestRunning, setRequestRunning] = useState(false);

  useEffect(() => {
    loadTenantSettings({ requestId });
  }, [requestId, loadTenantSettings]);

  const goToVerificationStatusScreen = useCallback(
    async (status: 'failed' | 'success' | 'manual') => {
      setPreventNavigation(false);
      const requestType = (await requestService.getRequestStatus(requestId!, rc!)).requestType;
      setTimeout(() => {
        navigate(`/${tenant.tenantId}/${requestId}/verification-status/${status}?type=${requestType}`);
      }, 0);
    },
    [navigate, requestId, tenant.tenantId, rc],
  );

  const goToErrorScreen = () => {
    setPreventNavigation(false);
    setTimeout(() => {
      navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}`);
    }, 0);
  };

  const { showWarning, reset, retry } = usePreventNavigation(preventNavigation);

  usePreventLeavePage(true, () => {
    analyticsService.trackManualEvent(AnalyticEventType.ExperianLeaveFromPage, {
      requestId,
    });
  });

  const setQuestionsFromResponse = (data: QuestionsResponse) => {
    setSessionId(data.sessionId);
    setSelectedAnswers(Array(data.questions.length));
    setQuestions(data.questions);
  };

  const getQuestionsAndHandleResponse = async (requestQuestionFunction: () => Promise<QuestionsResponse>) => {
    try {
      setRequestRunning(true);
      const data = await requestQuestionFunction();
      const status = data.experianResponseStatus.experianResponseStatusEnum;
      if (status === ExperianResponseStatus.IN_PROGRESS && data.questions.length) {
        setQuestionsFromResponse(data);
      } else if (status === ExperianResponseStatus.COMPLETED) {
        goToVerificationStatusScreen('success');
      } else if (status === ExperianResponseStatus.FAILED) {
        goToVerificationStatusScreen('failed');
      } else if (status === ExperianResponseStatus.MANUAL_VERIFICATION) {
        goToVerificationStatusScreen('manual');
      } else {
        goToErrorScreen();
      }
    } catch (e) {
      goToErrorScreen();
    } finally {
      setRequestRunning(false);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setPreventNavigation(false);
    const answers = { sessionId, answers: selectedAnswers.map(String) };
    getQuestionsAndHandleResponse(() => verificationQuestionnaireService.submitAnswers(requestId!, answers));
  };

  const handleRadioOnChange = (event: ChangeEvent<HTMLInputElement>, answerNumber: number): void => {
    const { value } = event.target;
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[parseInt(value)] = ++answerNumber;
    setSelectedAnswers(updatedAnswers);
  };

  useEffect(() => {
    if (tenant.tenantId) {
      getQuestionsAndHandleResponse(() =>
        verificationQuestionnaireService.sendRequestForInitialQuestions(requestId!, rc!),
      );
    }
  }, [requestId, tenant.tenantId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    analyticsService.trackManualEvent(AnalyticEventType.ExperianStarted, {
      requestId,
    });
  }, [requestId]);

  return (
    <BigidMeWireframe {...tenant} pageTitle={BigIdMeTranslate('page_title_verification_form')}>
      <div className={classes.root}>
        <div className={classes.titleWrapper}>
          <Typography variant="h3" {...e2e('experian_verification_form_title')}>
            {BigIdMeTranslate('consumer_web_submit_title', [{ key: 'tenant_caption', value: tenant.tenantName }])}
          </Typography>
          <Typography variant="h5" color="textPrimary">
            {BigIdMeTranslate('consumer_web_submit_subtitle')}
          </Typography>
        </div>
        <div className={classes.formHeading}>
          <Typography variant="h4" color="textPrimary">
            <b>{BigIdMeTranslate('consumer_web_submit_tell-us')}</b>
          </Typography>
        </div>
        {loading ? (
          <CircularProgress color="inherit" />
        ) : (
          <Paper className={classes.wrapper}>
            <div>
              <Typography variant="h5" color="textPrimary" className={classes.questionsTitle}>
                <b>{BigIdMeTranslate('consumer_web_submit_description')}</b>
              </Typography>
              <Divider />
            </div>
            <div>
              <form>
                <div className={classes.formControl}>
                  {questions.map(({ questionSelect, questionText }: QuestionType, index: number) => {
                    return (
                      <FormControl component="fieldset" key={index} className={classes.questionContainer}>
                        <FormLabel component="legend" className={classes.formQuestionLabel}>
                          {questionText}
                        </FormLabel>
                        <RadioGroup key={index} name="questions">
                          {questionSelect.map((option: string, answerIndex: number) => {
                            return (
                              <FormControlLabel
                                key={answerIndex}
                                value={answerIndex}
                                htmlFor={`${index}-${answerIndex}`}
                                control={
                                  <Radio
                                    disableRipple
                                    onChange={e => handleRadioOnChange(e, answerIndex)}
                                    value={index}
                                    icon={<BigidMeIconRadioEmpty />}
                                    checkedIcon={<BigidMeIconRadioSelected />}
                                    checked={selectedAnswers[index] === answerIndex + 1}
                                    color="primary"
                                    id={`${index}-${answerIndex}`}
                                    {...e2e(`radio_${index}-${answerIndex}`)}
                                  />
                                }
                                label={option}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    );
                  })}
                  <MeButton
                    onClick={handleSubmit}
                    text="Submit"
                    type="primary"
                    classes={classes.submitButton}
                    isDisabled={requestRunning || selectedAnswers.includes(undefined)}
                    {...e2e('submit-button')}
                    bi={{ eventType: 'experian_verification_form_submit' }}
                  />
                </div>
              </form>
            </div>
          </Paper>
        )}
      </div>
      <ExitDialog
        open={showWarning}
        onCancel={() => {
          reset && reset();
          analyticsService.trackManualEvent(AnalyticEventType.ExperianExitDialogNo, {
            requestId,
          });
        }}
        onClose={() => {
          retry && retry();
          analyticsService.trackManualEvent(AnalyticEventType.ExperianExitDialogYes, {
            requestId,
          });
        }}
      />
    </BigidMeWireframe>
  );
};
