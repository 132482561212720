import { api } from '../apiService';
import { RequestStatus } from '../request/types';

const validateCsrApiKey = (apiKey: string): Promise<void> =>
  api
    .get<void>(`/csr/validate-csr-api-key`, {
      headers: {
        'X-API-key': apiKey,
      },
    })
    .then();

const getRequestStatusCsr = (requestId: string, apiKey: string): Promise<RequestStatus> =>
  api.get(`/csr/requests/${requestId}/user-status`, { headers: { 'X-API-key': apiKey } }).then(res => res.data);

export const csrService = {
  validateCsrApiKey,
  getRequestStatusCsr,
};
