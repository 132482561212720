/* eslint-disable */
import React, { FC, useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useInterval } from '@bigid-ui/components';
import { LanguageContainer } from '../../state/languageContainer';
import { Box, Divider } from '@mui/material';
import { ReactComponent as BigidMeIconCaptchaMock } from '../../assets/icons/robot.svg';

import { useStyles } from './BigidMeCaptchaStyles';
const REFRESH_CAPTCHA_TIME = 90000;

interface CaptchaProps {
  onClick: (token: string | null) => void;
  isPreviewPP?: boolean;
}

export const BigidMeCaptcha: FC<CaptchaProps> = ({ onClick, isPreviewPP }) => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState('');
  const classes = useStyles();

  useInterval(() => {
    setRefreshReCaptcha(new Date().toString());
  }, REFRESH_CAPTCHA_TIME);

  return (
    <>
      <Box my={8}>
        <Divider />
      </Box>
      <div className={classes.root}>
        <Box my={2}>
          {!isPreviewPP ? (
            <div className={classes.captchaWrapper}>
              <GoogleReCaptcha onVerify={onClick} refreshReCaptcha={refreshReCaptcha} />
            </div>
          ) : (
            <BigidMeIconCaptchaMock title={BigIdMeTranslate('consumer_web_captcha')} />
          )}
        </Box>
      </div>
    </>
  );
};
