import React, { FC, useState } from 'react';
import { WithTest } from '../../utils';
import { FormControl, TextFieldProps } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ReactComponent as BigidMeIconCalendar } from '../../assets/icons/icon-calendar.svg';
import { BigidMeTextField, BigidMeTextFieldPropsType } from '../BigidMeTextField';
import { DateFormats } from '../../services';
import { format } from 'date-fns';
import { white } from '../../assets/theme/BigidMeColors';
import { toUTCDate } from '../../utils/dateUtils';
import { LanguageContainer, LanguageType } from '../../state/languageContainer';
import {
  pt,
  enUS,
  ar,
  fr,
  it,
  de,
  el,
  es,
  et,
  frCA,
  he,
  hi,
  cs,
  da,
  fi,
  ja,
  ko,
  ms,
  nl,
  nb,
  pl,
  sk,
  sv,
  th,
  tr,
  vi,
  zhCN,
} from 'date-fns/locale';

const VALUE_FORMAT = 'yyyy-MM-dd';

const locales: Record<LanguageType, Locale> = {
  pt,
  en: enUS,
  ar,
  fr,
  it,
  de,
  el,
  es,
  et,
  'fr-ca': frCA,
  he,
  hi,
  cs,
  da,
  fi,
  ja,
  ko,
  ms,
  nl,
  no: nb,
  pl,
  sk,
  sv,
  th,
  tr,
  vi,
  'zh-cn': zhCN,
};

const PickerInput = ({
  label,
  id,
  required,
  placeholder,
  error,
  onBlur,
  disabled,
  ...rest
}: {
  label?: string;
  disabled?: boolean;
  id?: string;
  required?: boolean;
  placeholder?: string;
  error?: string;
  onBlur?: () => void;
}) =>
  function PickerInput({ InputProps, inputProps, inputRef }: TextFieldProps) {
    return (
      <BigidMeTextField
        id={id}
        required={required}
        label={label}
        endAdornment={InputProps?.endAdornment}
        inputProps={{ ...inputProps, ...(placeholder && { placeholder }) }}
        error={error}
        onBlur={onBlur}
        inputRef={inputRef}
        disabled={disabled}
        {...rest}
      />
    );
  };

export interface BigidMeDatePickerProps extends WithTest, BigidMeTextFieldPropsType {
  onChange: (value: string) => void;
  inputFormat?: 'MM_DD_YYYY' | 'DD_MM_YYYY';
  disablePast?: boolean;
  disableFuture?: boolean;
  label: string;
  className?: string;
}

export const BigidMeDatePicker: FC<BigidMeDatePickerProps> = ({
  inputFormat = 'MM_DD_YYYY',
  defaultValue,
  error,
  placeholder = '',
  label,
  id,
  required,
  disablePast,
  disableFuture,
  onChange,
  disabled,
  onBlur,
  ...rest
}) => {
  const [value, setValue] = useState<Date | null | undefined>(defaultValue ? toUTCDate(new Date(defaultValue)) : null);
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const handleChange = (newValue: Date | null) => {
    setValue(newValue);
    onBlur && onBlur();
    if (newValue) {
      onChange(
        !Number.isNaN(new Date(newValue).getTime()) ? format(new Date(newValue), VALUE_FORMAT) : newValue.toString(),
      );
    }
  };

  const { language } = LanguageContainer.useContainer();

  return (
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locales[language]}>
        <DesktopDatePicker
          inputFormat={DateFormats[inputFormat]}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          components={{
            OpenPickerIcon: function CalendarIcon() {
              return <BigidMeIconCalendar data-aid="calendar-icon" title={BigIdMeTranslate('consumer_web_calendar')} />;
            },
          }}
          disablePast={disablePast}
          disableFuture={disableFuture}
          renderInput={params =>
            PickerInput({
              label,
              id,
              disabled,
              required,
              placeholder: placeholder || DateFormats[inputFormat].toUpperCase(),
              error,
              onBlur,
              ...rest,
            })({ ...params } as TextFieldProps)
          }
          PaperProps={{
            sx: {
              backgroundColor: white,
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
              borderRadius: '8px',
            },
          }}
        />
      </LocalizationProvider>
    </FormControl>
  );
};
