import React, { FC, useState, ReactNode, useEffect } from 'react';
import { Typography } from '@mui/material';
import { MeButton } from '../MeButton';
import { PasscodeType, requestService, RequestStatusTypes } from '../../services';
import { UserRequestStatus } from '../../constants/userRequestStatus';
import { RequestProcessingState } from '../../utils/requestStatusStates';
import { BigidMeModal } from '../BigidMeModal';
import { DownloadReport } from '../../screens/DownloadReport/DownloadReport';
import { LanguageContainer } from '../../state/languageContainer';
import { ErrorCodes } from '../../constants/errorCodes';

import { useStyles } from './BigidMeRequestStatusStyles';
import { e2e } from '../../utils';
import { RequestId } from '../RequestId';
import { VerificationSMSType } from '../../constants/requestVerification';
import { RedirectUrls } from '../../constants/routes';
import { UserDataContainer } from '../../state/userDataContainer';
import { TenantContainer } from '../../state/tenantContainer';
import { useNavigate } from 'react-router-dom';

export interface BigidMeRequestStatusPropsType {
  rc: string;
  type: RequestStatusTypes;
  icon: ReactNode;
  state: UserRequestStatus;
  requestId: string;
  expirationDate?: string;
  expirationDays?: number;
  submitNewRequestLink?: string;
  contactLink?: string;
  isConfidential?: boolean;
  passcodeType: PasscodeType;
  hasFilesToDownload: boolean;
  skip?: boolean;
}

export const BigidMeRequestStatus: FC<BigidMeRequestStatusPropsType> = ({
  rc,
  type,
  icon,
  state,
  requestId,
  expirationDate,
  expirationDays,
  contactLink,
  submitNewRequestLink,
  isConfidential,
  passcodeType,
  hasFilesToDownload,
  skip = true,
}) => {
  const classes = useStyles();
  const [phoneConfirmationModal, setPhoneConfirmationModal] = useState<VerificationSMSType>();
  const [wasDownloadError, setWasDownloadError] = useState(false);
  const [wasResendError, setWasResendError] = useState(false);
  const { BigIdMeTranslate, BigIdMeTranslateWithNode } = LanguageContainer.useContainer();
  const { setUserData } = UserDataContainer.useContainer();
  const tenant = TenantContainer.useContainer().tenant;
  const navigate = useNavigate();

  const handleOpenDownloadModal = () => {
    setPhoneConfirmationModal(VerificationSMSType.DOWNLOAD);
  };

  const handleOpenEditModal = () => {
    setPhoneConfirmationModal(VerificationSMSType.EDIT);
  };

  const handleCloseModal = () => {
    setPhoneConfirmationModal(undefined);
  };

  const requestProcessingStateKey = state in RequestProcessingState ? state : UserRequestStatus.Default;

  const content = RequestProcessingState[requestProcessingStateKey]({
    type,
    requestId,
    expirationDays,
    expirationDate,
    classes,
    contactLink,
    submitNewRequestLink,
    BigIdMeTranslate,
    BigIdMeTranslateWithNode,
    isConfidential,
    passcodeType,
    state,
  });

  const onPinVerificationError = (errorCode: number) => {
    const isResendError = errorCode === ErrorCodes.SEND_RESEND_PIN_CODE;
    const isDownloadError =
      errorCode === ErrorCodes.DOWNLOAD_REPORT_ATTEMPTS_EXCEEDED ||
      errorCode === ErrorCodes.DOWNLOAD_REPORT_REQUEST_EXPIRED;

    isResendError && setWasResendError(isResendError);
    isDownloadError && setWasDownloadError(isDownloadError);
  };

  const downloadReport = () => {
    requestService.downloadReport(requestId, '', rc);
  };

  const handleEditData = () => {
    if (tenant.tenantId) {
      requestService
        .editData(requestId, rc, undefined)
        .then(({ fields }) => {
          setUserData(fields);

          // should wait setUserData(request.fields) and after navigate (for tests)
          setTimeout(() => {
            navigate(`/${tenant.tenantId}/${requestId}/edit/${rc}`);
          }, 0);
        })
        .catch(err => {
          if (err.errorCode === ErrorCodes.ACTION_UNAVAILABLE) {
            navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}${RedirectUrls.ACTION_ERROR}`);
          }
        });
    }
  };

  const shouldVerifyPasscode = passcodeType != PasscodeType.NONE;

  useEffect(() => {
    if (skip) {
      return undefined;
    }
    if (state === UserRequestStatus.InformationProcessed && type === RequestStatusTypes.UPDATE) {
      if (shouldVerifyPasscode) {
        handleOpenEditModal();
      } else {
        handleEditData();
      }
    } else if (hasFilesToDownload) {
      if (shouldVerifyPasscode) {
        handleOpenDownloadModal();
      } else {
        downloadReport();
      }
    }
  }, [skip, tenant.tenantId]);

  return (
    <div className={classes.root}>
      <div className={classes.layout}>
        <div className={classes.icon}>{icon}</div>
        <div className={classes.status}>
          <Typography variant="h1" color="textSecondary" {...e2e('request_status_title')}>
            {content.name}
          </Typography>
        </div>
        <div className={classes.text}>
          <Typography variant="subtitle1" color="textPrimary" {...e2e('request_status_description')}>
            {content.description}
          </Typography>
        </div>
        {state !== UserRequestStatus.RequestCompleted &&
          state !== UserRequestStatus.IdentityVerificationFailed &&
          state !== UserRequestStatus.ReportExpired && <RequestId requestId={requestId} />}
        {hasFilesToDownload && !isConfidential && (
          <div className={classes.button}>
            <MeButton
              onClick={shouldVerifyPasscode ? handleOpenDownloadModal : downloadReport}
              text={
                shouldVerifyPasscode
                  ? BigIdMeTranslate('consumer_web_download_verify-download')
                  : BigIdMeTranslate('consumer_web_download-download')
              }
              type="primary"
              {...e2e('verify-and-download-button')}
              bi={{ eventType: 'request_status_verify_and_download' }}
            />
          </div>
        )}
        {state === UserRequestStatus.InformationProcessed && type === RequestStatusTypes.UPDATE && !isConfidential && (
          <div className={classes.button}>
            <MeButton
              onClick={shouldVerifyPasscode ? handleOpenEditModal : handleEditData}
              text={
                shouldVerifyPasscode
                  ? BigIdMeTranslate('consumer_web_download_verify-proceed')
                  : BigIdMeTranslate('consumer_web_download_proceed-proceed')
              }
              type="primary"
              {...e2e('verify-and-proceed-button')}
              bi={{ eventType: 'request_status_verify_and_proceed' }}
            />
          </div>
        )}
      </div>
      <BigidMeModal open={!!phoneConfirmationModal}>
        {!!phoneConfirmationModal && (
          <DownloadReport
            requestId={requestId}
            rc={rc}
            content={phoneConfirmationModal}
            wasDownloadError={wasDownloadError}
            wasResendError={wasResendError}
            onPinVerificationSuccess={handleCloseModal}
            onPinVerificationError={onPinVerificationError}
            passcodeType={passcodeType}
          />
        )}
      </BigidMeModal>
    </div>
  );
};
