import React, { FC, useEffect, useState } from 'react';
import { PasscodeType, requestService } from '../../services';
import { TenantContainer } from '../../state/tenantContainer';
import { ErrorCodes } from '../../constants/errorCodes';
import { RedirectUrls } from '../../constants/routes';
import { UserRequestStatus } from '../../constants/userRequestStatus';
import { UserDataContainer } from '../../state/userDataContainer';
import { LanguageContainer } from '../../state/languageContainer';
import { BigidMeSmsVerification } from '../../components/BigidMeSmsVerification/BigidMeSmsVerification';
import { VerificationSMSType } from '../../constants/requestVerification';
import { useNavigate } from 'react-router-dom';
import { setPageTitle } from '../../utils/helpers';
import { useErrorMessage } from '../../hooks/useErrorMessage';

export interface RequestStatusProps {
  requestId: string;
  rc: string;
  content: VerificationSMSType;
  wasDownloadError: boolean;
  wasResendError: boolean;
  onPinVerificationSuccess: (validationId?: string) => void;
  onPinVerificationError: (errorCode: number) => void;
  passcodeType: PasscodeType;
}

export const DownloadReport: FC<RequestStatusProps> = ({
  requestId,
  rc,
  content,
  wasDownloadError,
  wasResendError,
  onPinVerificationError,
  passcodeType,
}) => {
  const navigate = useNavigate();
  const tenant = TenantContainer.useContainer().tenant;
  const { setUserData } = UserDataContainer.useContainer();
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true);
  const [errorCode, setErrorCode] = useState<ErrorCodes>();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  useErrorMessage(message => {
    const errorCode = message.errorCode || ErrorCodes.ACTION_UNAVAILABLE;
    setErrorCode(errorCode);
    if (errorCode === ErrorCodes.ACTION_UNAVAILABLE) {
      navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}${RedirectUrls.ACTION_ERROR}`);
    }
    onPinVerificationError(errorCode);
  });

  useEffect(() => {
    checkStatusAndSendCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId, rc]);

  useEffect(() => {
    setPageTitle(BigIdMeTranslate('page_title_download_report'));
  }, []);

  const sendCode = (): void => {
    setLoading(true);
    requestService
      .sendRequestVerificationCode(requestId, rc)
      .catch(err => {
        setErrorCode(err?.response?.data.errorCode);
        onPinVerificationError(err?.response?.data.errorCode);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkStatusAndSendCode = (): void => {
    setCheckingStatus(true);

    requestService
      .getRequestStatus(requestId, rc)
      .then(s => {
        setCheckingStatus(false);

        if (s.status === UserRequestStatus.ReportExpired) {
          setErrorCode(ErrorCodes.REPORT_EXPIRED);
          navigate(`/${tenant.tenantId}/${requestId}/status/${rc}?skip=true`);
        } else {
          sendCode();
        }
      })
      .catch(() => {
        setErrorCode(ErrorCodes.ACTION_UNAVAILABLE);
      });
  };

  const handleDownloadReport = () => {
    setErrorCode(undefined);
    return Promise.resolve(requestService.downloadReport(requestId, code, rc));
  };

  const handleEditData = () => {
    const request = requestService.editData(requestId, rc, code);

    request
      .then(({ fields }) => {
        setUserData(fields);

        // should wait setUserData(request.fields) and after navigate (for tests)
        setTimeout(() => {
          navigate(`/${tenant.tenantId}/${requestId}/edit/${rc}/${code}`);
        }, 0);
      })
      .catch(err => {
        setErrorCode(err.errorCode || err.response?.data?.errorCode || ErrorCodes.ACTION_UNAVAILABLE);
        if (err.errorCode === ErrorCodes.ACTION_UNAVAILABLE) {
          navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}${RedirectUrls.ACTION_ERROR}`);
        }
        onPinVerificationError(err.errorCode || err.response?.data?.errorCode);
      });

    return request;
  };

  const actions = {
    [VerificationSMSType.DOWNLOAD]: {
      text: BigIdMeTranslate('consumer_web_download_verify-download'),
      onClick: handleDownloadReport,
    },
    [VerificationSMSType.EDIT]: {
      text: BigIdMeTranslate('consumer_web_download_verify-proceed'),
      onClick: handleEditData,
    },
  };
  return (
    <>
      {!checkingStatus && (
        <BigidMeSmsVerification
          loading={loading}
          sendCode={sendCode}
          onCodeChange={setCode}
          errorCode={errorCode}
          submitTitle={actions[content].text}
          submitClick={actions[content].onClick}
          wasDownloadError={wasDownloadError}
          wasResendError={wasResendError}
          passcodeType={passcodeType}
          bi={{ eventType: 'download_report_sms_verification' }}
        />
      )}
    </>
  );
};
