import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import { TenantContainer } from '../../state/tenantContainer';
import { LanguageContainer } from '../../state/languageContainer';
import { BigidMeWireframe } from '../../components/BigidMeWireframe';
import { BigidMeRequestStatus, BigidMeStepper } from '../../components';
import { requestService, RequestStatus } from '../../services';
import {
  BigidMeSessionController,
  BigidMeSessionEffect,
  SessionClosingStages,
} from '../../components/BigidMeSessionController';
import { UserRequestStatus } from '../../constants/userRequestStatus';
import { e2e } from '../../utils';
import { formatExpirationDate, getActiveStepIndex } from './utils';
import { useResponsive } from '../../hooks/useResponsive';

import { useStyles } from './RequestProcessingStatusStyles';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getIcon } from '../RequestProcessingStatusRep/utils';

export const RequestProcessingStatus: FC = () => {
  const navigate = useNavigate();
  const { requestId, rc, tenantId } = useParams();
  const { tenant, loadTenantSettings } = TenantContainer.useContainer();
  const isMobile = useResponsive();
  const { BigIdMeTranslate, setLanguage } = LanguageContainer.useContainer();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>();
  const [searchParams] = useSearchParams();
  const skipAutoAction = searchParams.get('skip') === 'true';

  const classes = useStyles();
  const {
    sessionClosingStage,
    setSessionClosingStage,
  }: {
    sessionClosingStage: SessionClosingStages;
    setSessionClosingStage: Dispatch<SetStateAction<SessionClosingStages>>;
  } = BigidMeSessionEffect({ tenantId: tenant.tenantId, navigate });

  useEffect(() => {
    loadTenantSettings({ requestId });
  }, [requestId, loadTenantSettings]);

  useEffect(() => {
    requestService
      .getRequestStatus(requestId!, rc!)
      .then(fs => {
        setLanguage(fs.userLocale);
        document.documentElement.setAttribute('lang', fs.userLocale);
        setRequestStatus(fs);
      })
      .catch(() => 'Todo:');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId, rc]);

  if (requestStatus?.waitingPreferencesSubmit) {
    navigate(`/${tenantId}/${requestId}/preferences/${rc}`);
  }

  return (
    <BigidMeWireframe {...tenant} hideLanguageControls pageTitle={BigIdMeTranslate('page_title_request_status')}>
      {requestStatus && (
        <div className={classes.root}>
          <div className={classes.status}>
            <BigidMeRequestStatus
              skip={skipAutoAction}
              state={requestStatus.status}
              type={requestStatus?.requestType}
              icon={getIcon(requestStatus?.requestType, requestStatus.status, BigIdMeTranslate)}
              rc={rc!}
              requestId={requestId!}
              expirationDays={requestStatus.expirationDays}
              expirationDate={formatExpirationDate(requestStatus.expirationDate)}
              submitNewRequestLink={`/${tenant.tenantId}`}
              contactLink={tenant?.supportLink}
              passcodeType={requestStatus.passcodeType}
              hasFilesToDownload={requestStatus.hasFilesToDownload}
            />
          </div>
          {requestStatus?.status !== UserRequestStatus.Issued && (
            <div className={classes.progress}>
              <div className={classes.text}>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className={classes.extraBold}
                  {...e2e('request_progress')}
                >
                  {BigIdMeTranslate('consumer_web_status_progress')}
                </Typography>
              </div>
              <BigidMeStepper
                isMobile={isMobile}
                orientation={isMobile ? 'vertical' : 'horizontal'}
                activeStepIndex={getActiveStepIndex(requestStatus)}
                requestStatus={requestStatus}
                hasError={
                  requestStatus.status === UserRequestStatus.NoDataFound ||
                  requestStatus.status === UserRequestStatus.IdentityVerificationFailed ||
                  requestStatus.status === UserRequestStatus.DataModificationDeclined
                }
                disabled={requestStatus.status === UserRequestStatus.ReportExpired}
              />
            </div>
          )}
        </div>
      )}
      <BigidMeSessionController
        sessionClosingStage={sessionClosingStage}
        setSessionClosingStage={setSessionClosingStage}
      />
    </BigidMeWireframe>
  );
};
