import makeStyles from '@mui/styles/makeStyles';
import { blue, gray } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles({
  root: ({ width }: { width: number | string }) => ({
    width,
    height: 9,
    margin: 0,
    backgroundColor: gray[250],
    borderRadius: 4.5,
    display: 'inline-flex',
    '&::before': {
      content: '""',
      width,
      height: 9,
      margin: 0,
      borderRadius: 4.5,
      background: `linear-gradient(to right, ${blue[750]}, ${blue[750]} 100%)`,
      animation: 'running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite',
    },
  }),
  '@global': {
    '@keyframes running-progress': {
      '0%': { marginLeft: '0', marginRight: '100%' },
      '50%': { marginLeft: '25%', marginRight: '0%' },
      '100%': { marginLeft: '100%', marginRight: '0' },
    },
  },
});
