import React, { FC, ReactElement, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

import { StyledBigidMeStatus } from './BigidMeStatusStyles';
import { e2e } from '../../utils';
import { RequestId } from '../RequestId';

export interface BigidMeStatusPropsType {
  title: string;
  description: ReactElement | string;
  icon: ReactNode;
  children?: ReactElement;
  requestId?: string;
}

export const BigidMeStatus: FC<BigidMeStatusPropsType> = ({ title, description, icon, requestId, children }) => (
  <StyledBigidMeStatus.Root>
    <StyledBigidMeStatus.Layout>
      <Box mb={8}>{icon}</Box>
      <Box mb={6}>
        <Typography textAlign="center" variant="h1" color="textSecondary" {...e2e('request_status_title')}>
          {title}
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography textAlign="center" variant="subtitle1" color="textPrimary" {...e2e('request_status_description')}>
          {description}
        </Typography>
      </Box>
      {requestId && <RequestId requestId={requestId} />}
      {children}
    </StyledBigidMeStatus.Layout>
  </StyledBigidMeStatus.Root>
);
