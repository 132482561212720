import React from 'react';
import { Root, classes } from './BigidMeMessageStyles';
import { ReactComponent as BigidMeIconClip } from '../../assets/icons/clip.svg';
import { ReactComponent as BigidMeIconAvatar } from '../../assets/icons/avatar.svg';
import { MessageDto, requestService } from '../../services';
import { TenantContainer } from '../../state/tenantContainer';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { useResponsive } from '../../hooks/useResponsive';
import { LanguageContainer } from '../../state/languageContainer';
import { parseHTML } from '../../utils';
import { formatDateToLocal } from '../../utils/dateUtils';
import { AMERICAN_LONG_FULL_DATE_FORMAT } from '../../constants/dateFormats';

export interface BigidMeMessagePropsType {
  testId?: string;
  inList?: boolean;
  message?: MessageDto;
}

export const BigidMeMessage = ({ testId, inList, message }: BigidMeMessagePropsType) => {
  const { tenant } = TenantContainer.useContainer();
  const isMobile = useResponsive();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const handleDownloadFile = (fileId: number, fileName: string, requestId: string, messageSecret: string) => {
    requestService.getRequestMessageFile(fileId, fileName, requestId, messageSecret);
  };

  const { requestId, messagesSecret } = useParams();

  return (
    <Root>
      <div className={classes.wrapper} data-aid={`testId-${message?.id}`}>
        {inList && (
          <div className={classes.avatar}>
            {message?.toConsumer && tenant.logoUrl ? (
              <img className={classes.avatarImg} src={tenant.logoUrl} alt="avatar" />
            ) : (
              <BigidMeIconAvatar title={BigIdMeTranslate('consumer_web_avatar')} />
            )}
          </div>
        )}
        {message && (
          <div className={classes.message}>
            <p className={classes.messageSubject}>{inList ? message?.sentBy : message?.subject}</p>
            <span className={classes.messageInfo}>{`${
              inList
                ? message.title
                : `${!isMobile ? BigIdMeTranslate('consumer_web_messages_send_by') : ''} ${message?.sentBy}`
            } ${formatDateToLocal(message?.sentDate, AMERICAN_LONG_FULL_DATE_FORMAT)}`}</span>
            <div className={classes.richText} data-aid={`${testId}-${message.id}_text`}>
              {message.removed
                ? BigIdMeTranslate(
                    'consumer_web_messages_communication_content_was_removed_according_to_the_retention_policy',
                  )
                : parseHTML(message?.content, true)}
            </div>
            <div className={classes.filesContainer}>
              {message.files.map((file, i) => (
                <div
                  className={cx(classes.fileContainer, { [classes.disabled]: file.removed })}
                  key={i + file.id}
                  onClick={() =>
                    requestId && messagesSecret && handleDownloadFile(file.id, file.fileName, requestId, messagesSecret)
                  }
                  data-aid={`${testId}-message-file-${message.id}-${i}`}
                >
                  <BigidMeIconClip className={classes.icon} title={BigIdMeTranslate('consumer_web_clip')} />
                  <span className={classes.messageFileName}>{file.fileName}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Root>
  );
};
