import React, { ChangeEvent, FC, ReactNode, useEffect, useState, InputHTMLAttributes, Ref } from 'react';
import { Input, InputBaseComponentProps } from '@mui/material';

import { useStyles } from './BigidMeTextFieldStyles';
import { e2e, WithTest } from '../../utils';
import { FieldWrapper } from '../FieldWrapper';

export interface BigidMeTextFieldPropsType extends WithTest {
  value?: string;
  label?: ReactNode;
  defaultValue?: string;
  placeholder?: string;
  required?: boolean;
  error?: string;
  disabled?: boolean;
  multiline?: boolean;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  customClasses?: {
    root?: string;
    labelRoot?: string;
    inputRoot?: string;
  };
  id?: string;
  rows?: number;
  endAdornment?: ReactNode;
  inputRef?: Ref<any>;
  inputProps?: InputBaseComponentProps;
  helper?: string;
  helperEndAdornment?: ReactNode;
}

export const BigidMeTextField: FC<BigidMeTextFieldPropsType> = ({
  value,
  defaultValue,
  label,
  placeholder,
  required,
  error,
  disabled,
  multiline,
  onChange,
  onBlur,
  customClasses = {},
  id,
  rows,
  endAdornment,
  inputRef,
  inputProps,
  helper,
  helperEndAdornment,
  ...rest
}) => {
  const classes = useStyles();
  const handleChange = (e: ChangeEvent): void => {
    const value = (e.target as HTMLInputElement).value;
    setInternalValue(value);
    onChange && onChange(value);
  };
  const [internalValue, setInternalValue] = useState('');

  useEffect(() => {
    if (defaultValue !== undefined) {
      setInternalValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value !== undefined) {
      setInternalValue(value);
    }
  }, [value]);

  return (
    <FieldWrapper
      label={label}
      required={required}
      error={error}
      customClasses={customClasses}
      id={id}
      helper={helper}
      data-aid={rest['data-aid']}
      fullWidth={multiline}
      helperEndAdornment={helperEndAdornment}
    >
      <Input
        placeholder={placeholder}
        id={id}
        onChange={handleChange}
        onBlur={() => onBlur && onBlur()}
        disableUnderline
        disabled={disabled}
        error={!!error}
        value={internalValue}
        classes={{
          root: `${classes.inputRoot} ${customClasses?.inputRoot}`,
          input: classes.input,
          error: classes.inputError,
          disabled: classes.inputDisabled,
          multiline: classes.multiline,
          focused: classes.inputFocus,
        }}
        rows={rows}
        multiline={multiline}
        inputProps={
          {
            ...e2e(rest['data-aid']),
            ...(inputProps || {}),
            ['aria-describedby']: error ? `${id}-error` : undefined,
          } as InputHTMLAttributes<HTMLInputElement>
        }
        endAdornment={endAdornment}
        inputRef={inputRef}
      />
    </FieldWrapper>
  );
};
