import React, { FC, MouseEvent, ReactText } from 'react';
import classNames from 'classnames';
import { e2e, WithTest } from '../../utils';

import { useStyles } from './BigidMeToggleStyles';

export interface BigidMeToggleType extends WithTest {
  id: ReactText;
  checked: boolean;
  onChange?: (id: ReactText, checked: boolean) => void;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  className?: string;
  'aria-labelledby'?: string;
}

export const BigidMeToggle: FC<BigidMeToggleType> = ({ id, checked, onChange, onClick, className, ...rest }) => {
  const classes = useStyles({ clickable: !!onChange });

  return (
    <div className={classNames(classes.root, className)} onClick={onClick} {...e2e(rest['data-aid'])}>
      <input
        type="checkbox"
        name={id.toString()}
        className={classes.checkbox}
        id={`MeSwitchToggle-${id}`}
        tabIndex={0}
        checked={checked}
        onChange={({ target: { checked } }) => onChange?.(id, checked)}
        aria-labelledby={rest['aria-labelledby']}
        {...e2e(rest['data-aid'], '-input')}
      />
      <label className={classes.label} htmlFor={`MeSwitchToggle-${id}`} />
    </div>
  );
};
