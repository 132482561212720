import React, { FC, ReactNode } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { ReactComponent as BigidMeIconShield } from '../../../assets/icons/shield.svg';
import { WithTest, e2e } from '../../../utils';

type FieldHelpTextPropsType = WithTest & {
  text: string;
  endAdornment?: ReactNode;
};

export const FieldHelpText: FC<FieldHelpTextPropsType> = ({ text, endAdornment, ...rest }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box flexShrink={0} alignItems="center">
        <BigidMeIconShield title={text} />
      </Box>
      <Typography fontSize="0.813rem" fontWeight={300} {...e2e(rest['data-aid'])}>
        {text} {endAdornment}
      </Typography>
    </Stack>
  );
};
