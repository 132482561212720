import React, { FC, useCallback, ChangeEvent, useEffect } from 'react';
import {
  Divider,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  StyledEngineProvider,
  FormControl,
  FormLabel,
} from '@mui/material';
import { ReactComponent as BigidMeIconRadioSelectedBlue } from '../../assets/icons/Radio_on_blue.svg';
import { ReactComponent as BigidMeIconRadioEmpty } from '../../assets/icons/Radio_off_black.svg';
import { e2e, WithTest } from '../../utils';
import { Root, classes } from './BigidMeRadioGroupStyles';
import { useResponsive } from '../../hooks/useResponsive';

export interface DeliveryMethodPropsType extends WithTest {
  onChange: (value: string) => void;
  label: string;
  radioGroupOptions: { label: string; value: string }[];
  defaultRadioValue?: string;
}

export const BigidMeRadioGroup: FC<DeliveryMethodPropsType> = ({
  label,
  onChange,
  radioGroupOptions,
  defaultRadioValue,
  ...rest
}) => {
  const isMobile = useResponsive();

  const onMethodChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      onChange(value);
    },
    [onChange],
  );

  useEffect(() => {
    if (defaultRadioValue) {
      onChange(defaultRadioValue);
    }
  }, [defaultRadioValue]);

  return (
    <StyledEngineProvider injectFirst>
      <Root>
        <FormControl component="fieldset" classes={{ root: classes.fromControl }} {...e2e(rest['data-aid'])}>
          <FormLabel component="legend">
            <InputLabel shrink classes={{ root: classes.labelRoot }} id="radio_label">
              {label}
            </InputLabel>
          </FormLabel>
          <Divider className={classes.divider} />
          <RadioGroup name="name" defaultValue={defaultRadioValue} row={!isMobile} defaultChecked={true}>
            {radioGroupOptions.map(({ label, value }) => (
              <FormControlLabel
                key={value}
                value={value}
                htmlFor={value}
                className={classes.radioLabel}
                control={
                  <Radio
                    id={value}
                    disableRipple
                    onChange={onMethodChange}
                    icon={<BigidMeIconRadioEmpty />}
                    checkedIcon={<BigidMeIconRadioSelectedBlue />}
                    {...e2e(`${rest['data-aid']}_${value}`)}
                  />
                }
                label={label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Root>
    </StyledEngineProvider>
  );
};
