import React, { FC, ReactElement, InputHTMLAttributes } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { WithTest, e2e } from '../../utils';
import { ReactComponent as BigidMeIconCheckboxChecked } from '../../assets/icons/Checkbox_Selected.svg';
import { ReactComponent as BigidMeIconCheckboxEmpty } from '../../assets/icons/Checkbox_empty.svg';

import { useStyles } from './BigidMeCheckboxStyles';
import { LanguageContainer } from '../../state/languageContainer';

export type BigidMeCheckboxType = WithTest & {
  id?: string;
  title: string | ReactElement | JSX.Element[];
  checked: boolean;
  onChange: (checked: boolean) => void;
  testId?: string;
};

export const BigidMeCheckbox: FC<BigidMeCheckboxType> = ({ id, title, checked, onChange, testId }) => {
  const classes = useStyles();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  return (
    <FormControlLabel
      className={classes.item}
      control={
        <Checkbox
          id={id}
          onChange={({ target: { checked } }) => onChange(checked)}
          className={classes.input}
          checked={checked}
          icon={<BigidMeIconCheckboxEmpty title={BigIdMeTranslate('consumer_web_checkbox_empty')} />}
          checkedIcon={<BigidMeIconCheckboxChecked title={BigIdMeTranslate('consumer_web_checkbox_selected')} />}
          inputProps={{ ...e2e(testId), ['aria-labelledby']: `${id}-label` } as InputHTMLAttributes<HTMLInputElement>}
        />
      }
      label={
        <Typography variant="subtitle1" color="textPrimary" data-aid={`${testId}_label`} id={`${id}-label`}>
          <b>{title}</b>
        </Typography>
      }
    />
  );
};
