import { api } from '../apiService';
import { VendorDetailsType } from './types';

const verifyEmail = (vendorRequestId: string, formData: { email: string; masterSecret: string }) => {
  return api.post(`/vendor-requests/${vendorRequestId}/secrets`, formData).then(res => res.data);
};

const getVendorDetails = (vendorRequestId: string, formData: { secret: string }) => {
  return api.post<VendorDetailsType>(`/vendor-requests/${vendorRequestId}`, formData).then(res => res.data);
};

const uploadVendorRequestFiles = (formData: FormData): Promise<string> =>
  api.post<{ uploadId: string }>('/vendor-request-files', formData).then(res => res.data.uploadId);

const postVendorRequestResolutions = (
  vendorRequestId: string,
  formData: {
    secret: string;
    resolution: string;
    comment?: string;
    files?: Array<{
      fileUploadId: string;
    }>;
  },
) => {
  return api.post<VendorDetailsType>(`/vendor-requests/${vendorRequestId}/resolutions`, formData).then(res => res.data);
};

export const vendorService = {
  verifyEmail,
  getVendorDetails,
  uploadVendorRequestFiles,
  postVendorRequestResolutions,
};
