import React, { FC, useState, useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import { FormControl, FormLabel, FormHelperText, InputLabel, StyledEngineProvider } from '@mui/material';
import { ReactComponent as BigidMeIconShield } from '../../assets/icons/shield.svg';
import { BigidMeFileUploader } from '../BigidMeFileUploader';
import { requestService, UserFilesType } from '../../services';
import { LanguageContainer } from '../../state/languageContainer';
import { ErrorMessages } from '../../constants/errorCodes';
import { ErrorResponse, WithTest } from '../../utils';
import { Root, classes } from './BigidMeUploadDocumentsFieldStyles';
import { parseHTML } from '../../utils';

export interface BigidMeUploadDocumentsFieldProps extends WithTest {
  onChange: (userFiles: UserFilesType | string) => void;
  onBlur?: () => void;
  value?: string;
  error?: string;
  required?: boolean;
  label?: string;
  tooltip?: string;
  description?: string;
  id: string;
}

export const BigidMeUploadDocumentsField: FC<BigidMeUploadDocumentsFieldProps> = ({
  error,
  onChange,
  onBlur,
  required,
  label,
  tooltip,
  description,
  id,
}) => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const [loadError, setLoadError] = useState('');
  const onSelectFile = useCallback(
    async (fileFormData: FormData) => {
      try {
        const uploadId = await requestService.uploadVerificationPhoto(fileFormData);
        onBlur && onBlur();
        onChange({ uploadId, id });
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const axiosError: AxiosError = err;
          setLoadError(BigIdMeTranslate(ErrorMessages[(axiosError?.response?.data as ErrorResponse).errorCode]));
        }
      }
    },
    [onChange, onBlur, BigIdMeTranslate],
  );

  const onRemove = useCallback(() => {
    onChange('');
  }, [onChange]);

  return (
    <StyledEngineProvider injectFirst>
      <Root>
        <FormControl
          component="fieldset"
          sx={{
            '.MuiFormGroup-root': {
              flexDirection: 'row',
              paddingBottom: '15px',
            },
            '.MuiFormControlLabel-label': {
              fontSize: '0.853rem',
            },
          }}
        >
          <FormLabel component="legend">
            <InputLabel shrink classes={{ root: classes.labelRoot }}>
              <div>
                {label}
                {required && (
                  <span className={classes.italic}>&nbsp;({BigIdMeTranslate('consumer_web_validation_required')})</span>
                )}
                <br />
                {description && parseHTML(description)}
              </div>
            </InputLabel>
          </FormLabel>
        </FormControl>
        <BigidMeFileUploader
          subtext={BigIdMeTranslate('consumer_web_submit_fileloader_png_jpeg_or_pdf_format_no_larger_than_10_mb')}
          format=".jpg, .jpeg, .png, .pdf"
          onSelect={onSelectFile}
          onRemove={onRemove}
          label="Click here to upload your file"
        />
        {loadError && <FormHelperText error>{loadError}</FormHelperText>}
        {error && <FormHelperText error>{error}</FormHelperText>}
        <div className={classes.helperWrapper}>
          <BigidMeIconShield />
          &nbsp;&nbsp; {tooltip || BigIdMeTranslate('consumer_web_submit_field.userPhoto.tooltip')}
        </div>
      </Root>
    </StyledEngineProvider>
  );
};
