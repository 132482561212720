import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { LanguageContainer } from '../../state/languageContainer';
import { e2e } from '../../utils';
import { useStyles } from './RequestIdStyles';

interface RequestIdPropsType {
  requestId: string;
}

export const RequestId: FC<RequestIdPropsType> = ({ requestId }) => {
  const classes = useStyles();
  const { BigIdMeTranslateWithNode } = LanguageContainer.useContainer();

  return (
    <div className={classes.requestId}>
      <Typography variant="subtitle2" color="textPrimary">
        {BigIdMeTranslateWithNode('consumer_web_status_request-id', [
          {
            key: 'request_id',
            value: (
              <span className={classes.extraBold} {...e2e('request_id')}>
                {requestId}
              </span>
            ),
          },
        ])}
      </Typography>
    </div>
  );
};
