import React, { ReactNode, FC } from 'react';
import { BigidBody1, BigidHeading3, BigidPaper } from '@bigid-ui/components';
import { Box } from '@mui/material';

type VendorRequestViewPropsType = {
  title: string;
  description: ReactNode;
  icon: ReactNode;
  children?: ReactNode;
};

export const VendorRequestView: FC<VendorRequestViewPropsType> = ({ title, description, children, icon }) => (
  <BigidPaper
    paperProps={{
      sx: {
        maxWidth: '424px',
        minHeight: '296px',
        maxHeight: '444px',
        padding: '40px 60px',
        margin: '0 16px',
        display: 'flex',
        '&&': { flexGrow: 0 },
        alignItems: 'center',
        gap: 2.5,
        borderRadius: 2,
      },
    }}
  >
    <Box flexGrow={1}>{icon}</Box>
    <BigidHeading3
      textAlign="center"
      mb={1}
      variantMapping={{
        h3: 'h1',
      }}
    >
      {title}
    </BigidHeading3>
    <BigidBody1 textAlign="center" fontSize="small">
      {description}
    </BigidBody1>
    {children}
  </BigidPaper>
);
