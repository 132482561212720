/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, MouseEvent, useMemo, useState } from 'react';
import cx from 'classnames';
import MenuIcon from '@mui/icons-material/Menu';
import { StyledEngineProvider, Button, Menu, MenuItem, Theme } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ReactComponent as LanguageIcon } from '../../assets/icons/mobile_language.svg';
import { useStyles } from './MeBurgerMenuStyles';
import { LanguageContainer, LanguageType } from '../../state/languageContainer';
import { black, gray, stepperInactive } from '../../assets/theme/BigidMeColors';

export interface MeBurgerMenuPropsType {
  bottomLinks: { title: string; url?: string; icon: JSX.Element }[];
  onLanguageChange: (value: LanguageType) => void;
  hideLanguageControls?: boolean;
}

export const MeBurgerMenu: FC<MeBurgerMenuPropsType> = ({ bottomLinks, onLanguageChange, hideLanguageControls }) => {
  const classes = useStyles();
  const { language, availableLanguages, BigIdMeTranslate, isRtl } = LanguageContainer.useContainer();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenLanguageMenu, setOpenLanguageMenu] = useState(false);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenLanguageMenu(false);
  };

  const handleLanguageChange = (value: LanguageType) => {
    onLanguageChange(value);
    handleClose();
  };

  const openLanguageMenu = () => {
    setOpenLanguageMenu(true);
  };

  const currentLanguage = useMemo(
    () => availableLanguages?.filter(item => item.value === language)[0]?.label,
    [availableLanguages, language],
  );

  return (
    <StyledEngineProvider injectFirst>
      <Button
        aria-label="Menu"
        aria-controls="mobile-burger-menu"
        aria-haspopup="true"
        onClick={handleClick}
        data-aid="mobile-burger-menu-button"
        sx={{
          '&': {
            position: 'relative',
            top: 0,
            left: '-10px',
            padding: '12px',
            minWidth: '45px',
            color: black,
          },
          '& svg': {
            fill: black,
          },
        }}
      >
        <MenuIcon />
      </Button>
      <Menu
        id="mobile-burger-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '.MuiPopover-root': {
            background: 'rgba(0,0,0,0.47)',
          },
          '.MuiPopover-paper': {
            overflowY: 'hidden',
            maxHeight: '100%',
            top: '0 !important',
            ...(isRtl ? { right: '0 !important', left: 'auto !important' } : { left: '0 !important' }),
          },
          '.MuiMenu-paper': {
            maxHeight: '100%',
          },
          '.MuiList-root': {
            height: '100vh',
            padding: 0,
            paddingTop: 0,
            paddingBottom: 0,
          },
          '.MuiList-padding': {
            paddingTop: 0,
            paddingBottom: 0,
          },
          '.MuiMenuItem-root': {
            minWidth: '261px',
            fontSize: '1rem',
            '& svg': {
              verticalAlign: 'middle',
              marginInlineEnd: '10px',
            },
            '& a': {
              color: gray[775],
              textDecoration: 'none',
            },
          },
          '.MuiPaper-rounded': {
            borderRadius: 0,
            backgroundColor: '#FFFFFF',
          },
        }}
      >
        {!hideLanguageControls && availableLanguages && availableLanguages!.length > 1 && (
          <div className={classes.languageWrapper}>
            <MenuItem onClick={openLanguageMenu}>
              <div className={classes.currentLanguage}>
                <span>
                  <LanguageIcon /> {currentLanguage}
                </span>
                {isOpenLanguageMenu ? (
                  <ArrowDropUpIcon style={{ color: stepperInactive }} />
                ) : (
                  <ArrowDropDownIcon style={{ color: stepperInactive }} />
                )}
              </div>
            </MenuItem>
            <ul className={cx(classes.languageMenu, { [classes.languageMenuOpen]: isOpenLanguageMenu })}>
              {availableLanguages?.map(({ label, value }, idx) => (
                <li
                  key={`${value}_${idx}`}
                  className={cx(classes.langItem, { [classes.checked]: value === language })}
                  onClick={() => handleLanguageChange(value)}
                >
                  {label}
                </li>
              ))}
            </ul>
          </div>
        )}
        {bottomLinks &&
          bottomLinks.map(
            ({ url, title, icon }) =>
              !!url && (
                <MenuItem key={title}>
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    {icon}
                    {title}
                  </a>
                </MenuItem>
              ),
          )}
      </Menu>
    </StyledEngineProvider>
  );
};
