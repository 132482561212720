import makeStyles from '@mui/styles/makeStyles';
import { white } from '../../assets/theme/BigidMeColors';
import success from '../../assets/icons/true.svg';

export const useStyles = makeStyles(theme => ({
  root: ({ clickable }: { clickable: boolean }) => ({
    position: 'relative',
    width: 29,
    userSelect: 'none',
    flexShrink: 0,
    ...(!clickable ? { pointerEvents: 'none' } : {}),

    '&:focus-within': {
      outline: '2px solid #888',
      outlineOffset: '1px',
    },
    [theme.breakpoints.down('sm')]: {
      width: 37,
    },
  }),
  checkbox: {
    position: 'absolute',
    opacity: 0,
    pointerEvents: 'none',
    '&:checked + label': {
      backgroundColor: '#292929',
      borderColor: '#292929',
      '&:before': {
        backgroundImage: `url(${success})`,
        right: 0.5,
      },
    },
  },
  label: {
    display: 'block',
    overflow: 'hidden',
    cursor: 'pointer',
    height: 17,
    padding: 0,
    lineHeight: 17,
    border: '1px solid #A8A7A7',
    borderRadius: 17,
    backgroundColor: 'rgba(143,155,179,0.16)',
    transition: 'background-color 0.3s ease-in',

    [theme.breakpoints.down('sm')]: {
      height: 23,
    },

    '&:before': {
      content: '""',
      display: 'block',
      width: 15,
      margin: '1px 0',
      background: white,
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 13.5,
      borderRadius: 15,
      transition: 'all 0.3s ease-in 0s',
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',

      [theme.breakpoints.down('sm')]: {
        right: 14,
        width: 21,
      },
    },
  },
}));
