import { TenantContainer } from '../../state/tenantContainer';
import { Helmet } from 'react-helmet';
import React from 'react';

export const BigidMeFavicon = () => {
  const { tenant } = TenantContainer.useContainer();
  return (
    <Helmet>
      <link rel="icon" href={tenant?.favicon} />
    </Helmet>
  );
};
