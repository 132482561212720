import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: `${theme.spacing(6)} 0 ${theme.spacing(4)} 0`,
  },
  iconButton: {
    padding: '0 4px',
    verticalAlign: 'baseline',
  },
}));
