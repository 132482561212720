import React, { FC, MouseEvent, ReactElement } from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import { useStyles } from './BigidMeClickableDropdownStyles';

interface Props {
  placeholder: string | ReactElement;
  options?: OptionType[];
  onChange?: (e?: any) => void;
  onClick?: (item: any, e?: MouseEvent) => void;
}

type OptionType = {
  id: string;
  label: string;
};

export const BigidMeClickableDropdown: FC<Props> = ({ placeholder, options = [], onChange, onClick }) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.selectContainer}>
      <Select
        value="placeholder"
        onChange={onChange}
        className={classes.selectInput}
        MenuProps={{
          PaperProps: {
            className: classes.selectMenu,
          },
        }}
      >
        <MenuItem className={classes.placeholderMenuItem} disabled value="placeholder">
          {placeholder}
        </MenuItem>
        {options.map(({ id, label }) => (
          <div key={id}>
            <MenuItem className={classes.selectMenuItem} value={label} onClick={e => onClick?.({ id, label }, e)}>
              {label}
            </MenuItem>
          </div>
        ))}
      </Select>
    </FormControl>
  );
};
