import React, { FC, useEffect } from 'react';
import { BigidMeRequestInfoWrapper } from '../../components/BigidMeRequestInfoWrapper';
import { TenantContainer } from '../../state/tenantContainer';
import { LanguageContainer } from '../../state/languageContainer';

import { useExpiredLinkErrorStyles } from './ErrorsStyles';

export const ExpiredLinkError: FC = () => {
  const { tenant, loadTenantSettings } = TenantContainer.useContainer();
  const { BigIdMeTranslate, BigIdMeTranslateWithNode } = LanguageContainer.useContainer();
  const classes = useExpiredLinkErrorStyles();

  useEffect(() => {
    loadTenantSettings({});
  }, [loadTenantSettings]);

  return (
    <BigidMeRequestInfoWrapper
      componentNaming="ExpiredLinkError"
      pageTitle={BigIdMeTranslate('consumer_web_errors_link-expired')}
    >
      {BigIdMeTranslate('consumer_web_errors_for-your-protection')}
      <br />
      {BigIdMeTranslateWithNode('consumer_web_errors_click-to-submit-another', [
        {
          key: 'click_here',
          value: (
            <a className={classes.link} href={`#/${tenant.tenantId}`}>
              {BigIdMeTranslate('consumer_web_general_click-here')}
            </a>
          ),
        },
      ])}
    </BigidMeRequestInfoWrapper>
  );
};
