import React from 'react';
import { ClassNameMap } from '@mui/styles/withStyles';
import { PasscodeType, RequestStatusTypes } from '../services';
import { UserRequestStatus } from '../constants/userRequestStatus';
import { BigIdMeTranslateType, BigIdMeTranslateWithNodeType } from '../state/languageContainer';
import { e2e } from './testing';

interface PlaceholdersType {
  type: RequestStatusTypes;
  requestId?: string;
  expirationDate?: string;
  expirationDays?: number;
  contactLink?: string;
  submitNewRequestLink?: string;
  classes?: ClassNameMap;
  BigIdMeTranslate: BigIdMeTranslateType;
  BigIdMeTranslateWithNode: BigIdMeTranslateWithNodeType;
  isConfidential?: boolean;
  passcodeType: PasscodeType;
  state?: UserRequestStatus;
}

export const RequestProcessingState = {
  [UserRequestStatus.Received]: ({ type, BigIdMeTranslate }: PlaceholdersType) => ({
    name: BigIdMeTranslate('consumer_web_status_request-received'),
    description: BigIdMeTranslate('consumer_web_status_request-received-description', [
      { key: 'type', value: BigIdMeTranslate(`consumer_web_status_request-type-${type.toLowerCase()}`) },
    ]),
  }),
  [UserRequestStatus.IdentityVerified]: ({ type, BigIdMeTranslate }: PlaceholdersType) => ({
    name: BigIdMeTranslate('consumer_web_status_identity-verified'),
    description: BigIdMeTranslate('consumer_web_status_identity-verified-description', [
      { key: 'type', value: BigIdMeTranslate(`consumer_web_status_request-type-${type.toLowerCase()}`) },
    ]),
  }),
  [UserRequestStatus.InformationProcessed]: ({
    type,
    BigIdMeTranslate,
    isConfidential,
    passcodeType,
  }: PlaceholdersType) => ({
    name: BigIdMeTranslate('consumer_web_status_information-processed'),
    description:
      type === RequestStatusTypes.UPDATE
        ? BigIdMeTranslate(
            isConfidential || passcodeType === PasscodeType.NONE
              ? 'consumer_web_status_information-processed-description-update-secured'
              : 'consumer_web_status_information-processed-description-update',
            [{ key: 'type', value: BigIdMeTranslate(`consumer_web_status_request-type-${type.toLowerCase()}`) }],
          )
        : BigIdMeTranslate('consumer_web_status_information-processed-description', [
            { key: 'type', value: BigIdMeTranslate(`consumer_web_status_request-type-${type.toLowerCase()}`) },
          ]),
  }),
  [UserRequestStatus.RequestCompleted]: ({
    type,
    requestId,
    expirationDays,
    expirationDate,
    classes,
    BigIdMeTranslate,
    BigIdMeTranslateWithNode,
  }: PlaceholdersType) => ({
    name: BigIdMeTranslate('consumer_web_status_request-completed'),
    description: (
      <div {...e2e('request-status-description')}>
        {BigIdMeTranslateWithNode('consumer_web_status_request-completed-description1', [
          { key: 'type', value: type.toLowerCase() },
          { key: 'request_id', value: <span className={classes!.extraBold}>{requestId}</span> },
        ])}
        <br />
        {BigIdMeTranslate('consumer_web_status_request-completed-description3', [
          { key: 'expiration_days', value: expirationDays!.toString() },
          { key: 'expiration_date', value: expirationDate! },
        ])}
      </div>
    ),
  }),
  [UserRequestStatus.DataModified]: ({ BigIdMeTranslate }: PlaceholdersType) => ({
    name: BigIdMeTranslate('consumer_web_status_data-modified'),
    description: <div>{BigIdMeTranslate('consumer_web_status_data-modified-description')}</div>,
  }),
  [UserRequestStatus.DataModificationDeclined]: ({
    contactLink,
    classes,
    BigIdMeTranslate,
    BigIdMeTranslateWithNode,
  }: PlaceholdersType) => ({
    name: BigIdMeTranslate('consumer_web_status_request-declined'),
    description: (
      <div>
        {BigIdMeTranslate('consumer_web_status_modification-declined-description')}
        {BigIdMeTranslateWithNode('consumer_web_status_please-contact-support', [
          {
            key: 'contact_us',
            value: (
              <a className={classes!.link} href={contactLink || ''}>
                {BigIdMeTranslate('consumer_web_general_contact')}
              </a>
            ),
          },
        ])}
      </div>
    ),
  }),
  [UserRequestStatus.ReportExpired]: ({
    submitNewRequestLink,
    classes,
    BigIdMeTranslate,
    BigIdMeTranslateWithNode,
  }: PlaceholdersType) => ({
    name: BigIdMeTranslate('consumer_web_status_report-expired'),
    description: (
      <div>
        {BigIdMeTranslate('consumer_web_status_report-expired-description')}
        <br />
        {BigIdMeTranslateWithNode('consumer_web_status_report-expired-another', [
          {
            key: 'click_here',
            value: (
              <a className={classes!.link} href={submitNewRequestLink || ''}>
                {BigIdMeTranslate('consumer_web_general_click-here')}
              </a>
            ),
          },
        ])}
      </div>
    ),
  }),
  [UserRequestStatus.NoDataFound]: ({
    contactLink,
    classes,
    BigIdMeTranslate,
    BigIdMeTranslateWithNode,
  }: PlaceholdersType) => ({
    name: BigIdMeTranslate('consumer_web_status_no-data'),
    description: (
      <div>
        {BigIdMeTranslate('consumer_web_status_no-data-description')}
        <br />
        {BigIdMeTranslateWithNode('consumer_web_status_please-contact-info', [
          {
            key: 'contact_us',
            value: (
              <a className={classes!.link} href={contactLink || ''}>
                {BigIdMeTranslate('consumer_web_general_contact')}
              </a>
            ),
          },
        ])}
      </div>
    ),
  }),
  [UserRequestStatus.IdentityVerificationFailed]: ({
    requestId,
    classes,
    contactLink,
    BigIdMeTranslate,
    BigIdMeTranslateWithNode,
  }: PlaceholdersType) => ({
    name: BigIdMeTranslate('consumer_web_status_request-declined'),
    description: (
      <div>
        {BigIdMeTranslateWithNode('consumer_web_status_request-declined-description', [
          {
            key: 'request_id',
            value: <span className={classes!.extraBold}>{requestId}</span>,
          },
        ])}
        <br />
        {BigIdMeTranslateWithNode('consumer_web_status_please-contact-support', [
          {
            key: 'contact_us',
            value: (
              <a className={classes!.link} href={contactLink || ''}>
                {BigIdMeTranslate('consumer_web_general_contact')}
              </a>
            ),
          },
        ])}
      </div>
    ),
  }),
  // TODO: deprecated since 01.04.2023
  [UserRequestStatus.DataDeleted_]: ({ BigIdMeTranslate }: PlaceholdersType) => ({
    name: BigIdMeTranslate('consumer_web_status_data-deleted'),
    description: (
      <div>
        {BigIdMeTranslate('consumer_web_status_data-deleted-description1')}
        <br />
        {BigIdMeTranslate('consumer_web_status_data-deleted-description2')}
      </div>
    ),
  }),
  [UserRequestStatus.DataDeleted]: ({ BigIdMeTranslate }: PlaceholdersType) => ({
    name: BigIdMeTranslate('consumer_web_status_data-deleted'),
    description: (
      <div>
        {BigIdMeTranslate('consumer_web_status_data-deleted-description1')}
        <br />
        {BigIdMeTranslate('consumer_web_status_data-deleted-description2')}
      </div>
    ),
  }),
  [UserRequestStatus.Issued]: ({ BigIdMeTranslate }: PlaceholdersType) => ({
    name: BigIdMeTranslate('consumer_web_status_optout-issued'),
    description: <div>{BigIdMeTranslate('consumer_web_status_optout-issued-description')}</div>,
  }),
  [UserRequestStatus.Default]: ({
    requestId,
    classes,
    contactLink,
    BigIdMeTranslate,
    BigIdMeTranslateWithNode,
    state,
  }: PlaceholdersType) => ({
    name: BigIdMeTranslate('consumer_web_status_request-declined'),
    description: (
      <div>
        {BigIdMeTranslateWithNode('consumer_web_status_request-id-declined', [
          {
            key: 'request_id',
            value: <span className={classes!.extraBold}>{requestId}</span>,
          },
        ])}{' '}
        {state}
        <br />
        {BigIdMeTranslateWithNode('consumer_web_status_please-contact-support', [
          {
            key: 'contact_us',
            value: (
              <a className={classes!.link} href={contactLink || ''}>
                {BigIdMeTranslate('consumer_web_general_contact')}
              </a>
            ),
          },
        ])}
      </div>
    ),
  }),
};
