import { format, addMinutes, isValid } from 'date-fns';

export const toUTCDate = (date: Date): Date => {
  return addMinutes(date, date.getTimezoneOffset());
};

/**
 * BE sending UTC date WITHOUT timezone, so format function represent it as local date.
 * Use formatDateToLocal in this case.
 *
 * Normalize UTC date value without timezone and then format in local date.
 */
export const formatDateToLocal = (date: string, formatStr: string) => {
  try {
    const normalizedUTCDateStr = date + 'Z';
    if (isValid(new Date(normalizedUTCDateStr))) {
      return format(new Date(normalizedUTCDateStr), formatStr);
    } else {
      return format(new Date(date), formatStr);
    }
  } catch (error) {
    console.log(error);
  }
};
