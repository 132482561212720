import { Typography } from '@mui/material';
import { styled } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { gray } from '../../assets/theme';

export const useExpiredLinkErrorStyles = makeStyles(() => ({
  link: {
    fontWeight: 600,
    color: '#2177CC',
    textDecoration: 'none',
    '&:lang(ru)': {
      fontWeight: 500,
    },
    '&:lang(ja), &:lang(zh)': {
      fontWeight: 400,
    },
  },
}));

export const useSessionClosedErrorStyles = makeStyles(() => ({
  buttonWrapper: {
    textDecoration: 'none',
    margin: '10px auto 0',
    '&& button': {
      textTransform: 'none',
    },
  },
}));

export const useStylesAction = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0 23px',
    textAlign: 'center',
    height: 'calc(100vh - 120px)',
    minHeight: 200,
    '& a': {
      ontWeight: 600,
      color: '#2177CC',
      textDecoration: 'none',
    },
  },
  title: {
    marginTop: 70,
  },
  text: {
    marginTop: 20,
  },
}));

export const useStylesUnexpected = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0 23px',
    textAlign: 'center',
    height: 'calc(100vh - 120px)',
    minHeight: 200,
    '& a': {
      ontWeight: 600,
      color: '#2177CC',
      textDecoration: 'none',
    },
  },
  title: {
    marginTop: 70,
  },
  text: {
    marginTop: 20,
  },
}));

export const StyledErrorPage = {
  Container: styled('div')({
    '&&': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',

      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  }),
  Title: styled(Typography)({
    '&&': {
      fontWeight: 800,
      fontSize: '1.875rem',
      lineHeight: '2.063rem',
      color: gray[750],
    },
  }),
  SubTitle: styled(Typography)({
    '&&': {
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: '2.063rem',
      color: gray[750],
    },
  }),
};
