import { useEffect } from 'react';
import { RequestStatusTypes } from '../../services';
import { setPageTitle } from '../../utils/helpers';
import { LanguageContainer } from '../../state/languageContainer';

const PAGE_TITLE = {
  [RequestStatusTypes.VIEW]: 'page_title_view_data_form',
  [RequestStatusTypes.UPDATE]: 'page_title_update_data_form',
  [RequestStatusTypes.DELETE]: 'page_title_delete_data_form',
  [RequestStatusTypes.PREFERENCES]: 'state_tabs.preferences',
  [RequestStatusTypes.OPT_OUT]: 'page_title_dont_share_or_sell',
  [RequestStatusTypes.APPEAL]: 'page_title_appeal_data_form',
};

type useSetPageTitleOptionsType = {
  isDefault: boolean;
  enable: boolean;
  type: RequestStatusTypes;
};

export const useSetPageTitle = ({ isDefault, enable, type }: useSetPageTitleOptionsType) => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  useEffect(() => {
    if (!enable) {
      return;
    }

    if (isDefault) {
      setPageTitle(BigIdMeTranslate('page_title_privacy_center'));
    } else {
      setPageTitle(BigIdMeTranslate(PAGE_TITLE[type]));
    }
  }, [enable, isDefault, type, BigIdMeTranslate]);
};
