import makeStyles from '@mui/styles/makeStyles';
import { white } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    padding: '50px 0',
    minHeight: '100%',
  },
  title: {
    margin: '32px auto',
  },
  titleWrapper: {
    margin: '0 auto 85px auto',
    textAlign: 'center',
  },
  formHeading: {
    width: 1000,
    marginBottom: 24,
  },
  wrapper: {
    borderRadius: 12,
    backgroundColor: white,
    boxShadow: '0 2px 25px 0 rgba(0,0,0,0.1)',
    padding: '32px 45px',
    width: '100%',
    maxWidth: 1000,
  },
  formControl: {
    width: '100%',
  },
  questionsTitle: {
    paddingBottom: 20,
  },
  questionContainer: {
    display: 'flex',
    borderBottom: '1px solid #D6D6D6',
    marginTop: 24,
    paddingBottom: 22,
    '& .MuiFormGroup-root': {
      marginTop: 20,
    },
  },
  formQuestionLabel: {
    '&.MuiFormLabel-root.Mui-focused': {
      color: '#3E3E3E',
    },
  },
  submitButton: {
    width: 108,
    marginTop: 34,
  },
}));
