import React, { ReactNode } from 'react';
import { PasscodeType, RequestStatusTypes } from '../../services';
import { UserRequestStatus } from '../../constants/userRequestStatus';
import { BigidMeIconView } from '../../assets/icons/BigidMeIconView';
import { BigidMeIconUpdate } from '../../assets/icons/BigidMeIconUpdate';
import { BigidMeIconDelete } from '../../assets/icons/BigidMeIconDelete';
import { ReactComponent as BigidMeIconErrorExclamation } from '../../assets/icons/icon-alarm-red.svg';
import { BigidMeIconPreferences } from '../../assets/icons/BigidMeIconPreferences';
import { ReactComponent as BigidMeIconExpired } from '../../assets/icons/expired with bg.svg';
import { BigIdMeTranslateType, LanguageType } from '../../state/languageContainer';

export const getIcon = (
  type: RequestStatusTypes,
  status: UserRequestStatus,
  translate: BigIdMeTranslateType,
): ReactNode => {
  if (status === UserRequestStatus.NoDataFound || status === UserRequestStatus.IdentityVerificationFailed) {
    return <BigidMeIconErrorExclamation title={translate('consumer_web_error')} />;
  }
  if (status === UserRequestStatus.ReportExpired) {
    return <BigidMeIconExpired title={translate('consumer_web_expired')} />;
  }
  switch (type) {
    case RequestStatusTypes.VIEW:
      return <BigidMeIconView title={translate('consumer_web_submit_request-view_name')} />;
    case RequestStatusTypes.UPDATE:
      return <BigidMeIconUpdate title={translate('consumer_web_update')} />;
    case RequestStatusTypes.DELETE:
      return <BigidMeIconDelete title={translate('consumer_web_submit_request-delete_name')} />;
    case RequestStatusTypes.PREFERENCES:
      return <BigidMeIconPreferences title={translate('consumer_web_preferences')} />;
    default:
      return <BigidMeIconView title={translate('consumer_web_submit_request-view_name')} />;
  }
};

export const initialStateForStepper = {
  history: [
    {
      status: UserRequestStatus.Received,
      passed: false,
      current: false,
      future: true,
    },
    {
      status: UserRequestStatus.IdentityVerified,
      passed: false,
      current: false,
      future: true,
    },
    {
      status: UserRequestStatus.InformationProcessed,
      passed: false,
      current: false,
      future: true,
    },
    {
      status: UserRequestStatus.RequestCompleted,
      passed: false,
      current: false,
      future: true,
    },
  ],
  expirationDate: '',
  expirationDays: 0,
  requestId: '',
  requestName: '',
  requestType: RequestStatusTypes.VIEW,
  status: UserRequestStatus.NoDataFound,
  userLocale: LanguageType.EN,
  userLocation: '',
  userOs: '',
  passcodeType: PasscodeType.NONE,
  waitingPreferencesSubmit: false,
  hasFilesToDownload: false,
};
