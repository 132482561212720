import React, { FC, InputHTMLAttributes, ReactElement } from 'react';
import classNames from 'classnames';
import { FormControlLabel, Radio, Typography } from '@mui/material';
import { e2e, WithTest } from '../../utils';
import { ReactComponent as BigidMeIconRadioEmpty } from '../../assets/icons/Radio_off_black.svg';
import { ReactComponent as BigidMeIconRadioSelected } from '../../assets/icons/Radio_on_black.svg';

import { useStyles } from './BigidMeRadioStyles';

export type BigidMeRadioType = WithTest & {
  id?: string;
  title: string | ReactElement;
  onChange: (value: string) => void;
  value?: string | number;
  description?: string | ReactElement | JSX.Element[];
  className?: string;
  descriptionClassaName?: string;
};

export const BigidMeRadio: FC<BigidMeRadioType> = ({
  id,
  title,
  value,
  onChange,
  description,
  className,
  descriptionClassaName,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      id={id}
      value={value}
      control={
        <Radio
          disableRipple
          onChange={({ target: { value } }) => onChange(value)}
          className={classes.input}
          icon={<BigidMeIconRadioEmpty />}
          checkedIcon={<BigidMeIconRadioSelected />}
          inputProps={{ ...e2e(rest['data-aid']) } as InputHTMLAttributes<HTMLInputElement>}
          aria-labelledby={`${id}-label`}
        />
      }
      className={classNames(classes.item, className)}
      label={
        <>
          <Typography variant="subtitle1" color="textPrimary" id={`${id}-label`}>
            <b>{title}</b>
          </Typography>
          {description && (
            <Typography variant="subtitle1" color="textPrimary" className={descriptionClassaName}>
              {description}
            </Typography>
          )}
        </>
      }
    />
  );
};
