import makeStyles from '@mui/styles/makeStyles';
import { gray } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles(theme => ({
  root: {
    '& p': {
      margin: 0,
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.125rem',
    },

    [theme.breakpoints.down('sm')]: {
      marginTop: 32,
      fontSize: '1rem',
      fontWeight: 800,
    },
  },
  itemGroup: {
    margin: '20px 0 40px',

    [theme.breakpoints.down('sm')]: {
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  item: {
    marginBottom: 24,
    maxWidth: 675,
  },
  divider: {
    backgroundColor: gray[275],
    margin: '20px 0',

    [theme.breakpoints.down('sm')]: {
      margin: '9px 0 16px 0',
    },
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  optionDescription: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 6,
      lineHeight: '1.25rem',
    },
  },
  subtitle: {
    fontSize: '0.9rem',
    fontWeight: 700,
    minHeight: 25,
    '& p': {
      maxWidth: 718,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  toggleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      marginBottom: 24,
      paddingBottom: 24,
      borderBottom: `1px solid ${gray[275]}`,
    },
  },
  optoutLabelsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  optoutLabelsWrapperRight: {
    flexShrink: 0,
    width: 128,
    display: 'flex',
    lineHeight: 1.75,
  },
  optoutLabel: {
    fontSize: '0.9rem',
    fontWeight: 700,
    minWidth: 62,
    display: 'inline-block',
    textAlign: 'center',
  },
  optoutSingleLabel: {
    fontSize: '0.9rem',
    fontWeight: 700,
    minWidth: 62,
    textAlign: 'right',
  },
  optoutGroupDouble: {
    padding: '16px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  optoutGroupSingle: {
    padding: '16px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  optoutRadioLabel: {
    display: 'inline-block',
    flex: 1,
    textAlign: 'start',
  },
  optoutSingle: {
    margin: 0,
    display: 'block',
    textAlign: 'end',
  },
  optoutDouble: {
    margin: '0 21px',
  },
}));
