import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  labelRoot: {
    color: `${theme.palette.text.primary} !important`,
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.6em',
    position: 'static',
  },
  radioLabel: {
    '&:focus-within': {
      outline: '2px solid #888',
      outlineStyle: 'auto',
      outlineOffset: '1px',
    },
  },
  divider: {
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));
