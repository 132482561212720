import React, { FC } from 'react';
import { TenantContainer } from '../../state/tenantContainer';
import { Typography } from '@mui/material';
import { LanguageContainer } from '../../state/languageContainer';
import { e2e } from '../../utils';

import { useStyles } from './BigidMeRequestInfoWrapperStyles';

export const BigidMeRequestInfoFooter: FC = () => {
  const { tenant } = TenantContainer.useContainer();
  const { BigIdMeTranslate, BigIdMeTranslateWithNode } = LanguageContainer.useContainer();
  const classes = useStyles();

  return (
    <Typography variant="subtitle2" color="textPrimary" align="center" className={classes.note}>
      {BigIdMeTranslateWithNode('consumer_web_errors_any-questions', [
        {
          key: 'contact_us',
          value: (
            <a key={tenant?.supportLink} className={classes.link} href={tenant?.supportLink} {...e2e('contact-us')}>
              {BigIdMeTranslate('consumer_web_general_contact')}
            </a>
          ),
        },
      ])}
    </Typography>
  );
};
