import { styled } from '@mui/material';
import { white } from '../../assets/theme/BigidMeColors';

const Root = styled('div')({
  display: 'flex',
  backgroundColor: white,
  height: '100%',
});

const Layout = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto 70px',
  maxWidth: 612,
  padding: '10px 32px',
  height: '100%',
});

export const StyledBigidMeStatus = {
  Root,
  Layout,
};
