import makeStyles from '@mui/styles/makeStyles';
import { gray, linkText } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
  },
  icon: {
    margin: '0 auto 16px',

    [theme.breakpoints.down('sm')]: {
      marginBottom: 12,

      '& > svg': {
        width: 72,
        height: 72,
      },
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: 24,

    [theme.breakpoints.down('sm')]: {
      marginBottom: 8,
      fontSize: '1.25rem',
    },
  },
  text: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  note: {
    width: '100%',
    paddingTop: 20,
    borderTop: `1px solid ${gray[275]}`,
  },
  button: {
    margin: 20,
  },
  link: {
    cursor: 'pointer',
    color: linkText,
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    fontSize: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
