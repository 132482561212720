import { useEffect } from 'react';
import { ErrorResponseType } from '../types/ErrorResponseType';

export const useErrorMessage = (onMessage: (value: ErrorResponseType) => void) => {
  const messageListener = (e: MessageEvent) => {
    if (e.origin === location.origin && e.data.type === 'error') {
      onMessage(e.data.error);
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageListener);
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [onMessage]);
};
