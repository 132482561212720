import React, { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from 'react';
import { Paper, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BigidMeSessionController,
  BigidMeSessionEffect,
  BigidMeTextField,
  BigidMeWireframe,
  MeSubmitButton,
  SessionClosingStages,
  BigidMeChangesStatusSubmitted,
  BigidMeTooltip,
} from '../../components';
import { TenantContainer } from '../../state/tenantContainer';
import { requestService } from '../../services';
import { ErrorCodes } from '../../constants/errorCodes';
import { UserDataContainer } from '../../state/userDataContainer';
import { LanguageContainer } from '../../state/languageContainer';
import { BigidMeIconUpdate } from '../../assets/icons/BigidMeIconUpdate';

import { useStyles } from './EditDataStyles';
import { RedirectUrls } from '../../constants/routes';

enum VerificationStatuses {
  READY,
  COMPLETE,
}

export type EditDataResponseType<T> = {
  fields: T;
};

export interface EditDataFieldType {
  name: string;
}

export interface EditDataRequestFieldType extends EditDataFieldType {
  currentValue: string;
  isTouched?: boolean;
}

export interface EditDataResponseFieldType extends EditDataFieldType {
  userValue: string;
}

interface ValidationType {
  valid: boolean;
  errors: {
    [id: number]: string;
  };
}

export const EditData: FC = () => {
  const navigate = useNavigate();
  const { requestId, rc, code } = useParams();
  const userCommentName = 'userComment';
  const { tenant, loadTenantSettings } = TenantContainer.useContainer();
  const { userData, setUserData } = UserDataContainer.useContainer();
  const [mappedFields, setMappedFields] = useState<EditDataRequestFieldType[]>([]);
  const [verificationStatus, setVerificationStatus] = useState<VerificationStatuses>();
  const [validation, setValidation] = useState<ValidationType>({
    valid: false,
    errors: {},
  });
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const classes = useStyles();

  const {
    sessionClosingStage,
    setSessionClosingStage,
  }: {
    sessionClosingStage: SessionClosingStages;
    setSessionClosingStage: Dispatch<SetStateAction<SessionClosingStages>>;
  } = BigidMeSessionEffect({ tenantId: tenant.tenantId, navigate });

  useEffect(() => {
    loadTenantSettings({ requestId });
  }, [requestId, loadTenantSettings]);

  useEffect(() => {
    if (tenant.tenantId) {
      if (userData.length) {
        setVerificationStatus(VerificationStatuses.READY);
        setMappedFields([
          ...userData.map(field => ({
            ...field,
            isTouched: false,
          })),
          { currentValue: '', name: userCommentName, isTouched: false },
        ]);
      } else {
        navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}${RedirectUrls.ACTION_ERROR}`);
      }
    }

    return () => {
      setUserData([]);
    };
  }, [setUserData, userData, navigate, tenant.tenantId]);

  const handleChange = (name: string, value: string) => {
    const newFields = [...mappedFields];
    const index = newFields.findIndex(item => item.name === name);
    if (index !== -1) {
      newFields[index].currentValue = value;
    }
    setMappedFields(newFields);
  };

  const renderFields = (fields: EditDataRequestFieldType[]) =>
    fields.map(({ name, currentValue }, index) =>
      name !== userCommentName ? (
        <BigidMeTextField
          key={name}
          label={
            <BigidMeTooltip title={name}>
              <span>{name}</span>
            </BigidMeTooltip>
          }
          value={currentValue}
          onChange={(value: string) => handleChange(name, value)}
          onBlur={() => handleBlur(index)}
          error={validation.errors[index]}
          customClasses={{
            root: classes.textRoot,
            labelRoot: classes.labelRoot,
          }}
        />
      ) : (
        <Fragment key={name} />
      ),
    );

  const handleBlur = (index: number) => {
    const newFields = [...mappedFields];
    newFields[index].isTouched = true;
    setMappedFields(newFields);
    setValidation(validate());
  };

  const validate = () => {
    const errors: Record<number, string> = {};
    const valid = true;
    return { errors, valid };
  };

  const validateAndSubmit = () => {
    const requests: Promise<unknown>[] = [Promise.resolve()];

    if (mappedFields) {
      const { errors, valid } = validate();
      setValidation({ errors, valid });

      if (!valid) {
        return Promise.all(requests);
      }

      const requestFields: EditDataResponseFieldType[] = mappedFields.map(({ name, currentValue }) => ({
        name,
        userValue: currentValue,
      }));
      const userComment = requestFields.splice(
        requestFields.indexOf(requestFields.find(item => item.name === userCommentName)!),
        1,
      )[0];
      const request = requestService.editDataSubmit(requestId!, rc!, code!, requestFields, userComment.userValue);

      requests.push(request);
      request
        .then(() => setVerificationStatus(VerificationStatuses.COMPLETE))
        .catch(err => {
          switch (err.errorCode) {
            case ErrorCodes.CONSUMER_DATA_ALREADY_UPDATED: {
              navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}${RedirectUrls.CONSUMER_DATA_ALREADY_UPDATED}`);
              break;
            }
            default: {
              navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}${RedirectUrls.ACTION_ERROR}`);
            }
          }
        });
    }

    return Promise.all(requests);
  };

  return (
    <BigidMeWireframe {...tenant} hideLanguageControls pageTitle={BigIdMeTranslate('page_title_edit_data')}>
      <div className={classes.root}>
        {verificationStatus === VerificationStatuses.READY && (
          <>
            <BigidMeIconUpdate />
            <Typography variant="h1" color="textSecondary" className={classes.title}>
              {BigIdMeTranslate('consumer_web_edit_title')}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" className={classes.subTitle}>
              {BigIdMeTranslate('consumer_web_edit_description_1')}
              <br />
              {BigIdMeTranslate('consumer_web_edit_description_2')}
              <br />
              {BigIdMeTranslate('consumer_web_edit_description_3')}
            </Typography>
            <Paper className={classes.wrapper}>
              <>
                <div className={classes.fields}>{mappedFields && renderFields(mappedFields)}</div>
                <BigidMeTextField
                  label={BigIdMeTranslate('consumer_web_edit_comment')}
                  placeholder={BigIdMeTranslate('consumer_web_edit_comment-placeholder')}
                  multiline
                  value={mappedFields[mappedFields.length - 1].currentValue}
                  onBlur={() => handleBlur(mappedFields.length - 1)}
                  error={validation.errors[mappedFields.length - 1]}
                  onChange={(value: string) => handleChange(userCommentName, value)}
                  customClasses={{ inputRoot: classes.textAreaInputRoot, labelRoot: classes.labelRoot }}
                />
              </>
              <div className={classes.controls}>
                <MeSubmitButton
                  isDisabled={!validation.valid}
                  onClick={validateAndSubmit}
                  classes={classes.button}
                  text={BigIdMeTranslate('consumer_web_edit_save')}
                  type="primary"
                  bi={{ eventType: 'edit_data_submit' }}
                />
              </div>
            </Paper>
          </>
        )}
        {verificationStatus === VerificationStatuses.COMPLETE && <BigidMeChangesStatusSubmitted />}
        <BigidMeSessionController
          sessionClosingStage={sessionClosingStage}
          setSessionClosingStage={setSessionClosingStage}
        />
      </div>
    </BigidMeWireframe>
  );
};
