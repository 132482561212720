import React, { FC } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { MeButton } from '../../components';
import { BigidMeModal } from '../../components/BigidMeModal';
import { LanguageContainer } from '../../state/languageContainer';

interface ExitDialogPropsType {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
}

export const ExitDialog: FC<ExitDialogPropsType> = ({ open, onCancel, onClose }) => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  return (
    <BigidMeModal open={open} onClose={onCancel}>
      <Box px={1}>
        <Typography variant="h5" fontWeight="bold">
          {BigIdMeTranslate('consumer_web_want_to_leave')}
        </Typography>
      </Box>
      <Box py={2}>
        <Divider />
      </Box>
      <Box px={1}>
        <Typography variant="h6">{BigIdMeTranslate('consumer_web_your_changes_will_be_lost')}</Typography>
      </Box>
      <Stack direction="row" justifyContent="space-between" mt={4}>
        <MeButton type="secondary" onClick={onCancel} bi={{ eventType: 'exit_dialog_cancel' }}>
          {BigIdMeTranslate('consumer_web_cancel')}
        </MeButton>
        <MeButton type="primary" onClick={onClose} bi={{ eventType: 'exit_dialog_close' }}>
          {BigIdMeTranslate('consumer_web_yes')}
        </MeButton>
      </Stack>
    </BigidMeModal>
  );
};
