import React, { FC, useEffect } from 'react';
import { ReactComponent as BigidMeIconPinCode } from '../../assets/icons/icon-alarm-red.svg';
import { Typography } from '@mui/material';
import { MeSubmitButton } from '../../components';
import { e2e } from '../../utils';
import { LanguageContainer } from '../../state/languageContainer';
import { useStyles } from './RequestNotFoundStyles';
import { TenantContainer } from '../../state/tenantContainer';
import { setPageTitle } from '../../utils/helpers';

export interface NotFoundPropsType {
  onClickButton: () => Promise<unknown>;
}

export const RequestNotFound: FC<NotFoundPropsType> = ({ onClickButton }) => {
  const classes = useStyles();
  const { BigIdMeTranslate, BigIdMeTranslateWithNode } = LanguageContainer.useContainer();
  const { tenant } = TenantContainer.useContainer();

  useEffect(() => {
    setPageTitle(BigIdMeTranslate('page_title_request_not_found'));
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <BigidMeIconPinCode title={BigIdMeTranslate('consumer_web_error')} />
      </div>
      <Typography variant="h4" color="textPrimary" className={classes.title} {...e2e('not_found_modal_title')}>
        {BigIdMeTranslate('consumer_web_customer_rep_wrong_request_id')}
      </Typography>
      <Typography variant="h6" color="textPrimary" className={classes.text} {...e2e('not_found_modal_description')}>
        {BigIdMeTranslate('consumer_web_customer_rep_wrong_request_id_description')}
      </Typography>
      <div className={classes.button}>
        {
          <MeSubmitButton
            text={BigIdMeTranslate('consumer_web_customer_rep_try_again')}
            onClick={onClickButton}
            type="primary"
            {...e2e('try_again_button')}
            bi={{ eventType: 'request_not_found_try_again_button' }}
          />
        }
      </div>
      <div className={classes.note}>
        <Typography variant="subtitle2" color="textPrimary" align="center" gutterBottom>
          {BigIdMeTranslateWithNode('consumer_web_errors_any-questions', [
            {
              key: 'contact_us',
              value: (
                <a className={classes.link} href={tenant?.supportLink} {...e2e('contact-us')}>
                  {BigIdMeTranslate('consumer_web_general_contact')}
                </a>
              ),
            },
          ])}
        </Typography>
      </div>
    </div>
  );
};
