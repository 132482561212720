import React, { FC, useState } from 'react';
import { BigidBIEventData } from '@bigid-ui/utils';
import { MeButton, MeButtonPropsType } from '../MeButton';

export interface MeSubmitButtonType extends MeButtonPropsType {
  onClick: () => Promise<unknown>;
  bi: BigidBIEventData;
}

export const MeSubmitButton: FC<MeSubmitButtonType> = ({ onClick, isDisabled, ...rest }) => {
  const [pending, setPending] = useState(false);
  const disabled = isDisabled || pending;

  const handleClick = () => {
    if (!onClick) {
      return;
    }

    setPending(true);
    onClick()
      .then(() => setPending(false))
      .catch(() => setPending(false));
  };

  return <MeButton onClick={handleClick} isDisabled={disabled} {...rest} />;
};
