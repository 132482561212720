import makeStyles from '@mui/styles/makeStyles';
import { white } from '../../assets/theme/BigidMeColors';
import { styled } from '@mui/styles';
import StepConnector from '@mui/material/StepConnector';
import { stepConnectorClasses } from '@mui/material';

export const useStyles = makeStyles(theme => ({
  stepLabelRoot: {
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  stepHeader: {
    fontSize: '1.125rem',
    display: 'flex',
    alignItems: 'center',
    color: '#222222',
    marginLeft: 15,
  },
  extraBold: {
    fontWeight: 800,
  },
  iconPencil: {
    padding: '0 10px',
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  italic: {
    fontStyle: 'italic',
    fontWeight: 200,
    marginLeft: 10,
    marginRight: 5,
  },
  stepTwoContentRoot: {
    [theme.breakpoints.up('sm')]: {
      margin: '10px 35px',
      borderRadius: 12,
      backgroundColor: white,
      boxShadow: '0 2px 25px 0 rgba(0,0,0,0.1)',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      margin: '10px 10px',
    },
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      padding: '8px 38px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '8px 16px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 32,
    },
  },
  notification: {
    padding: '30px 0 0 0',

    [theme.breakpoints.down('sm')]: {
      margin: '0 -16px',
      paddingTop: 22,
      fontSize: '1rem',
      lineHeight: '1.375rem',
    },

    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  userDetailsTitleMobile: {
    display: 'none',
    fontSize: '1rem',
    margin: 0,
    fontWeight: 'normal',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  contactDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '15px 0',
    alignItems: 'center',
  },
  authorizedAgentContactDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '15px 0',
    alignItems: 'start',
    flexDirection: 'column',
  },
  fieldsContainer: {
    width: '100%',
    maxWidth: 700,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',

    [theme.breakpoints.down('sm')]: {
      maxWidth: 288,
      margin: '0 auto',
    },
  },
  fullWidthField: {
    flex: '0 0 100%',
  },
  inputField: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  helperText: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 8,
    marginTop: 31,

    '& > svg': {
      margin: '0 15px 0 0',
      minWidth: 20,
    },

    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      marginBottom: 0,
      marginLeft: 0,
      alignItems: 'center',

      '& > svg': {
        marginRight: 13,
        minWidth: 20,
      },
    },
  },
  submitButtonHelperText: {
    marginTop: 0,
  },
  // TODO: Move helper text inside input components (text, select) to avoid this workaround
  helperTextWithError: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 4,
    },
  },
  titleWrapper: {
    margin: 55,
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      margin: '60px 24px 24px 24px',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '20px',
      fontWeight: 600,
    },
  },
  subTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
      fontSize: '0.875rem',
      lineHeight: '20px',
      fontWeight: 600,
    },
  },
  stepperWrapper: {
    maxWidth: 1200,
    margin: 'auto',
    textAlign: 'left',
  },
  stepper: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    padding: 24,
  },
  wrapperFlex: {
    display: 'flex',
    alignItems: 'start',
    margin: '37px 37px 0 0',

    [theme.breakpoints.down('sm')]: {
      margin: '28px 0 0 0',
      alignItems: 'flex-start',
      width: '100%',
    },
  },
  requestThumbnailsWrapper: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  requestThumbnails: {
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'inline-grid',
      gridTemplateColumns: 'auto auto',
      margin: '0 auto',
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  wrapperField: {
    visibility: 'visible',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  photoUploadFieldWrapper: {
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  wrapperSubmit: {
    display: 'flex',
    margin: '40px 0',
    alignItems: 'center',

    '& > svg': {
      margin: '0 15px 0 25px',
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 288,
      margin: '40px auto 0 auto',

      '& > svg': {
        marginLeft: 0,
      },
    },
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: 288,
    },
  },
  navigationMobile: {
    display: 'none',
    zIndex: 10,
    height: 36,
    padding: '0 16px',
    justifyContent: 'space-between',
    backgroundColor: '#EDEDED',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  stepContent: {
    [theme.breakpoints.down('sm')]: {
      borderLeft: 'none',
      margin: 0,
      marginTop: 20,
      padding: '0 16px',
    },
  },
  displayOnDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  captchaWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  mobileBackButton: {
    padding: '0!important',
    border: 'none',
    backgroundColor: 'transparent!important',
    boxShadow: 'none!important',

    '[dir=rtl] & svg': {
      transform: 'rotateY(180deg)',
    },
  },
  hiddenField: {
    display: 'none',
  },
  requestorTypeSelf: {
    backgroundColor: '#F0F5FF',
    borderRadius: 20,
    fontSize: 13,
    fontWeight: 600,
    color: '#2F54EB',
    display: 'flex',
    alignItems: 'center',
    marginRight: 5,
    marginLeft: 10,
    padding: '5px 10px',

    '& svg': {
      marginRight: 5,
    },
  },
  requestorTypeAgent: {
    backgroundColor: '#FBF5FE',
    borderRadius: 20,
    fontSize: 13,
    fontWeight: 600,
    color: '#560D70',
    display: 'flex',
    alignItems: 'center',
    marginRight: 5,
    marginLeft: 10,
    padding: '5px 10px',

    '& svg': {
      marginRight: 5,
    },
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: -20,
  },
}));

export const ResponsiveStepperConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#01BFA5',
      borderLeftStyle: 'dashed',
      borderWidth: 2,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#01BFA5',
      borderLeftStyle: 'dashed',
      borderWidth: 2,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
