export enum UserRequestStatus {
  Received = 'Request received',
  IdentityVerified = 'Identity verified',
  InformationProcessed = 'Information processed',
  RequestCompleted = 'Request completed',
  DataModified = 'Data modified',
  DataModificationDeclined = 'Data modification declined',
  ReportExpired = 'Report Expired',
  NoDataFound = 'No data found',
  IdentityVerificationFailed = 'Identity could not be verified',
  DataDeleted_ = 'Data Deleted',
  DataDeleted = 'Data deleted',
  Issued = 'Issued',
  Default = 'default',
}
