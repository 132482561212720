import { DivisionsType, CountriesType, ManagePreferencesItemType } from '../services/request/types';
import { BigidSelectOption } from '../components/BigidMeSelect';

export const normalizeCountriesResponceToOptions = (countries: CountriesType[] = []) =>
  countries
    .map(({ name, divisions, displayName }: CountriesType) => ({
      value: name,
      label: displayName,
      divisions: divisions
        .map((division: DivisionsType) => ({
          value: division.code,
          label: division.displayName,
        }))
        .sort((div1, div2) => div1.label.localeCompare(div2.label)),
    }))
    .sort((country1, country2) => country1.label.localeCompare(country2.label));

export const normalizeManagePreferencesReasons = (items: ManagePreferencesItemType[]): BigidSelectOption[] =>
  items.map(({ id, label }) => ({ value: id, label }));
