import React from 'react';
import { useTheme } from '@mui/material';
import { BigidPrivacyUserIllustration } from '@bigid-ui/icons';

export const BigidMeIconRequestorTypeSelf = () => {
  const theme = useTheme();

  return (
    <BigidPrivacyUserIllustration
      fillColor={theme.palette.bigidMe.illustrationColor}
      titleId="selfTitle"
      title="Self"
    />
  );
};
