import React, { FC, ReactNode } from 'react';
import { Modal } from '@mui/material';
import { useStyles } from './BigidMeModalStyles';
import { ReactComponent as BigidMeIconClose } from '../../assets/icons/baseline-clear-24px.svg';
import { e2e } from '../../utils';
import { LanguageContainer } from '../../state/languageContainer';
import classNames from 'classnames';

interface BigidMeModalType {
  open: boolean;
  onClose?: () => void;
  children?: ReactNode;
  wrapperClassName?: string;
}

export const BigidMeModal: FC<BigidMeModalType> = ({ open, onClose, children, wrapperClassName }) => {
  const classes = useStyles();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  return (
    <Modal open={open} onClose={onClose} className={classes.root} {...e2e('bigidme_modal')}>
      <div className={classNames(wrapperClassName, classes.wrapper)}>
        {onClose && (
          <div className={classes.close} onClick={onClose} {...e2e('bigidme_modal_close')}>
            <BigidMeIconClose title={BigIdMeTranslate('consumer_web_clear')} />
          </div>
        )}
        {children}
      </div>
    </Modal>
  );
};
