import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createContainer } from 'unstated-next';
import { BigidBIAnalyticsService, BigidBIAnalyticsServiceOptions } from '@bigid-ui/utils';
import { TenantContainer } from './tenantContainer';
import { isSmallIdApp } from '../utils/commonUtils';
import { getPageNameByPathName } from '../utils';

export const CONSUMER_APP_MAIN_MODULE = 'PRIVACYPORTAL_CONSUMER_APP';

class ConsumerAnalyticsService extends BigidBIAnalyticsService {
  setOptions(options: BigidBIAnalyticsServiceOptions) {
    this.options = options;
  }
}

const userGeneralProperties = [
  {
    property: 'screen_width',
    value: screen?.width,
  },
  {
    property: 'screen_height',
    value: screen?.height,
  },
  {
    property: 'screen_avail_height',
    value: screen?.availHeight,
  },
  {
    property: 'screen_avail_width',
    value: screen?.availWidth,
  },
  {
    property: 'screen_color_depth',
    value: screen?.colorDepth,
  },
  {
    property: 'screen_pixel_depth',
    value: screen?.pixelDepth,
  },
  {
    property: 'screen_orientation_type',
    value: screen?.orientation?.type,
  },
  {
    property: 'screen_orientation_angle',
    value: screen?.orientation?.angle,
  },
  {
    property: 'window_inner_width',
    value: window?.innerWidth,
  },
  {
    property: 'window_inner_height',
    value: window?.innerHeight,
  },
];

export const analyticsService = new ConsumerAnalyticsService({
  eventFixedData: [],
  moduleName: '',
  generateAutomaticEvents: false,
});
let pathname: string;

// do not use this hook inside components which we use in privacy-portal-app
// there is conflicts with useLocation hook
// import analyticsService directly from this module
const useAnalytics = () => {
  const location = useLocation();
  const { tenant } = TenantContainer.useContainer();

  pathname = location.pathname;
  const companyName = tenant.tenantName;

  useEffect(() => {
    const initAnalyticsService = () => {
      if (tenant.analyticsConfig.enabled) {
        analyticsService.setOptions({
          userProperties: () => [
            { property: 'tenant', value: tenant.tenantId },
            { property: 'company_name', value: companyName },
            { property: 'environment', value: tenant.analyticsConfig.environment },
            ...userGeneralProperties,
          ],
          eventFixedData: () => ({
            product_type: isSmallIdApp() ? 'SmallID' : 'Privacy Portal',
            pathname,
          }),
          moduleName: CONSUMER_APP_MAIN_MODULE,
          generateAutomaticEvents: false,
        });
        analyticsService.setUserGroup('Accounts', companyName);
        analyticsService.init({
          apiKey: tenant.analyticsConfig.apiKey,
          serverUrl: tenant.analyticsConfig.serverUrl,
          // need to pass undefined because on consumer there is no login
          userName: undefined as any,
        });

        return () => {
          analyticsService.unregister();
        };
      }
    };

    return initAnalyticsService();
  }, [
    companyName,
    tenant.analyticsConfig.apiKey,
    tenant.analyticsConfig.enabled,
    tenant.analyticsConfig.environment,
    tenant.analyticsConfig.serverUrl,
    tenant.tenantId,
  ]);

  useEffect(() => {
    const trackPageView = () => {
      const eventType = `${CONSUMER_APP_MAIN_MODULE}_${getPageNameByPathName(location.pathname, tenant.tenantId)}`;
      analyticsService.trackPageView(eventType, {
        pathname: location.pathname,
        pageTitle: document.title,
      });
    };

    trackPageView();
  }, [location.pathname]);

  return {};
};

export const AnalyticsContainer = createContainer(useAnalytics);
