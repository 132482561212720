import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TenantContainer } from '../../state/tenantContainer';
import { LanguageContainer } from '../../state/languageContainer';
import { MeButton } from '../../components/MeButton';

import { useSessionClosedErrorStyles } from './ErrorsStyles';
import { BigidMeRequestInfoWrapper } from '../../components/BigidMeRequestInfoWrapper';

export const SessionClosedError: FC = () => {
  const { so, publishName } = useParams<'so' | 'publishName'>();
  const { tenant, loadTenantSettings } = TenantContainer.useContainer();
  const { BigIdMeTranslate, language } = LanguageContainer.useContainer();
  const classes = useSessionClosedErrorStyles();
  const navigate = useNavigate();

  useEffect(() => {
    loadTenantSettings({ publishName, language });
  }, [language, loadTenantSettings, publishName]);

  const startOver = Boolean(parseInt(so!));

  return (
    <BigidMeRequestInfoWrapper
      componentNaming="SessionClosedError"
      pageTitle={BigIdMeTranslate('page_title_session_closed')}
    >
      <>
        {startOver
          ? BigIdMeTranslate('consumer_web_errors_to-protect')
          : BigIdMeTranslate('consumer_web_errors_to-view')}
        <br />
        <br />
        {startOver && (
          <div className={classes.buttonWrapper}>
            <MeButton
              onClick={() => navigate(`/${tenant.tenantId}/${publishName || ''}`)}
              text={BigIdMeTranslate('consumer_web_general_start-over')}
              type="primary"
              bi={{ eventType: 'session_closed_error_start_over' }}
            />
          </div>
        )}
      </>
    </BigidMeRequestInfoWrapper>
  );
};
