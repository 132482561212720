import React, { FC } from 'react';
import { useTheme } from '@mui/material';
import { BigidPrivacyViewIllustration } from '@bigid-ui/icons';
import { LanguageContainer } from '../../state/languageContainer';

export const BigidMeIconView: FC<{ title?: string }> = ({ title }) => {
  const theme = useTheme();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  return (
    <BigidPrivacyViewIllustration
      fillColor={theme.palette.bigidMe.illustrationColor}
      titleId="viewTitle"
      title={title || BigIdMeTranslate('consumer_web_submit_request-view_name') || 'View'}
    />
  );
};
