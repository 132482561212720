import { RequestStatus } from '../../services';

export const getActiveStepIndex = (requestStatus: RequestStatus): number => {
  const activeItem = requestStatus.history.find(s => requestStatus.status === s.status)!;
  return requestStatus.history.indexOf(activeItem);
};

export const formatExpirationDate = (expirationDate = '') => {
  const date = new Date(expirationDate);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
};
