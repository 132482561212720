import { QuestionsResponse, AnswersType, LexisNexisQuestionsResponse, LexisNexisAnswersRequestType } from './types';
import { api } from '../apiService';

const sendRequestForInitialQuestions = (requestId: string, rc: string): Promise<QuestionsResponse> => {
  return api
    .post(`/verification-questionnaire/experian/${requestId}/initial-verification?rc=${rc}`)
    .then(res => res.data);
};

const submitAnswers = (requestId: string, answers: AnswersType): Promise<QuestionsResponse> => {
  // Todo: delete questionResponseCounter when it's removed from the server
  return api
    .post(`/verification-questionnaire/experian/${requestId}/answers`, { ...answers, questionResponseCounter: '1' })
    .then(res => res.data);
};

const sendRequestForLexisNexisInitialQuestions = (
  requestId: string,
  rc: string,
): Promise<LexisNexisQuestionsResponse> => {
  return api
    .post(`/verification-questionnaire/lexis-nexis/${requestId}/initial-verification?rc=${rc}`)
    .then(res => res.data);
};

const submitLexisNexisAnswers = (
  requestId: string,
  formData: LexisNexisAnswersRequestType,
): Promise<LexisNexisQuestionsResponse> => {
  return api.post(`/verification-questionnaire/lexis-nexis/${requestId}/answers`, formData).then(res => res.data);
};

export const verificationQuestionnaireService = {
  sendRequestForInitialQuestions,
  submitAnswers,
  submitLexisNexisAnswers,
  sendRequestForLexisNexisInitialQuestions,
};
