import makeStyles from '@mui/styles/makeStyles';
import { white, gray } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',

    '&::before': {
      [theme.breakpoints.up('sm')]: {
        content: '""',
        background: 'linear-gradient(360deg, rgba(255,255,255,0) 30%, #FFFFFF 100%)',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: 50,
      },
    },

    [theme.breakpoints.up('sm')]: {
      borderRadius: 12,
      backgroundColor: white,
      boxShadow: '0 2px 25px 0 rgba(0,0,0,0.1)',
      paddingRight: 3,
      overflow: 'hidden',
    },

    [theme.breakpoints.down('sm')]: {
      width: 288,
      marginTop: 0,
    },
  },
  outer: {
    padding: '0 45px 104px 48px',

    [theme.breakpoints.down('md')]: {
      padding: '0 24px 50px 24px',
    },

    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  inner: {
    marginTop: 35,

    [theme.breakpoints.down('sm')]: {
      marginTop: 32,
    },
  },
  stepTwo: {
    minHeight: 300,
    marginBottom: 30,
    overflow: 'unset',
  },
  controls: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.up('sm')]: {
      height: 80,
      padding: '0 40px',
      borderTop: `1px solid ${gray[275]}`,
      backgroundColor: white,
    },
  },
  controlsButton: {
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  clear: {
    marginRight: 20,
    fontWeight: 700,
    color: `${gray[650]} !important`,
    fontSize: '0.8125rem',
    minWidth: 20,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.15) !important',
    },
  },
  buttonBack: {
    padding: 5,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 36,
      marginBottom: 16,
    },
  },
}));
