import React from 'react';
import { useTheme } from '@mui/material';
import { BigidPrivacyAuthorizedAgentIllustration } from '@bigid-ui/icons';

export const BigidMeIconRequestorTypeAuthorizedAgent = () => {
  const theme = useTheme();

  return (
    <BigidPrivacyAuthorizedAgentIllustration
      fillColor={theme.palette.bigidMe.illustrationColor}
      titleId="authorizedAgentTitle"
      title="Authorized Agent"
    />
  );
};
