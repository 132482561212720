import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { TenantContainer } from '../../state/tenantContainer';
import { LanguageContainer } from '../../state/languageContainer';
import { BigidMeWireframe } from '../../components/BigidMeWireframe';
import { BigidMeRequestStatus, BigidMeStepper } from '../../components';
import { RequestStatus, RequestStatusTypes, csrService, PasscodeType } from '../../services';

import {
  BigidMeSessionController,
  BigidMeSessionEffect,
  SessionClosingStages,
} from '../../components/BigidMeSessionController';
import { UserRequestStatus } from '../../constants/userRequestStatus';
import { e2e } from '../../utils';
import { useResponsive } from '../../hooks/useResponsive';

import { useStyles } from './CustomerSupportRepStyles';
import { BigidMeRequestStatusCsr } from '../../components/BigidMeRequestStatusById/BigidMeRequestStatusCsr';
import { BigidMeModal } from '../../components/BigidMeModal';
import { RequestNotFound } from '../NotFound/RequestNotFound';
import { getIcon, initialStateForStepper } from './utils';
import { formatExpirationDate, getActiveStepIndex } from '../RequestProcessingStatus/utils';
import { useNavigate, useParams } from 'react-router-dom';

export const CustomerSupportRep: FC = () => {
  const { requestId, apiKey } = useParams();
  const { BigIdMeTranslate, setLanguage, language } = LanguageContainer.useContainer();
  const navigate = useNavigate();
  const { tenant, loadTenantSettings } = TenantContainer.useContainer();
  useEffect(() => {
    loadTenantSettings({ requestId, language, navigate });
  }, [language, loadTenantSettings, navigate, requestId]);

  useEffect(() => {
    if (tenant.tenantId) {
      csrService.validateCsrApiKey(apiKey!).catch(() => {
        setIsInvalidApiKey(true);
      });
    }
  }, [apiKey, tenant.tenantId]);

  const isMobile = useResponsive();

  const [isRequestFailed, setIsRequestFailed] = useState(false);
  const [requestObject, setRequestObject] = useState<RequestStatus>(initialStateForStepper);
  const [isInvalidApiKey, setIsInvalidApiKey] = useState(false);

  const classes = useStyles();

  const {
    sessionClosingStage,
    setSessionClosingStage,
  }: {
    sessionClosingStage: SessionClosingStages;
    setSessionClosingStage: Dispatch<SetStateAction<SessionClosingStages>>;
  } = BigidMeSessionEffect({ tenantId: tenant.tenantId, navigate });

  const setDataToRequestObject = (data: RequestStatus) => {
    setRequestObject(data);
    setLanguage(data.userLocale);
  };

  const onErrorAction = (isError: boolean) => {
    setIsRequestFailed(isError);
  };

  const closeModalPromise = () =>
    new Promise<unknown>(() => {
      setIsRequestFailed(false);
    });

  if (isInvalidApiKey) {
    navigate('/404');
  }
  return (
    <BigidMeWireframe {...tenant} hideLanguageControls pageTitle={BigIdMeTranslate('page_title_customer_support')}>
      {requestObject && (
        <div className={classes.root}>
          <div className={classes.status}>
            {!requestObject.requestId && (
              <BigidMeRequestStatusCsr
                state={requestObject.status}
                type={requestObject?.requestType}
                icon={getIcon(requestObject?.requestType, requestObject.status, BigIdMeTranslate)}
                requestId={requestId!}
                expirationDays={requestObject.expirationDays}
                expirationDate={formatExpirationDate(requestObject.expirationDate)}
                submitNewRequestLink={`/${tenant.tenantId}`}
                contactLink={tenant?.supportLink}
                setDataToRequestObject={setDataToRequestObject}
                onErrorAction={onErrorAction}
                apiKey={apiKey!}
                tenantId={tenant.tenantId}
              />
            )}
            {requestObject.requestId && (
              <BigidMeRequestStatus
                state={requestObject.status}
                type={requestObject.requestType}
                icon={getIcon(requestObject.requestType, requestObject.status, BigIdMeTranslate)}
                rc={''}
                requestId={requestObject.requestId}
                expirationDays={requestObject.expirationDays}
                expirationDate={formatExpirationDate(requestObject.expirationDate)}
                submitNewRequestLink={`/${tenant.tenantId}`}
                contactLink={tenant?.supportLink}
                isConfidential
                passcodeType={PasscodeType.NONE}
                hasFilesToDownload={requestObject.hasFilesToDownload}
              />
            )}
          </div>
          {requestObject.status !== UserRequestStatus.Issued &&
            requestObject.requestType !== RequestStatusTypes.PREFERENCES &&
            requestObject.requestType !== RequestStatusTypes.OPT_OUT && (
              <div className={classes.progress}>
                <div className={classes.text}>
                  <h4 className={classes.extraBold} {...e2e('request_progress')}>
                    {BigIdMeTranslate('consumer_web_status_progress')}
                  </h4>
                </div>
                <BigidMeModal open={isRequestFailed} onClose={() => setIsRequestFailed(false)}>
                  {isRequestFailed && <RequestNotFound onClickButton={closeModalPromise} />}
                </BigidMeModal>
                <BigidMeStepper
                  isMobile={isMobile}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  activeStepIndex={getActiveStepIndex(requestObject)}
                  requestStatus={requestObject}
                  hasError={
                    requestObject.status === UserRequestStatus.NoDataFound ||
                    requestObject.status === UserRequestStatus.IdentityVerificationFailed ||
                    requestObject.status === UserRequestStatus.DataModificationDeclined
                  }
                  disabled={requestObject.status === UserRequestStatus.ReportExpired}
                />
              </div>
            )}
        </div>
      )}
      <BigidMeSessionController
        sessionClosingStage={sessionClosingStage}
        setSessionClosingStage={setSessionClosingStage}
      />
    </BigidMeWireframe>
  );
};
