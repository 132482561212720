import makeStyles from '@mui/styles/makeStyles';
import { white, gray } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles(theme => ({
  root: {},
  wrapper: {
    maxWidth: 676,
    borderRadius: 12,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: white,
    padding: '24px 56px 32px',
    boxShadow: '0 6px 23px 0 rgba(0,0,0,0.23)',
    outline: 'none',

    ['@media (maxWidth: 768px)']: {
      left: 50,
      right: 50,
      transform: 'translate(0, -50%)',
    },

    [theme.breakpoints.down('sm')]: {
      left: 16,
      right: 16,
      transform: 'translate(0, -50%)',
      padding: '28px 24px',
    },
  },
  close: {
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer',

    '& > svg': {
      width: 15,
      height: 15,
      fill: gray[700],

      [theme.breakpoints.down('sm')]: {
        width: 28,
        height: 28,
      },
    },
  },
}));
