import React, { FC, Fragment, useMemo } from 'react';
import { Divider, Typography } from '@mui/material';
import cx from 'classnames';
import { NotificationPanel } from '../../components/NotificationPanel';
import {
  AuthorizedAgentFieldNameType,
  AuthorizedAgentFieldType,
  AuthorizedAgentType,
  CountriesOptionsType,
  FieldValidation,
  GlobalTexts,
  UserFilesType,
  UserPhoneType,
} from '../../services';
import { ReactComponent as BigidMeIconShield } from '../../assets/icons/shield.svg';
import { ReactComponent as BigidMeIconLock } from '../../assets/icons/lock.svg';
import { LanguageContainer } from '../../state/languageContainer';

import { useStyles } from './SubmitRequestStyles';
import { e2e } from '../../utils';
import { FieldValuesType, SetTouchedFieldsCallback } from '../../utils/submitFields';
import { useAuthorizedAgent } from './hooks/useAutorizedAgent';
import { getAuthorizedAgentFieldType, groupAuthorizedAgentFields } from '../../utils/helpers';
import { TenantContainer } from '../../state/tenantContainer';
import { parseHTML } from '../../utils';

type AuthorizedAgentPropsType = {
  validation?: FieldValidation;
  setValidation: (validation: FieldValidation) => void;
  fieldValues: FieldValuesType;
  setFieldValues: (fieldValues: FieldValuesType) => void;
  countriesOptions: CountriesOptionsType[];
  touchedFields: Record<string, boolean>;
  setTouchedFields: (callback: SetTouchedFieldsCallback) => void;
  resetSelect?: boolean;
  authorizedAgent: AuthorizedAgentType;
  authorizedAgentFiles: UserFilesType[];
  setAuthorizedAgentFiles: React.Dispatch<React.SetStateAction<UserFilesType[]>>;
};

export const AuthorizedAgent: FC<AuthorizedAgentPropsType> = ({
  authorizedAgent,
  validation,
  setValidation,
  fieldValues,
  setFieldValues,
  countriesOptions,
  touchedFields,
  setTouchedFields,
  setAuthorizedAgentFiles,
  authorizedAgentFiles,
}) => {
  const classes = useStyles();
  const { fields, texts, layout, translations } = authorizedAgent || {};
  const tenant = TenantContainer.useContainer().tenant;
  const { BigIdMeTranslate, language } = LanguageContainer.useContainer();

  const { validateFields, fieldTypes } = useAuthorizedAgent({
    fields: fields || [],
    fieldValues,
    countriesOptions,
    layout,
  });

  const handleFieldTouched = (name: string) => {
    setTouchedFields((prev: Record<string, boolean>) => ({ ...prev, [name]: true }));
  };

  const handleFieldChange = (id: string, value: string | UserFilesType | UserPhoneType): void => {
    let values = { ...fieldValues, [id]: value };
    if (id === AuthorizedAgentFieldNameType.COUNTRY) {
      values = { ...fieldValues, [id]: value, [AuthorizedAgentFieldNameType.STATE]: '' };
    } else if (id === AuthorizedAgentFieldNameType.UPLOAD_DOCUMENTS) {
      if (!value) {
        setAuthorizedAgentFiles([]);
      } else {
        setAuthorizedAgentFiles([...authorizedAgentFiles, value as UserFilesType]);
      }
    }
    setFieldValues(values);
    setValidation(validateFields(values));
  };

  const renderFields = (fields: AuthorizedAgentFieldType[]) =>
    fields
      ?.sort((a, b) => a.ordinal - b.ordinal)
      .map(field => {
        const translation = field.translations[language];
        const fieldName = getAuthorizedAgentFieldType(field, fieldTypes);
        const InputComponent = fieldTypes[fieldName].component;
        const label =
          translation?.title ||
          BigIdMeTranslate(`consumer_web_submit_field-${field.id.toString().toLowerCase()}_label`);

        const description = translation?.description;
        const placeholder = translation?.hint || '';
        const helper = translation?.tooltip;
        const error =
          touchedFields[field.name] && validation?.messages[field.name] ? validation.messages[field.name] : '';
        const options = fieldTypes[fieldName].optionsSource({ field }) || [];
        const onChange = (e: string | UserFilesType | UserPhoneType) => {
          handleFieldChange(field.name, e);
          handleFieldTouched(field.name);
        };

        const fieldStyles = cx(classes.wrapperFlex, classes.inputField, {
          [classes.hiddenField]: !fieldTypes[fieldName].isVisible({ fieldValues, field }),
          [classes.fullWidthField]: fieldTypes[fieldName].isFullWidth,
        });

        const withSmsValidation =
          tenant.smsValidationOn && field.name === AuthorizedAgentFieldNameType.MOBILE_PHONE_NUMBER;

        const defaultValue =
          field.name === AuthorizedAgentFieldNameType.STATE
            ? options.find(
                item =>
                  item?.label === field.translations[language]?.defaultValue ||
                  item?.value === field.translations[language]?.defaultValue,
              )?.value
            : field.translations[language]?.defaultValue;

        return (
          <Fragment key={field.name}>
            {fieldName === AuthorizedAgentFieldNameType.UPLOAD_DOCUMENTS && <div style={{ flexBasis: '100%' }}></div>}
            <div
              className={cx(fieldStyles, {
                [classes.hiddenField]: !fieldTypes[fieldName].isVisible({ field, fieldValues }),
              })}
              id={`form-${field.name}`}
            >
              <div
                className={cx(classes.wrapperField, {
                  [classes.photoUploadFieldWrapper]: fieldName === AuthorizedAgentFieldNameType.UPLOAD_DOCUMENTS,
                })}
              >
                <InputComponent
                  id={field.name}
                  label={label}
                  description={description}
                  required={fieldTypes[fieldName].isRequired({ field, fieldValues })}
                  placeholder={placeholder}
                  error={error}
                  onChange={onChange}
                  options={options}
                  isSearchable={true}
                  radioGroupOptions={options}
                  defaultValue={defaultValue}
                  defaultRadioValue={field.options?.[0]?.name}
                  key={field.name}
                  tooltip={translation?.tooltip}
                  withSmsValidation={withSmsValidation}
                  {...e2e(`field_${field.name}`)}
                />
              </div>
              {helper &&
                fieldName !== AuthorizedAgentFieldNameType.UPLOAD_DOCUMENTS &&
                fieldName !== AuthorizedAgentFieldNameType.MOBILE_PHONE_NUMBER && (
                  <div
                    className={cx(classes.helperText, { [classes.helperTextWithError]: !!error })}
                    data-aid={`field_${field.name}_tooltip`}
                  >
                    <>
                      <BigidMeIconShield />
                      <Typography variant="h6">{helper}</Typography>
                    </>
                  </div>
                )}
            </div>
          </Fragment>
        );
      });

  const topDisclaimer = texts?.find(text => text.name === GlobalTexts.DATA_STORING_TOP_DISCLAIMER);

  const bottomDisclaimer = texts?.find(text => text.name === GlobalTexts.DATA_STORING_BOTTOM_DISCLAIMER);

  const fieldsGroups = useMemo(() => groupAuthorizedAgentFields(fields, fieldTypes), [fields, fieldTypes]);

  return (
    <div className={classes.container}>
      <div className={classes.displayOnDesktop}>
        <div className={classes.authorizedAgentContactDetails}>
          <Typography variant="h4" color="textPrimary">
            <b>{translations?.[language]?.title}</b>
          </Typography>
          <Typography variant="h5" color="textPrimary">
            {translations?.[language]?.description}
          </Typography>
        </div>
        <Divider />
      </div>
      {topDisclaimer?.enabled && (
        <div className={classes.notification}>
          <NotificationPanel
            text={
              topDisclaimer.translations?.[language]?.value && parseHTML(topDisclaimer.translations?.[language]?.value)
            }
            Icon={topDisclaimer?.useIcon ? BigidMeIconLock : undefined}
            transparentBackground={!topDisclaimer?.useBlueBackground}
            testId="authAgentTopDisclaimer"
          />
        </div>
      )}
      {fieldsGroups.map((fieldGroup, index) => (
        <div key={index} className={classes.fieldsContainer}>
          {renderFields(fieldGroup)}
        </div>
      ))}
      {bottomDisclaimer?.enabled && (
        <div className={classes.notification}>
          <NotificationPanel
            text={parseHTML(bottomDisclaimer.translations[language]?.value || '')}
            Icon={bottomDisclaimer?.useIcon ? BigidMeIconLock : undefined}
            transparentBackground={!bottomDisclaimer?.useBlueBackground}
            testId="authAgentBottomDisclaimer"
          />
        </div>
      )}
    </div>
  );
};
