import React from 'react';
import { useStyles } from './BigidMeProgressBarInfiniteStyles';

interface BigidMeProgressBarInfiniteType {
  width: number | string;
}

export const BigidMeProgressBarInfinite = ({ width }: BigidMeProgressBarInfiniteType) => {
  const classes = useStyles({ width });

  return <span className={classes.root}></span>;
};
