import React from 'react';
import { Typography } from '@mui/material';

import { TenantContainer } from '../../state/tenantContainer';
import { LanguageContainer } from '../../state/languageContainer';
import { useStyles } from './MePageHeaderStyles';
import { parseHTML } from '../../utils';

export const MePageHeader = () => {
  const { tenant } = TenantContainer.useContainer();
  const { language } = LanguageContainer.useContainer();
  const classes = useStyles();

  return (
    <div className={classes.titleWrapper}>
      <Typography variant="h2" className={classes.title}>
        {tenant.translations[language]?.pageTitle}
      </Typography>
      <Typography variant="h3" className={classes.subTitle}>
        {tenant.translations[language]?.pageSubtitle}
      </Typography>
      {tenant.translations[language]?.description && (
        <div className={classes.description}>{parseHTML(tenant.translations[language]?.description || '', true)}</div>
      )}
    </div>
  );
};
