import React, { useState } from 'react';
import { Root, classes } from './BigidMeSendMessageStyles';
import { BigidMeTextField } from '../BigidMeTextField';
import { ReactComponent as BigidMeIconClip } from '../../assets/icons/clip.svg';
import { FileUploaderLink } from '../FileUploaderLink';
import { requestService } from '../../services';
import { filter } from 'lodash';
import { MeButton } from '../MeButton';
import { useParams } from 'react-router-dom';
import { useResponsive } from '../../hooks/useResponsive';
import { Typography } from '@mui/material';
import { LanguageContainer } from '../../state/languageContainer';

export interface BigidMeMessagePropsType {
  subject: string;
  title: string;
  testId?: string;
  handleMessageSent: () => void;
}

export const BigidMeSendMessage = ({ subject, title, testId, handleMessageSent }: BigidMeMessagePropsType) => {
  const { requestId, messagesSecret } = useParams();
  const isMobile = useResponsive();
  const [messageFiles, setMessageFiles] = useState<{ id: string; fileName: string }[]>([]);
  const [content, setContent] = useState('');
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  const onFileUpload = async (fileName: string, file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    const uploadId = await requestService.uploadVerificationPhoto(formData);
    setMessageFiles(prev => [...prev, { id: uploadId, fileName }]);
  };

  const onRemoveFile = (fileId: string) => {
    const messageFilesNew = filter(messageFiles, ({ id }) => id !== fileId);
    setMessageFiles(messageFilesNew);
  };

  const onSubmit = async () => {
    if (requestId && messagesSecret) {
      await requestService.sendMessage(
        requestId,
        {
          title: title,
          subject: subject,
          content,
          files: messageFiles.map(f => ({ fileUploadId: f.id })),
        },
        messagesSecret,
      );
      handleMessageSent();
    }
  };

  return (
    <Root>
      <div className={classes.wrapper} data-aid={testId}>
        <div className={classes.sendMessageContainer}>
          <BigidMeTextField
            multiline
            rows={4}
            customClasses={{ inputRoot: classes.inputRoot }}
            value={content}
            onChange={setContent}
            data-aid="message-field"
            placeholder={BigIdMeTranslate('consumer_web_messages_add_your_reply_here')}
          />
          <div className={classes.filesContainer}>
            {messageFiles.map((file, i) => (
              <div key={i + file.id} className={classes.fileContainer} onClick={() => onRemoveFile(file.id)}>
                <BigidMeIconClip className={classes.icon} title={BigIdMeTranslate('consumer_web_clip')} />
                <span className={classes.messageInfo}>{file.fileName}</span>
              </div>
            ))}
          </div>
          {isMobile && (
            <Typography className={classes.filesInfo} variant="body2" gutterBottom>
              {BigIdMeTranslate('consumer_web_messages_files_supported')}
            </Typography>
          )}
          <div className={classes.uploaderWrapper}>
            <FileUploaderLink
              maxSize={10}
              isDisabled={false}
              onSelect={onFileUpload}
              format=".pdf, .jpg, .png, .jpeg"
              tooltip={BigIdMeTranslate('consumer_web_messages_files_supported')}
              testId="message-file-uploader"
            />
            <MeButton type="primary" onClick={onSubmit} data-aid="submit" bi={{ eventType: 'send_message_submit' }}>
              {BigIdMeTranslate('consumer_web_messages_submit')}
            </MeButton>
          </div>
        </div>
      </div>
    </Root>
  );
};
