import React from 'react';
import { Route, RouterProvider, createHashRouter, createRoutesFromElements, Outlet } from 'react-router-dom';
import { TenantContainer } from './state/tenantContainer';
import { UserDataContainer } from './state/userDataContainer';
import { LanguageContainer } from './state/languageContainer';
import { AnalyticsContainer } from './state/analyticsContainer';
import { DuplicateRequestError } from './screens/Errors/DuplicateRequestError';
import { ErrorAction } from './screens/Errors/ErrorAction';
import { RequestConfirmation } from './screens/RequestConfirmation/RequestConfirmation';
import { EditData } from './screens/EditData/EditData';
import { RequestProcessingStatus } from './screens/RequestProcessingStatus/RequestProcessingStatus';
import { RedirectUrls } from './constants/routes';
import { AlreadyConfirmedRequestError } from './screens/Errors/AlreadyConfirmedRequestError';
import { ExpiredLinkError } from './screens/Errors/ExpiredLinkError';
import { SessionClosedError } from './screens/Errors/SessionClosedError';
import { ConsumerDataAlreadyUpdated } from './screens/Errors/ConsumerDataAlreadyUpdated';
import { ErrorGeneric } from './screens/Errors/ErrorGeneric';
import { NotFound } from './screens/Errors/NotFound';
import { ManagePreferences } from './screens/ManagePreferences/ManagePreferences';
import { CustomerSupportRep } from './screens/RequestProcessingStatusRep/CustomerSupportRep';
import { ExperianVerificationForm } from './screens/ExperianVerificationForm/ExperianVerificationForm';
import { SubmitRequestWithToken } from './screens/SubmitRequest/SubmitRequestWithToken';
import { RequestMessages } from './screens/RequestMessages/RequestMessages';
import { RequestMessageError } from './screens/Errors/RequestMessageError';
import { BigidMeFavicon } from './components/BigidMeFavicon';
import { RequestError } from './screens/Errors/RequestError';
import { BigidMeAppTheme } from './components/BigidMeAppTheme/BigidMeAppTheme';
import CssBaseline from '@mui/material/CssBaseline';
import { LexisNexisVerificationScreen } from './screens/LexisNexisVerificationScreen/LexisNexisVerificationScreen';
import { VerificationStatus } from './screens/VerificationStatus/VerificationStatus';
import { MonitoringContainer } from './state/monitoringContainer';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { e2e } from './utils';
import { VerifyEmail } from './screens/VendorRequest/VerifyEmail/VerifyEmail';
import { VendorRequestResolution } from './screens/VendorRequest/VendorRequestResolution/VendorRequestResolution';
import useRecaptchaVisibility from './hooks/useRecaptchaVisibility';

const DEV_TEST_CAPTCHA_SITEKEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'; // common key provided by google for all developers to enter dev mode
const siteKey = process.env.REACT_APP_CAPTCHA_SITEKEY || DEV_TEST_CAPTCHA_SITEKEY;

const AppContent: React.FC = () => {
  useRecaptchaVisibility();

  return (
    <>
      <CssBaseline />
      <BigidMeFavicon />
      <Outlet />
    </>
  );
};

export function App() {
  return (
    <GoogleReCaptchaProvider language="en" reCaptchaKey={siteKey || ''} {...e2e(`captcha`)}>
      <RouterProvider
        router={createHashRouter(
          createRoutesFromElements(
            <>
              <Route path="/error" element={<ErrorGeneric />} />
              <Route
                element={
                  <MonitoringContainer.Provider>
                    <LanguageContainer.Provider>
                      <TenantContainer.Provider>
                        <BigidMeAppTheme>
                          <UserDataContainer.Provider>
                            <AnalyticsContainer.Provider>
                              <AppContent />
                            </AnalyticsContainer.Provider>
                          </UserDataContainer.Provider>
                        </BigidMeAppTheme>
                      </TenantContainer.Provider>
                    </LanguageContainer.Provider>
                  </MonitoringContainer.Provider>
                }
              >
                <Route path="*" element={<NotFound />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="/:tenantId/csr/:apiKey" element={<CustomerSupportRep />} />
                <Route path="/:tenantId" element={<SubmitRequestWithToken />} />
                <Route path="/:tenantId/:publishName" element={<SubmitRequestWithToken />} />
                <Route path="/:tenantId/:requestId/messages/:messagesSecret/:loc" element={<RequestMessages />} />
                <Route
                  path="/:tenantId/:requestId/confirmation/:confirmationId/:rc/:loc"
                  element={<RequestConfirmation />}
                />
                <Route
                  path="/:tenantId/vendor-request/:vendorRequestId/:secret"
                  element={<VendorRequestResolution />}
                />
                <Route
                  path="/:tenantId/vendor-request/:vendorRequestId/verify-email/:masterSecret"
                  element={<VerifyEmail />}
                />
                <Route path="/:tenantId/:requestId/verification/experian/:rc" element={<ExperianVerificationForm />} />
                <Route
                  path="/:tenantId/:requestId/verification/lexisnexis/:rc"
                  element={<LexisNexisVerificationScreen />}
                />
                <Route path="/:tenantId/:requestId/status/:rc" element={<RequestProcessingStatus />} />
                <Route path="/:tenantId/:requestId/verification-status/:status" element={<VerificationStatus />} />
                <Route path="/:tenantId/:requestId/preferences/:rc" element={<ManagePreferences />} />
                <Route path="/:tenantId/:requestId/edit/:rc/:code" element={<EditData />} />
                <Route path="/:tenantId/:requestId/edit/:rc" element={<EditData />} />
                <Route
                  path={`/:tenantId${RedirectUrls.ERROR}${RedirectUrls.DUPLICATE_REQUEST}/:deliveryIndicator`}
                  element={<DuplicateRequestError />}
                />
                <Route
                  path={`/:tenantId${RedirectUrls.ERROR}${RedirectUrls.EXPIRED_LINK}`}
                  element={<ExpiredLinkError />}
                />
                <Route
                  path={`/:tenantId${RedirectUrls.ERROR}${RedirectUrls.ALREADY_CONFIRMED_REQUEST_ERROR}`}
                  element={<AlreadyConfirmedRequestError />}
                />
                <Route
                  path={`/:tenantId${RedirectUrls.ERROR}${RedirectUrls.CONSUMER_DATA_ALREADY_UPDATED}`}
                  element={<ConsumerDataAlreadyUpdated />}
                />
                <Route
                  path={`/:tenantId${RedirectUrls.ERROR}${RedirectUrls.SESSION_CLOSED}/:so/:publishName`}
                  element={<SessionClosedError />}
                />
                <Route
                  path={`/:tenantId${RedirectUrls.ERROR}${RedirectUrls.SESSION_CLOSED}/:so`}
                  element={<SessionClosedError />}
                />
                <Route path={`/:tenantId${RedirectUrls.ERROR}${RedirectUrls.ACTION_ERROR}`} element={<ErrorAction />} />
                <Route
                  path={`/:tenantId${RedirectUrls.ERROR}${RedirectUrls.REQUEST_MESSAGE_ERROR}`}
                  element={<RequestMessageError />}
                />
                <Route path={`/:tenantId${RedirectUrls.ERROR}`} element={<ErrorAction />} />
                <Route path={`/:tenantId${RedirectUrls.REQUEST_ERROR}/:errorCode`} element={<RequestError />} />
              </Route>
            </>,
          ),
        )}
      />
    </GoogleReCaptchaProvider>
  );
}
