import React, { FC, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { requestService, RequestStatusTypes } from '../../services';
import { RedirectUrls } from '../../constants/routes';
import { ErrorCodes, ErrorMessages } from '../../constants/errorCodes';
import { LanguageContainer, LanguageType } from '../../state/languageContainer';
import { TenantContainer } from '../../state/tenantContainer';
import { VerificationProviderEnum } from '../../services/verification-questionnaire/types';
import axios, { AxiosError } from 'axios';
import { ErrorResponse } from '../../utils';

export const RequestConfirmation: FC = () => {
  const navigate = useNavigate();
  const { tenantId, requestId, confirmationId, rc, loc } = useParams();
  const { tenant, loadTenantSettings } = TenantContainer.useContainer();
  const { setLanguage, BigIdMeTranslate } = LanguageContainer.useContainer();
  const emailConfirmationAttempted = useRef<boolean>(false);

  useEffect(() => {
    loadTenantSettings({ requestId });
  }, [loadTenantSettings, requestId]);

  useEffect(() => {
    // by checking tenant.tenantId we ensure that tenant information is loaded
    if (requestId && confirmationId && tenant.tenantId && rc && !emailConfirmationAttempted.current) {
      emailConfirmationAttempted.current = true;
      setLanguage(loc! as LanguageType);
      requestService
        .confirmRequest(requestId!, confirmationId!)
        .then(() => {
          requestService
            .getRequestStatus(requestId, rc)
            .then(({ requestType }) => {
              requestService
                .getVerificationProviderByRequestId(requestId)
                .then(verificationProvider => {
                  if (requestType !== RequestStatusTypes.OPT_OUT && requestType !== RequestStatusTypes.PREFERENCES) {
                    if (verificationProvider === VerificationProviderEnum.EXPERIAN) {
                      navigate(`/${tenantId}/${requestId}/verification/experian/${rc}`);
                      return;
                    }
                  }

                  if (verificationProvider === VerificationProviderEnum.LEXIS_NEXIS) {
                    navigate(`/${tenantId}/${requestId}/verification/lexisnexis/${rc}`);
                    return;
                  }
                  navigate(`/${tenantId}/${requestId}/status/${rc}?skip=true`);
                })
                .catch(err => {
                  if (axios.isAxiosError(err)) {
                    const axiosError: AxiosError = err;
                    console.log(
                      BigIdMeTranslate(ErrorMessages[(axiosError?.response?.data as ErrorResponse).errorCode]),
                    );
                  }
                });
            })
            .catch(err => {
              if (axios.isAxiosError(err)) {
                const axiosError: AxiosError = err;
                console.log(BigIdMeTranslate(ErrorMessages[(axiosError?.response?.data as ErrorResponse).errorCode]));
              }
            });
        })
        .catch(err => {
          const { errorCode } = err?.response?.data;
          if (errorCode) {
            switch (errorCode) {
              case ErrorCodes.EMAIL_ALREADY_CONFIRMED: {
                navigate(`/${tenantId}${RedirectUrls.ERROR}${RedirectUrls.ALREADY_CONFIRMED_REQUEST_ERROR}`);
                break;
              }

              case ErrorCodes.REQUEST_ALREADY_CLOSED: {
                navigate(`/${tenantId}${RedirectUrls.ERROR}${RedirectUrls.EXPIRED_LINK}`);
                break;
              }

              default: {
                navigate(`/${tenantId}/error`);
              }
            }
          } else {
            navigate(`/${tenantId}/error`);
          }
        });
    }
  }, [requestId, confirmationId, tenant.tenantId, rc, setLanguage, loc, navigate, tenantId, BigIdMeTranslate]);

  return (
    <>
      <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
