import React, { FC, ReactElement } from 'react';
import { IconButton, Tooltip, TooltipProps } from '@mui/material';
import { ReactComponent as BigidMeIconInfo } from '../../assets/icons/info.svg';
import { e2e, WithTest } from '../../utils';

import { useStyles } from './BigidMeTooltipStyles';
import { LanguageContainer } from '../../state/languageContainer';

export type BigidMeTooltipPropsType = Pick<TooltipProps, 'placement'> &
  WithTest & {
    title: string | ReactElement;
    children?: ReactElement;
  };

export const BigidMeTooltip: FC<BigidMeTooltipPropsType> = ({ title, children, placement = 'top', ...rest }) => {
  const classes = useStyles();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  return (
    <Tooltip classes={{ tooltip: classes.tooltip }} title={title} arrow placement={placement} enterTouchDelay={0}>
      {children || (
        <IconButton disableRipple classes={{ root: classes.tooltipButton }} {...e2e(rest['data-aid'])} size="large">
          <BigidMeIconInfo title={BigIdMeTranslate('consumer_web_info')} />
        </IconButton>
      )}
    </Tooltip>
  );
};
