import { LexisNexisAnswersStateType } from './types';

export const transformAnswers = (answers: LexisNexisAnswersStateType) => ({
  questionSetId: 1,
  questions: Object.keys(answers).map(questionId => ({
    questionId: +questionId,
    choices: [
      {
        choice: answers[+questionId],
      },
    ],
  })),
});
