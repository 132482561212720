import React, { FC } from 'react';
import { useTheme } from '@mui/material';
import { LanguageContainer } from '../../state/languageContainer';

export const BigidMeIconReportWarning: FC<{ title?: string }> = ({ title }) => {
  const theme = useTheme();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  return (
    <svg
      role="img"
      aria-labelledby="reportWarmTitle"
      width="92px"
      height="92px"
      viewBox="0 0 92 92"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="reportWarmTitle">{title || BigIdMeTranslate('consumer_web_report_warning') || 'Report Warn'}</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle fill={theme.palette.bigidMe?.backgroundElement} cx="46" cy="46" r="45"></circle>
        <g transform="translate(26.000000, 25.000000)">
          <g>
            <g fill={theme.palette.bigidMe?.primary}>
              <g>
                <path d="M12.2727273,14 L27,14 C27.5522847,14 28,14.4477153 28,15 C28,15.5522847 27.5522847,16 27,16 L12.2727273,16 C11.7204425,16 11.2727273,15.5522847 11.2727273,15 C11.2727273,14.4477153 11.7204425,14 12.2727273,14 Z"></path>
                <path d="M12.2727273,21 L20.2727273,21 C20.825012,21 21.2727273,21.4477153 21.2727273,22 C21.2727273,22.5522847 20.825012,23 20.2727273,23 L12.2727273,23 C11.7204425,23 11.2727273,22.5522847 11.2727273,22 C11.2727273,21.4477153 11.7204425,21 12.2727273,21 Z"></path>
                <path d="M12.2727273,28 L16.2727273,28 C16.825012,28 17.2727273,28.4477153 17.2727273,29 C17.2727273,29.5522847 16.825012,30 16.2727273,30 L12.2727273,30 C11.7204425,30 11.2727273,29.5522847 11.2727273,29 C11.2727273,28.4477153 11.7204425,28 12.2727273,28 Z"></path>
                <rect x="5" y="14" width="4.18181818" height="2" rx="1"></rect>
                <rect x="5" y="21" width="4.18181818" height="2" rx="1"></rect>
                <rect x="5" y="28" width="4.18181818" height="2" rx="1"></rect>
                <path
                  d="M29.96875,1.24344979e-13 C31.5664309,1.24344979e-13 32.8724109,1.24891996 32.9636573,2.82372721 L32.96875,3 L32.9691706,19.0739878 L32.9290837,19.055816 C37.0981899,20.9364472 40,25.1293466 40,30 C40,36.627417 34.627417,42 28,42 C24.9387645,42 22.1452554,40.8537271 20.0252775,38.9669862 L20.0630574,39.0004682 L20.0630574,39.0004682 L3.03125,39 C1.43356912,39 0.127589121,37.75108 0.0363426934,36.1762728 L0.03125,36 L0.03125,3 C0.03125,1.40231912 1.28016996,0.0963391206 2.85497721,0.00509269341 L3.03125,1.24344979e-13 L29.96875,1.24344979e-13 Z M28,20 C22.4771525,20 18,24.4771525 18,30 C18,35.5228475 22.4771525,40 28,40 C33.5228475,40 38,35.5228475 38,30 C38,24.4771525 33.5228475,20 28,20 Z M29.96875,2 L3.03125,2 C2.51841416,2 2.09574284,2.38604019 2.03797773,2.88337887 L2.03125,3 L2.03125,36 C2.03125,36.5128358 2.41729019,36.9355072 2.91462887,36.9932723 L3.03125,37 L18.2526901,37.0008275 L18.2691705,37.0236765 C16.8414689,35.0491441 16,32.6228398 16,30 C16,23.372583 21.372583,18 28,18 C29.0196026,18 30.009505,18.1271617 30.9547131,18.3664911 L30.9687874,18.3700639 L30.9687874,18.3700639 L30.96875,3 C30.96875,2.48716416 30.5827098,2.06449284 30.0853711,2.00672773 L29.96875,2 Z"
                  fillRule="nonzero"
                ></path>
                <rect
                  stroke={theme.palette.bigidMe?.primary}
                  strokeWidth="3"
                  x="1.5"
                  y="7.63888889"
                  width="30"
                  height="1"
                ></rect>
                <ellipse cx="27.84375" cy="4.08333333" rx="1.03125" ry="1.02777778"></ellipse>
                <ellipse cx="23.71875" cy="4.08333333" rx="1.03125" ry="1.02777778"></ellipse>
                <line
                  x1="28"
                  y1="31"
                  x2="28"
                  y2="24"
                  stroke={theme.palette.bigidMe?.primary}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <circle cx="28" cy="35" r="1"></circle>
              </g>
            </g>
            <g transform="translate(0.000000, 1.000000)"></g>
          </g>
        </g>
      </g>
    </svg>
  );
};
