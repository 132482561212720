/* eslint-disable i18next/no-literal-string */
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  BigidBody1,
  BigidChip,
  BigidDropdown,
  BigidFormFieldLabelWrapper,
  BigidHeading5,
  BigidTextField,
  PrimaryButton,
} from '@bigid-ui/components';
import { BigidAttachmentIcon } from '@bigid-ui/icons';
import { Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { vendorService } from '../../../services/vendor/vendorService';
import { ActiveViewType } from '../types';
import { getViewByErrorCode } from '../utils';
import { FileUploader } from '../FileUploader/FileUploader';
import { VendorDetailsType } from '../../../services/vendor/types';

const COMMENT_MAX_SYMBOLS = 2000;

const schema = yup
  .object({
    resolution: yup.string().required('The field is required'),
    comment: yup.string().when('resolution', ([resolution], schema) => {
      return resolution === 'Other' ? schema.required('The field is required') : schema.notRequired();
    }),
  })
  .required();

type VendorRequestResolutionFormPropsType = {
  resolutions: string[];
  setStatus: (status: ActiveViewType) => void;
  onSuccess: (data: VendorDetailsType) => void;
};

export const VendorRequestResolutionForm: FC<VendorRequestResolutionFormPropsType> = ({
  resolutions,
  setStatus,
  onSuccess,
}) => {
  const { vendorRequestId = '', secret = '' } = useParams();
  const [files, setFiles] = useState<Array<{ fileUploadId: string; fileName: string }>>([]);
  const removeFile = (id: string) => {
    setFiles(prevState => prevState.filter(({ fileUploadId }) => fileUploadId !== id));
  };

  const { watch, control, handleSubmit } = useForm<{
    resolution: 'Other' | string;
    comment?: string;
  }>({
    defaultValues: {
      resolution: '',
      comment: '',
    },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const commentRequired = watch('resolution') === 'Other';

  const onSubmit = handleSubmit(async values => {
    try {
      const data = await vendorService.postVendorRequestResolutions(vendorRequestId, {
        secret,
        ...values,
        files,
      });
      onSuccess(data);
    } catch (error) {
      const code = (error as AxiosError<{ errorCode: number }>)?.response?.data?.errorCode;
      setStatus(code ? getViewByErrorCode(code) : 'failed');
    }
  });

  const resolutionOptions =
    resolutions?.map(text => ({
      id: text,
      displayValue: text,
      value: text,
    })) || [];

  const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    const fileUploadId = await vendorService.uploadVendorRequestFiles(formData);
    setFiles(prevState => [...prevState, { fileUploadId, fileName: file.name }]);
  };

  return (
    <>
      <BigidHeading5 variantMapping={{ h5: 'h2' }} fontWeight="bold">
        Deletion resolution
      </BigidHeading5>
      <BigidBody1 fontSize="medium" mb={3}>
        Please update the status of the request once a resolution has been provided
      </BigidBody1>
      <Stack gap={3}>
        <Controller
          name="resolution"
          control={control}
          render={({ field, fieldState }) => (
            <BigidFormFieldLabelWrapper
              id="resolution"
              name="resolution"
              label="Resolution"
              isRequired
              errorIsShown
              error={fieldState.error?.message}
            >
              <BigidDropdown
                onSelect={([{ value }]) => field.onChange(value)}
                options={resolutionOptions}
                dataAid="deleteResolutionSelect"
              />
            </BigidFormFieldLabelWrapper>
          )}
        />
        <Controller
          name="comment"
          control={control}
          render={({ field, fieldState, formState }) => (
            <>
              <BigidTextField
                multiline
                rows={4}
                label="Comment"
                required={commentRequired}
                placeholder="Write your comment here"
                onChange={value => field.onChange(value.target.value.substring(0, COMMENT_MAX_SYMBOLS))}
                value={field.value}
                disabled={formState.isSubmitting}
                errorMessage={fieldState.error?.message}
              />
              <BigidBody1 size="small" mt={-2}>{`${field.value?.length}/${COMMENT_MAX_SYMBOLS}`}</BigidBody1>
            </>
          )}
        />
        <Stack direction="row" gap={1} flexWrap={{ md: 'nowrap', xs: 'wrap' }}>
          {files.map(({ fileName, fileUploadId }) => (
            <BigidChip
              key={fileUploadId}
              icon={<BigidAttachmentIcon />}
              label={fileName}
              onDelete={() => removeFile(fileUploadId)}
            />
          ))}
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <FileUploader format={'.pdf, .jpg, .jpeg, .png, .csv'} onSelect={uploadFile} disabled={files.length >= 5} />
          <div>
            <PrimaryButton size="large" text="Send" onClick={onSubmit} dataAid="deletionResolutionSubmitButton" />
          </div>
        </Stack>
      </Stack>
    </>
  );
};
