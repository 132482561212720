import React from 'react';
import { SubmitRequest } from './SubmitRequest';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LanguageContainer } from '../../state/languageContainer';
import { CircularProgress } from '@mui/material';
import { useStyles } from './SubmitRequestStyles';
import { useSubmitRequest } from './hooks/useSubmitRequest';

export const SubmitRequestWithToken = () => {
  const { search } = useLocation();
  const { loadedLanguages } = LanguageContainer.useContainer();
  const navigate = useNavigate();
  const classes = useStyles();

  const { publishName } = useParams();

  const urlSearchParams = new URLSearchParams(search);
  const hashParams = new URLSearchParams(window.location.hash.substr(1));

  const idToken = hashParams.get('id_token') || '';
  const integrationName = urlSearchParams.get('integration-name') || '';
  const token = urlSearchParams.get('token') || '';
  const employeeToken = urlSearchParams.get('employee-token') || '';
  const action = urlSearchParams.get('action') || '';
  const requestorTypeAction = urlSearchParams.get('requestorType') || '';
  const { countries, userProfiles, requestorTypes, requestDefinitions, authorizedAgent } = useSubmitRequest({
    publishName,
    token,
    employeeToken,
    integrationName,
    idToken,
  });

  return loadedLanguages.length ? (
    <SubmitRequest
      userProfiles={userProfiles}
      countries={countries}
      requestorTypes={requestorTypes}
      requestDefinitions={requestDefinitions}
      authorizedAgent={authorizedAgent}
      navigate={navigate}
      action={action}
      requestorTypeAction={requestorTypeAction}
      isUserDetailsPreFilled={!!token || !!employeeToken || !!idToken}
    />
  ) : (
    <CircularProgress className={classes.loader} />
  );
};
