import React, { FC, ReactText } from 'react';
import { Root, classes } from './NotificationPanelStyles';

interface NotificationPanelPropsType {
  text: string | JSX.Element | JSX.Element[];
  visible?: boolean;
  margin?: ReactText;
  Icon?: FC;
  transparentBackground?: boolean;
  testId?: string;
}

export const NotificationPanel = ({
  text,
  visible = true,
  margin = 0,
  Icon,
  transparentBackground = false,
  testId,
}: NotificationPanelPropsType) => {
  return (
    <Root theme={{ visible: visible, transparentBackground: transparentBackground }}>
      <div className={classes.wrapper} style={{ margin }} data-aid={testId}>
        {Icon && <Icon data-aid={`${testId}_icon`} />}
        <div className={classes.richText} data-aid={`${testId}_text`}>
          {text}
        </div>
      </div>
    </Root>
  );
};
