import { styled } from '@mui/material/styles';
import { FormHelperText, InputLabel } from '@mui/material';

import { errorColor } from '../../assets/theme/BigidMeColors';

export const HelperContainer = styled('div')<FormHelperTextContainerType>(({ theme, fullWidth }) => ({
  minWidth: fullWidth ? '50%' : '100%',
  marginTop: `${theme.spacing(2)} !important`,
  flex: 1,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(2),
    marginTop: `${theme.spacing(6)} !important`,
  },
}));

export const StyledInputLabel = styled(InputLabel)(({ theme, hidden }) =>
  hidden
    ? {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: '1px',
        margin: '-1px',
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        width: '1px',
      }
    : {
        color: theme.palette.text.primary,
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.typography.caption.lineHeight,
        marginBottom: theme.spacing(2),
        whiteSpace: 'nowrap',

        [theme.breakpoints.down('sm')]: {
          width: 'auto',
          fontSize: '0.875rem',
          lineHeight: '0.875rem',
          transform: 'none',
        },
        '&.Mui-focused': {
          color: `${theme.palette.text.primary} !important`,
        },
      },
);

type FormHelperTextContainerType = {
  fullWidth?: boolean;
};

export const StyledFormHelperText = styled(FormHelperText)<FormHelperTextContainerType>(({ theme, fullWidth }) => ({
  fontSize: theme.typography.h6.fontSize,
  lineHeight: theme.typography.h6.lineHeight,
  color: `${errorColor} !important`,
  width: fullWidth ? 'auto' : 212,
}));

// div needed to support rtl
export const RequiredText = styled('div')(() => ({
  display: 'inline-block',
  fontStyle: 'italic',
  fontWeight: 300,
}));
