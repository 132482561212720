import React, { FC, useEffect } from 'react';
import { BigidMeWireframe } from '../../components/BigidMeWireframe';
import { TenantContainer } from '../../state/tenantContainer';
import { LanguageContainer } from '../../state/languageContainer';
import { Typography } from '@mui/material';
import { ReactComponent as BigidMeIconErrorGeneric } from '../../assets/icons/icon-whreareyou.svg';

import { useStylesAction } from './ErrorsStyles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getResponseError } from '../../utils/errors';
import { RedirectUrls } from '../../constants/routes';
import queryString from 'query-string';

export const RequestError: FC = () => {
  const { tenant, loadTenantSettings } = TenantContainer.useContainer();
  const navigate = useNavigate();
  const location = useLocation();
  const errorCode = Number(useParams().errorCode);
  const { BigIdMeTranslate, BigIdMeTranslateWithNode } = LanguageContainer.useContainer();
  const classes = useStylesAction();

  useEffect(() => {
    loadTenantSettings({});
  }, [loadTenantSettings]);

  if (!errorCode) {
    navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}`);
    return null;
  }

  const error = getResponseError(errorCode);

  if (!error) {
    navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}`);
    return null;
  }

  const fields = queryString.parse(location.search);
  const translatedTitle =
    error.withNode && fields
      ? BigIdMeTranslateWithNode(
          error.text,
          Object.entries(fields).map(([key, value]) => ({
            key,
            value,
          })),
        )
      : BigIdMeTranslate(error.text);

  return (
    <BigidMeWireframe {...tenant} pageTitle={String(translatedTitle)}>
      <div className={classes.container}>
        <BigidMeIconErrorGeneric title={String(translatedTitle)} />
        <Typography variant="h1" color="textPrimary" className={classes.title} data-aid="request_info_title">
          {translatedTitle}
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center" className={classes.text}>
          {BigIdMeTranslateWithNode('consumer_web_errors_please-try-again', [
            {
              key: 'contact_us',
              value: (
                <a href={tenant.supportLink} key={'contact_us'}>
                  {BigIdMeTranslate('consumer_web_general_contact')}
                </a>
              ),
            },
          ])}
        </Typography>
      </div>
    </BigidMeWireframe>
  );
};
