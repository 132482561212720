import React, { FC, useRef, useState } from 'react';
import { BigidFormFieldLabelWrapper, BigidTooltip, TertiaryButton } from '@bigid-ui/components';
import { BigidAttachmentIcon, BigidInfoIcon } from '@bigid-ui/icons';
import { Box, Stack } from '@mui/material';

const KILOBYTE = 1024;

type FileUploaderPropsType = {
  format?: string;
  maxSize?: number;
  disabled?: boolean;
  onSelect: (file: File) => void;
};

export const FileUploader: FC<FileUploaderPropsType> = ({
  format = '.pdf, .jpg, .jpeg, .png',
  maxSize = 10,
  disabled = false,
  onSelect,
}) => {
  const [uploadError, setUploadErrorStatus] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const onSelectFile = (files: FileList | null) => {
    if (!files) return;

    if (files[0].size / (KILOBYTE * KILOBYTE) > maxSize) {
      const error = `Maximum file size: ${maxSize}MB per file.`;
      setUploadErrorStatus(error);
      return;
    }

    const formatRegExp = new RegExp(`(${format.replace(/,\s?/g, '|')})$`, 'i');
    if (!formatRegExp.test(files[0].name)) {
      const error = 'Wrong file format';
      setUploadErrorStatus(error);
      return;
    }
    onSelect(files[0]);
    setUploadErrorStatus('');
  };

  const onFileChange = (event: { target: { files: FileList | null; value: string } }) => {
    onSelectFile(event.target.files);
    event.target.value = '';
  };

  return (
    <BigidFormFieldLabelWrapper id="file-upload" errorIsShown error={uploadError}>
      <Stack direction="row" gap={1} alignItems="baseline">
        <TertiaryButton
          disabled={disabled}
          size="medium"
          text="Upload file"
          startIcon={<BigidAttachmentIcon />}
          onClick={() => inputRef.current?.click()}
        />
        <BigidTooltip
          title="Files supported: csv, pdf, jpg, png.
      Max. 5 files, no more than 10 MB each"
        >
          <Box display="inline-block">
            <BigidInfoIcon />
          </Box>
        </BigidTooltip>
      </Stack>
      <Box display="none">
        <input ref={inputRef} type="file" accept={format} onChange={onFileChange} />
      </Box>
    </BigidFormFieldLabelWrapper>
  );
};
