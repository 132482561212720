import React, { FC } from 'react';
import { BigidMeRequestInfoWrapper } from '../../components/BigidMeRequestInfoWrapper';
import { LanguageContainer } from '../../state/languageContainer';

export const AlreadyConfirmedRequestError: FC = () => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  return (
    <BigidMeRequestInfoWrapper
      componentNaming="AlreadyConfirmedRequestError"
      pageTitle={BigIdMeTranslate('page_title_request_confirmed')}
    >
      {BigIdMeTranslate('consumer_web_errors_progress-updates')}
    </BigidMeRequestInfoWrapper>
  );
};
