import makeStyles from '@mui/styles/makeStyles';
import { gray } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles({
  requestId: {
    borderRadius: 3,
    backgroundColor: gray[175],
    padding: '6px 12px',
    textAlign: 'center',
  },
  extraBold: {
    // needed for rtl
    display: 'inline-block',
    fontWeight: 800,
  },
});
