import makeStyles from '@mui/styles/makeStyles';
import { gray, white } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles({
  root: {
    backgroundColor: white,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  status: {
    width: '100%',
    backgroundColor: white,
    margin: '0 auto',
    flex: 'auto',
  },
  text: {
    textAlign: 'center',
    padding: '20px 0',
    fontSize: '1rem',
  },
  progress: {
    width: '100%',
    backgroundColor: gray[75],
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 10,
  },
  extraBold: {
    fontWeight: 800,
  },
});
