import React, { FC, useState } from 'react';
import { Divider, Typography } from '@mui/material';
import { ManagePreferencesItemType, ManagePreferencesSectionsType } from '../../services';
import { BigidMeSelect } from '../BigidMeSelect';
import { normalizeManagePreferencesReasons } from '../../utils/data-normalizer';
import { e2e } from '../../utils';

import { useStyles } from './BigidMeSurveyStyles';
import { LanguageContainer } from '../../state/languageContainer';
import { BigidMeTooltip } from '../BigidMeTooltip';
import { BigidMeTextField } from '../BigidMeTextField';

const OTHER_OPTION_VALUE = 0;

export interface BigidMeSurveyType {
  sections: ManagePreferencesSectionsType[];
  onChange: (sections: ManagePreferencesSectionsType[], otherReason: string) => void;
  isPreviewPP?: boolean;
}

export const BigidMeSurvey: FC<BigidMeSurveyType> = ({ sections, onChange, isPreviewPP }) => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const [isOther, setOtherReason] = useState(false);
  const [otherReasonText, setOtherReasonText] = useState<string>('');
  const classes = useStyles();

  if (sections.length === 0) return <></>;

  const onSurveyChange = (value: string | number) => {
    const newData = [...sections];
    const firstSection = newData[0];
    const newSection = { ...firstSection };
    newSection.items = newSection.items.map((item: ManagePreferencesItemType) => ({
      ...item,
      selected: value === item.id,
    }));
    newData[0] = newSection;
    setOtherReasonText('');
    onChange(newData, '');
    setOtherReason(value === OTHER_OPTION_VALUE);
  };

  const onReasonChange = (value: string) => {
    onChange(sections, value);
    setOtherReasonText(value);
  };

  return (
    <>
      <Typography variant="h4" color="textSecondary" className={classes.title} gutterBottom>
        {sections[0].title}
        {sections[0].hint && <BigidMeTooltip title={sections[0].hint} />}
      </Typography>
      <Divider className={classes.divider} />
      <Typography variant="subtitle1" color="textSecondary">
        {sections?.[0]?.subtitle}
      </Typography>
      <BigidMeSelect
        className={classes.select}
        placeholder={BigIdMeTranslate('consumer_web_submit_request-preferences_select-reason')}
        options={normalizeManagePreferencesReasons(sections[0].items)}
        onChange={onSurveyChange}
        menuIsOpen={isPreviewPP}
        {...e2e(`select_reason`)}
      />
      {isOther && (
        <>
          <Typography variant="subtitle1" color="textSecondary" className={classes.otherReasonLabel}>
            {BigIdMeTranslate('consumer_web_submit_request-preferences_other-reason')}
          </Typography>
          <BigidMeTextField
            multiline
            value={otherReasonText}
            onChange={onReasonChange}
            customClasses={{
              inputRoot: classes.otherReasonInput,
            }}
          />
        </>
      )}
    </>
  );
};
