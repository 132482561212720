import React, { FC, useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Typography } from '@mui/material';
import { LanguageContainer } from '../../state/languageContainer';
import { BigidMeModal } from '../BigidMeModal';
import { ReactComponent as BigidMeIconErrorGeneric } from '../../assets/icons/icon-whreareyou.svg';
import { MeButton } from '../MeButton';

import { useStyles } from './BigidMeSessionControllerStyles';
import { RedirectUrls } from '../../constants/routes';

const AUTH_SESSION_EXPIRE_TIME = process.env.REACT_APP_AUTH_SESSION_EXPIRE_TIME
  ? Number(process.env.REACT_APP_AUTH_SESSION_EXPIRE_TIME)
  : 15 * 60 * 1000;

export enum SessionClosingStages {
  NONE,
  REMINDER,
  CLOSE,
}

type BigidMeSessionEffectProps = {
  tenantId: string;
  navigate: NavigateFunction;
  startOver?: boolean;
  isPreview?: boolean;
  publishName?: string;
};

export const BigidMeSessionEffect = ({
  tenantId,
  navigate,
  startOver,
  isPreview,
  publishName,
}: BigidMeSessionEffectProps) => {
  const [sessionClosingStage, setSessionClosingStage] = useState<SessionClosingStages>(SessionClosingStages.NONE);

  useEffect(() => {
    let sessionReminderTimerId = setTimeout(() => {
      setSessionClosingStage(SessionClosingStages.REMINDER);
    }, AUTH_SESSION_EXPIRE_TIME - 60 * 1000);
    let sessionTimerId = setTimeout(() => {
      navigate &&
        navigate(
          `/${tenantId}${RedirectUrls.ERROR}${RedirectUrls.SESSION_CLOSED}${startOver ? '/1' : '/0'}${
            publishName && '/' + publishName
          }`,
        );
    }, AUTH_SESSION_EXPIRE_TIME);
    const listeners: { event: string; fn: () => void }[] = [];
    ['mousemove', 'focus', 'blur', 'click', 'resize', 'scroll', 'change', 'keypress'].forEach(event => {
      const fn = () => {
        clearTimeout(sessionReminderTimerId);
        clearTimeout(sessionTimerId);
        sessionReminderTimerId = setTimeout(() => {
          setSessionClosingStage(SessionClosingStages.REMINDER);
        }, AUTH_SESSION_EXPIRE_TIME - 60 * 1000);
        sessionTimerId = setTimeout(() => {
          navigate &&
            navigate(
              `/${tenantId}${RedirectUrls.ERROR}${RedirectUrls.SESSION_CLOSED}${startOver ? '/1' : '/0'}/${
                publishName || ''
              }`,
            );
        }, AUTH_SESSION_EXPIRE_TIME);
      };
      listeners.push({ event, fn });
      window.addEventListener(event, fn);
    });
    return () => {
      clearTimeout(sessionReminderTimerId);
      clearTimeout(sessionTimerId);
      listeners.forEach(listener => {
        window.removeEventListener(listener.event, listener.fn);
      });
    };
  }, [tenantId, startOver, isPreview]);

  return { sessionClosingStage, setSessionClosingStage };
};

interface BigidMeSessionControllerType {
  sessionClosingStage: SessionClosingStages;
  setSessionClosingStage: (stage: SessionClosingStages) => void;
}

export const BigidMeSessionController: FC<BigidMeSessionControllerType> = ({
  sessionClosingStage,
  setSessionClosingStage,
}) => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const classes = useStyles();

  const handleClose = () => {
    setSessionClosingStage(SessionClosingStages.NONE);
  };

  return (
    <BigidMeModal open={sessionClosingStage === SessionClosingStages.REMINDER} onClose={handleClose}>
      <div className={classes.icon}>
        <BigidMeIconErrorGeneric title={BigIdMeTranslate('consumer_web_error')} />
      </div>
      <Typography variant="h4" color="textPrimary" className={classes.title}>
        {BigIdMeTranslate('consumer_web_session_hello')}
      </Typography>
      <Typography variant="h6" color="textPrimary" className={classes.text}>
        {BigIdMeTranslate('consumer_web_session_to-protect')}
      </Typography>
      <div className={classes.controls}>
        <MeButton
          text={BigIdMeTranslate('consumer_web_general_continue')}
          onClick={handleClose}
          type="primary"
          classes={classes.button}
          bi={{ eventType: 'session_controller_continue' }}
        />
      </div>
    </BigidMeModal>
  );
};
