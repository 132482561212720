import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { LanguageContainer } from '../../state/languageContainer';
import { BigidMeRequestInfoFooter } from '../BigidMeRequestInfoWrapper/BigidMeRequestInfoFooter';
import { BigidMeIconChangesSubmited } from '../../assets/icons/BigidMeIconChangesSubmited';

import { useStyles } from './BigidMeRequestStatusStyles';

export const BigidMeChangesStatusSubmitted: FC = () => {
  const classes = useStyles();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  return (
    <div className={classes.root}>
      <div className={classes.layout}>
        <BigidMeIconChangesSubmited />
        <div className={classes.status}>
          <Typography variant="h1" color="textSecondary" align="center" gutterBottom>
            {BigIdMeTranslate('consumer_web_general_changes-submitted')}
          </Typography>
        </div>
        <div className={classes.text}>
          <Typography variant="subtitle1" color="textPrimary" align="center" gutterBottom>
            {BigIdMeTranslate('consumer_web_general_we-will-notify')}
          </Typography>
        </div>
        <BigidMeRequestInfoFooter />
      </div>
    </div>
  );
};
