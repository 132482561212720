export const RedirectUrls = {
  ERROR: '/error',
  DUPLICATE_REQUEST: '/duplicate-request',
  ACTION_ERROR: '/action-error',
  ALREADY_CONFIRMED_REQUEST_ERROR: '/already-confirmed-request',
  EXPIRED_LINK: '/expired-link',
  SESSION_CLOSED: '/session-closed',
  CONSUMER_DATA_ALREADY_UPDATED: '/consumer-data-already-updated',
  REQUEST_MESSAGE_ERROR: '/request-is-closed',
  REQUEST_ERROR: '/request-error',
};
