import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  item: {
    alignItems: 'start',
    outline: 'none !important',

    '&:focus-within': {
      outline: '2px solid #888',
      outlineStyle: 'auto',
    },
  },
  input: {
    width: 12,
    height: 12,
    padding: 0,
    margin: 5,
    outline: 'none',

    [theme.breakpoints.down('sm')]: {
      width: 20,
      height: 20,
      marginLeft: 12,

      '& > span > svg': {
        width: '100%',
        height: '100%',
      },
    },
  },
}));
