import { createContainer } from 'unstated-next';
import { useState } from 'react';
import { EditDataRequestFieldType } from '../screens/EditData/EditData';

export const useUserData = (initial: EditDataRequestFieldType[] = []) => {
  const [userData, setUserData] = useState<EditDataRequestFieldType[]>(initial);
  return { userData, setUserData };
};

export const UserDataContainer = createContainer(useUserData);
