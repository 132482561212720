import React, { FC, useState, useCallback, useEffect, InputHTMLAttributes } from 'react';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import PhoneInput from 'react-phone-input-with-accessibility';
import 'react-phone-input-with-accessibility/lib/style.css';
import cx from 'classnames';
import { ReactComponent as BigidMeIconShield } from '../../assets/icons/shield.svg';
import { ReactComponent as IconVerified } from '../../assets/icons/verified.svg';

import { useStyles } from './BigidMePhoneFieldStyles';
import { LanguageContainer } from '../../state/languageContainer';
import { e2e, WithTest } from '../../utils';
import { MeButton } from '../MeButton';
import { BigidMeModal } from '../BigidMeModal';
import { UserPhoneType } from '../../services';
import { NotificationPanel } from '../NotificationPanel';
import { PhoneVerification } from '../../screens/PhoneVerification/PhoneVerification';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { BigidMeTooltip } from '../BigidMeTooltip';

import de from 'react-phone-input-with-accessibility/lang/de.json';

export interface BigidMePhoneFieldProps extends WithTest {
  label?: string;
  id?: string;
  withSmsValidation?: boolean;
  tooltip?: string;
  required?: boolean;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  onChange: (value: UserPhoneType) => void;
  onBlur?: () => void;
  defaultValue?: string; // can be country code ('ua') or full number (+38063...)
}

export const BigidMePhoneField: FC<BigidMePhoneFieldProps> = ({
  label,
  id,
  tooltip,
  required,
  placeholder,
  error,
  disabled,
  onChange,
  onBlur,
  defaultValue,
  withSmsValidation,
  ...rest
}) => {
  const { BigIdMeTranslate, language } = LanguageContainer.useContainer();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState<string | undefined>(undefined);
  const [defaultCountryCode, setDefaultCountryCode] = useState<string | undefined>(undefined);
  const [phoneConfirmationModal, setPhoneConfirmationModal] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isValid, setValidated] = useState(true);
  const classes = useStyles({ hasValue: !!phoneNumber });
  const styleClasses = cx(classes.inputRoot, {
    [classes.inputError]: error,
    [classes.inputDisabled]: disabled,
  });

  const handleOnChange = useCallback(
    (phoneNumber: string, data: { countryCode: string }): void => {
      setPhoneNumber(phoneNumber);
      setCountryCode(data.countryCode);
      setIsVerified(false);

      const phoneUtil = PhoneNumberUtil.getInstance();
      try {
        const isPhoneValid = phoneUtil.isValidNumberForRegion(
          phoneUtil.parse(phoneNumber, data.countryCode),
          data.countryCode,
        );
        setValidated(isPhoneValid);
      } catch {
        setValidated(false);
      }

      onChange({
        validationId: withSmsValidation ? undefined : 'turned_off',
        countryCode: data.countryCode,
        phone: phoneNumber ? '+' + phoneNumber : '',
      });
    },
    [onChange, withSmsValidation],
  );

  useEffect(() => {
    function initializeDefaultContryCode() {
      if (defaultValue?.length == 2) {
        setDefaultCountryCode(defaultValue);
      } else {
        const phoneUtil = PhoneNumberUtil.getInstance();
        try {
          const phone = phoneUtil.parse(defaultValue);
          setPhoneNumber(phone.getCountryCode() + '' + phone.getNationalNumber());
          setValidated(true);
        } catch {
          console.log('phone is not valid');
          setValidated(false);
        }
      }
    }
    initializeDefaultContryCode();
  }, [defaultValue]);

  const handleOnBlur = useCallback(() => {
    onBlur && onBlur();
  }, [onBlur]);

  const handleCloseModal = () => {
    setIsVerified(false);
    setPhoneConfirmationModal(false);
  };

  const onPinVerificationSuccess = (validationId?: string) => {
    setIsVerified(!!validationId);
    onChange({ phone: '+' + phoneNumber, validationId, countryCode });
    setPhoneConfirmationModal(false);
  };

  const onPinVerificationError = () => {
    setIsVerified(false);
  };

  const onVerifyClick = () => {
    setPhoneConfirmationModal(true);
  };

  return (
    <FormControl fullWidth>
      {label && (
        <InputLabel shrink classes={{ root: classes.labelRoot }} htmlFor={id} {...e2e(`label_${rest['data-aid']}`)}>
          {label}
          {required && (
            <span className={classes.italic}>&nbsp;({BigIdMeTranslate('consumer_web_validation_required')})</span>
          )}
          {tooltip && <BigidMeTooltip title={tooltip} data-aid="phone-input-tooltip" />}
        </InputLabel>
      )}
      {/* Hidden label for country code (accessibility) */}
      <label className={classes.visualHidden} id={`countryCodeLabel-${id}`}>
        {BigIdMeTranslate('consumer_web_submit_country_code_label')}
      </label>
      <div className={classes.container}>
        <div className={classes.inputContainer}>
          <PhoneInput
            id={id}
            country={defaultCountryCode}
            value={phoneNumber}
            placeholder={placeholder}
            prefix="+"
            disabled={disabled}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            inputClass={styleClasses}
            buttonClass={styleClasses}
            localization={language === 'de' ? de : undefined}
            inputProps={
              {
                ...e2e(rest['data-aid']),
                ['aria-describedby']: error ? `${id}-error` : undefined,
                tabIndex: 0,
                id,
              } as InputHTMLAttributes<HTMLInputElement>
            }
          />
          {error && (
            <FormHelperText error classes={{ root: classes.helperTextRoot }} id={`${id}-error`}>
              {error}
            </FormHelperText>
          )}
        </div>
        <div className={classes.buttonContainer}>
          {withSmsValidation && !isVerified && (
            <MeButton
              onClick={onVerifyClick}
              text={BigIdMeTranslate('consumer_web_verify')}
              isDisabled={!phoneNumber || !isValid || !!error}
              type="primary"
              classes={classes.verifyButton}
              {...e2e(`btn_verify`)}
              bi={{ eventType: 'phone_field_verify' }}
            />
          )}
          {withSmsValidation && isVerified && (
            <div className={classes.statusLabel}>
              <IconVerified title={BigIdMeTranslate('consumer_web_verified')} />
              <span>{BigIdMeTranslate('consumer_web_verified')}</span>
            </div>
          )}
        </div>
      </div>
      {withSmsValidation && !isVerified && (
        <NotificationPanel margin="25px 0 0 0" Icon={BigidMeIconShield} text={tooltip || ''} />
      )}
      <BigidMeModal open={!!phoneConfirmationModal} onClose={handleCloseModal}>
        {!!phoneConfirmationModal && (
          <PhoneVerification
            phone={'+' + phoneNumber}
            onPinVerificationSuccess={onPinVerificationSuccess}
            onPinVerificationError={onPinVerificationError}
          />
        )}
      </BigidMeModal>
    </FormControl>
  );
};
