import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { BigidMeManagePreferences, BigidMeWireframe, InternalStepsType } from '../../components';
import {
  ManagePreferencesStatus,
  ManagePreferencesStepType,
  ManagePreferencesType,
  requestService,
} from '../../services';
import { TenantContainer } from '../../state/tenantContainer';
import { PreferencesRequestStatus } from './PreferencesRequestStatus';

import { useStyles } from './ManagePreferencesStyles';
import { useParams } from 'react-router-dom';
import { LanguageContainer } from '../../state/languageContainer';

const POLLING_INTERVAL = 5000;

export const ManagePreferences: FC = () => {
  const { requestId, rc } = useParams();
  const classes = useStyles();
  const [preferences, setPreferences] = useState<ManagePreferencesType & { loaded: boolean }>({
    steps: [],
    loaded: false,
  });
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const { tenant, loadTenantSettings } = TenantContainer.useContainer();
  const pollingTimeout = useRef<number>();
  const [internalStep, setInternalStep] = useState(InternalStepsType.PREFERENCES);
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  useEffect(() => {
    loadTenantSettings({ requestId });
  }, [requestId, loadTenantSettings]);

  const fetchPreferences = useCallback(
    () =>
      requestService
        .fetchPreferences(requestId!, rc!)
        .then(data => {
          if (data.status === ManagePreferencesStatus.PENDING) {
            pollingTimeout.current = window.setTimeout(() => fetchPreferences(), POLLING_INTERVAL);
          }
          setPreferences({ ...data, loaded: true });
        })
        .catch(() => {
          setPreferences(prev => ({ ...prev, status: ManagePreferencesStatus.ERROR }));
          pollingTimeout.current = window.setTimeout(() => fetchPreferences(), POLLING_INTERVAL);
        }),
    [requestId, rc],
  );

  useEffect(() => {
    fetchPreferences();

    return () => {
      pollingTimeout.current && window.clearTimeout(pollingTimeout.current);
    };
  }, [fetchPreferences]);

  const handleApply = (steps: ManagePreferencesStepType[], selectedReason: string) => {
    setSubmitDisabled(true);
    requestService
      .updatePreferences({ steps, selectedReason, requestId: requestId!, rc: rc! })
      .then(() => setPreferences(prev => ({ ...prev, status: ManagePreferencesStatus.SUBMITTED })))
      .catch(() => {
        setPreferences(prev => ({ ...prev, status: ManagePreferencesStatus.ERROR }));
        setSubmitDisabled(false);
      });
  };

  const handleSubmitStatus = useMemo(() => {
    const { status, steps, selectedReason, loaded } = preferences;

    if (status === ManagePreferencesStatus.ERROR && loaded) {
      return () =>
        requestService
          .updatePreferences({ steps, selectedReason, requestId: requestId!, rc: rc! })
          .then(fetchPreferences);
    }

    if (status === ManagePreferencesStatus.NOT_FOUND) {
      return () => requestService.checkEmail({ requestId: requestId!, rc: rc! }).then(fetchPreferences);
    }

    return undefined;
  }, [fetchPreferences, preferences, rc, requestId]);

  return (
    <BigidMeWireframe {...tenant} hideLanguageControls pageTitle={BigIdMeTranslate('page_title_manage_preferences')}>
      <div className={classes.root}>
        {preferences.status === ManagePreferencesStatus.COMPLETED ? (
          <BigidMeManagePreferences
            data={preferences.steps}
            onApply={handleApply}
            step={internalStep}
            submitDisabled={isSubmitDisabled}
            setStep={setInternalStep}
          />
        ) : (
          preferences.status && <PreferencesRequestStatus status={preferences.status} onSubmit={handleSubmitStatus} />
        )}
      </div>
    </BigidMeWireframe>
  );
};
