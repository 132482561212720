import React, { useEffect } from 'react';
import { Box } from '@mui/system';

import { ReactComponent as BigidMeIconErrorGeneric } from '../../assets/icons/error.svg';
import { StyledErrorPage } from './ErrorsStyles';
import { LanguageContainer } from '../../state/languageContainer';
import { setPageTitle } from '../../utils/helpers';

export const NotFound: React.FC = () => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  useEffect(() => {
    setPageTitle(BigIdMeTranslate('consumer_web_page_not_found_title'));
  }, [BigIdMeTranslate]);

  return (
    <StyledErrorPage.Container>
      <Box mb={2}>
        <BigidMeIconErrorGeneric title={'consumer_web_error'} />
      </Box>
      <StyledErrorPage.Title variant="h4" color="textPrimary" mb={1}>
        {BigIdMeTranslate('consumer_web_page_not_found_title')}
      </StyledErrorPage.Title>
      <StyledErrorPage.SubTitle variant="h6" color="textPrimary">
        {BigIdMeTranslate('consumer_web_page_not_found_description')}
      </StyledErrorPage.SubTitle>
    </StyledErrorPage.Container>
  );
};
