import React, { FC } from 'react';
import { useTheme } from '@mui/material';
import { BigidPrivacyLegalIllustration } from '@bigid-ui/icons';

import { LanguageContainer } from '../../state/languageContainer';

export const BigidMeIconAppeal: FC<{ title?: string }> = ({ title }) => {
  const theme = useTheme();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  return (
    <BigidPrivacyLegalIllustration
      fillColor={theme.palette.bigidMe.illustrationColor}
      titleId="appealTitle"
      title={title || BigIdMeTranslate('brand_requests.appeal.title') || 'Request an Appeal'}
    />
  );
};
