import React, { useState, useEffect, KeyboardEvent, ReactNode, FC, useCallback } from 'react';
import { useTheme } from '@mui/material';
import cx from 'classnames';
import Select, { OnChangeValue, FormatOptionLabelMeta, components, OptionProps, GroupBase } from 'react-select';
import { LanguageContainer } from '../../state/languageContainer';
import { e2e, WithTest } from '../../utils';
import { FieldWrapper } from '../FieldWrapper';

import { useClasses, selectStyles } from './BigidMeSelectStyles';

export interface BigidSelectOption {
  label: string;
  node?: ReactNode;
  value: string;
}

export interface BigidSelectProps extends WithTest {
  label?: string;
  id?: string;
  className?: string;
  placeholder?: string;
  name?: string;
  required?: boolean;
  options?: BigidSelectOption[];
  isDisabled?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  error?: string;
  value?: BigidSelectOption[] | null;
  defaultValue?: string;
  menuIsOpen?: boolean;
  onInputChange?: (value: string) => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  onChange: (value: string) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  displayLimit?: number;
  clearOnSelect?: boolean;
  autoFocus?: boolean;
  lang?: boolean;
  helper?: string;
  hiddenLabel?: boolean;
}

export const BigidMeSelect: FC<BigidSelectProps> = ({
  label = '',
  id,
  className = '',
  placeholder = '',
  name = '',
  required,
  options = [],
  isDisabled = false,
  isSearchable = false,
  isClearable = false,
  autoFocus = false,
  error = '',
  value = null,
  defaultValue,
  menuIsOpen = false,
  onInputChange,
  onMenuOpen,
  onMenuClose,
  onChange,
  onKeyDown,
  displayLimit = 0,
  clearOnSelect = false,
  lang,
  helper,
  hiddenLabel,
  ...rest
}: BigidSelectProps) => {
  const { BigIdMeTranslate, language } = LanguageContainer.useContainer();
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const [currentValue, setCurrentValue] = useState<BigidSelectOption[] | null>([]);

  const classes = useClasses({ hiddenLabel });

  useEffect(() => {
    setIsOpened(menuIsOpen);
  }, [menuIsOpen]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value, language]);

  useEffect(() => {
    const defaultOption = options.find(({ value }) => value === defaultValue);
    defaultOption && setCurrentValue([defaultOption]);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  let visibleOptionsCount = 0;

  const handleOpen = () => {
    setIsOpened(true);
    onMenuOpen && onMenuOpen();
  };

  const handleClose = () => {
    setIsOpened(false);
    onMenuClose && onMenuClose();
  };

  const handleChange = (value: OnChangeValue<BigidSelectOption, boolean>) => {
    const newValue = [value as BigidSelectOption];
    setCurrentValue(clearOnSelect ? [] : newValue);
    setIsOpened(false);
    onChange && onChange(newValue[0].value);
  };

  const handleInput = (input: string) => {
    visibleOptionsCount = 0;
    onInputChange && onInputChange(input);
  };

  const filterOption = ({ label }: BigidSelectOption, input: string): boolean => {
    const isMatch = label?.toString().toLowerCase().includes(input.toLowerCase());

    return displayLimit > 0 ? isMatch && visibleOptionsCount++ < displayLimit : isMatch;
  };

  const formatOptionLabel = (
    { value, label, node }: BigidSelectOption,
    _labelMeta: FormatOptionLabelMeta<BigidSelectOption>,
  ): ReactNode => {
    return node || lang ? <span lang={value}>{label}</span> : <>{label}</>;
  };

  const theme = useTheme();
  const selectProps = {
    filterOption,
    styles: selectStyles(theme.breakpoints.values.sm),
    className,
    placeholder,
    name,
    inputId: id,
    options,
    isDisabled,
    isClearable,
    isSearchable,
    autoFocus,
    value: currentValue,
    menuIsOpen: isOpened,
    onInputChange: handleInput,
    onMenuOpen: handleOpen,
    onMenuClose: handleClose,
    onChange: handleChange,
    onKeyDown,
    formatOptionLabel,
    noOptionsMessage: () => BigIdMeTranslate('consumer_web_no_options'),
  };

  const renderOptions = useCallback(
    (props: OptionProps<BigidSelectOption, false, GroupBase<BigidSelectOption>>) => {
      const { innerProps } = props;
      return (
        <components.Option
          {...props}
          innerProps={{
            ...innerProps,
            ...e2e(rest['data-aid'], `-option_${innerProps.id?.match(/\d+$/)![0]}`),
          }}
        />
      );
    },
    [rest],
  );

  return (
    <FieldWrapper
      label={label}
      required={required}
      error={error}
      id={id}
      helper={helper}
      fullWidth
      data-aid={rest['data-aid']}
      hiddenLabel={hiddenLabel}
    >
      <div className={cx(classes.container, { [classes.containerWithLabel]: label })} {...e2e(rest['data-aid'])}>
        <Select
          {...selectProps}
          components={{
            Option: props => renderOptions(props),
            SingleValue: function SingleValue(props) {
              const { innerProps } = props;
              return (
                <components.SingleValue
                  {...props}
                  innerProps={{ ...innerProps, ...e2e(`${rest['data-aid']}-value`) }}
                />
              );
            },
          }}
        />
      </div>
    </FieldWrapper>
  );
};
