import { useEffect } from 'react';
import { TenantContainer } from '../state/tenantContainer';

const useRecaptchaVisibility = () => {
  const { tenant } = TenantContainer.useContainer();

  useEffect(() => {
    const styleId = 'hide-recaptcha-badge';
    let styleTag = document.getElementById(styleId) as HTMLStyleElement;

    if (!styleTag) {
      styleTag = document.createElement('style');
      styleTag.id = styleId;
      document.head.appendChild(styleTag);
    }

    if (!tenant.captchaOn) {
      styleTag.textContent = `.grecaptcha-badge { visibility: hidden; }`;
    } else {
      styleTag.textContent = '';
    }

    return () => {
      if (styleTag) {
        document.head.removeChild(styleTag);
      }
    };
  }, [tenant.captchaOn]);
};

export default useRecaptchaVisibility;
