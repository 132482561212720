import makeStyles from '@mui/styles/makeStyles';
import { gray, white } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: 960,
    margin: '40px auto',

    [theme.breakpoints.down('md')]: {
      width: '80%',
    },

    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  statusWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: gray[500],
    backgroundColor: white,
    padding: '80px 35px 50px 35px',
    width: '100%',
    borderRadius: 12,
    boxShadow: '0 2px 25px 0 rgba(0,0,0,0.1)',
  },

  statusTitle: {
    margin: '50px 0 8px 0',
    fontSize: '1.375rem',
  },
  statusSubTitle: {
    margin: '0 0 40px 0',
    fontSize: '1.125rem',
    fontWeight: 'normal',
    textAlign: 'center',
  },
}));
