import makeStyles from '@mui/styles/makeStyles';
import { black, errorColor, gray, linkText, white } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    backgroundColor: white,
    height: '100%',
  },
  layout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto 70px',
    maxWidth: 612,
    padding: '10px 32px',
    height: '100%',
  },
  icon: {
    marginBottom: 32,
  },
  status: {
    textAlign: 'center',
    marginBottom: 24,
  },
  text: {
    textAlign: 'center',
    marginBottom: 16,
  },
  button: {
    marginTop: 36,
  },
  extraBold: {
    fontWeight: 800,
  },
});

export const useStylesSubmitted = makeStyles({
  root: {
    display: 'flex',
    height: 'calc(100vh - 96px)',
    backgroundColor: white,
  },
  layout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '100%',
    backgroundColor: white,
    padding: '10px 32px',
  },
  title: {
    padding: '32px 0 24px',
  },
  text1: {
    padding: '0 0 56px 0',
    borderBottom: `1px solid ${gray[250]}`,
  },
  text2: {
    padding: '16px 0',
    '& table, th, td': {
      border: `1px solid ${gray[400]}`,
      padding: 8,
      textAlign: 'center',
    },
  },
  link: {
    cursor: 'pointer',
    color: linkText,
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    fontSize: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  disabledLink: {
    pointerEvents: 'none',
    color: black,
    opacity: 0.5,
  },
  resendError: {
    color: errorColor,
    whiteSpace: 'nowrap',
  },
});
