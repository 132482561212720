import { FormControl, Paper } from '@mui/material';
import { styled } from '@mui/styles';

export const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto',
  padding: '50px 0',
  minHeight: '100%',
  width: 1000,
});

export const StyledQuestionsWrapper = styled(Paper)(({ theme }) => ({
  borderRadius: 12,
  backgroundColor: theme.palette.grey[50],
  boxShadow: '0 2px 25px 0 rgba(0,0,0,0.1)',
  padding: '32px 45px',
  width: '100%',
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  marginTop: 24,
  paddingBottom: 22,
  '& .MuiFormGroup-root': {
    marginTop: 20,
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: theme.palette.text.primary,
  },
}));
