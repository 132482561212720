import { styled } from '@mui/styles';
import { messageBackgroundColor, gray } from '../../assets/theme/BigidMeColors';

const PREFIX = 'Message';

export const classes = {
  richText: `${PREFIX}-richText`,
  wrapper: `${PREFIX}-wrapper`,
  message: `${PREFIX}-message`,
  avatar: `${PREFIX}-avatar`,
  avatarImg: `${PREFIX}-avatarImg`,
  messageSubject: `${PREFIX}-messageSubject`,
  messageInfo: `${PREFIX}-messageInfo`,
  filesContainer: `${PREFIX}-filesContainer`,
  fileContainer: `${PREFIX}-fileContainer`,
  messageFileName: `${PREFIX}-messageFileName`,
  icon: `${PREFIX}-icon`,
  disabled: `${PREFIX}-disabled`,
};

export const Root = styled('div')(({ theme }) => ({
  marginBottom: 20,

  [`& .${classes.wrapper}`]: {
    fontSize: '1.125rem',
    display: 'flex',
    borderBottom: `1px solid ${gray[275]}`,
    alignItems: 'start',
    paddingBottom: 10,
  },
  [`& .${classes.richText}`]: {
    fontSize: '0.875rem',
    margin: '10px 0',
    '& table, th, td': {
      border: `1px solid ${theme.palette.grey[400]}`,
      padding: 8,
      textAlign: 'center',
    },
  },
  [`& .${classes.messageInfo}`]: {
    fontSize: '0.875rem',

    [theme.breakpoints.down('sm')]: {
      color: messageBackgroundColor,
    },
  },
  [`& .${classes.filesContainer}`]: {
    display: 'flex',
    marginBottom: 10,
    flexDirection: 'column',
  },
  [`& .${classes.fileContainer}`]: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      opacity: 0.6,
    },
  },
  [`& .${classes.messageFileName}`]: {
    borderBottom: `1px solid ${gray[700]}`,
    fontSize: '0.875rem',
  },
  [`& .${classes.icon}`]: {
    height: 16,
    marginRight: 10,
  },
  [`& .${classes.messageSubject}`]: {
    fontWeight: '700',
    marginBottom: 10,
    marginTop: 0,
  },
  [`& .${classes.avatar}`]: {
    marginTop: 3,
    marginRight: 10,
    width: 18,
    height: 18,
  },
  [`& .${classes.avatarImg}`]: {
    width: 18,
    height: 18,
  },
  [`& .${classes.disabled}`]: {
    pointerEvents: 'none',
    opacity: 0.4,
  },
}));
