import React, { FC } from 'react';
import { useTheme } from '@mui/material';
import { LanguageContainer } from '../../state/languageContainer';

export const BigidMeIconSuccess: FC<{ title?: string }> = ({ title }) => {
  const theme = useTheme();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  return (
    <svg
      role="img"
      aria-labelledby="success"
      width="53"
      height="52"
      viewBox="0 0 53 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="success">{title || BigIdMeTranslate('consumer_web_success') || 'Success'}</title>
      <circle cx="26.5" cy="26" r="25" stroke={theme.palette.bigidMe?.primary} strokeWidth="2" />
      <path
        d="M19.5 27.2359L24.8512 33L35.5 21"
        stroke={theme.palette.bigidMe?.primary}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
