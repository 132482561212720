import { LanguageContainer } from '../../../state/languageContainer';
import { useCallback } from 'react';
import {
  FieldValuesType,
  isAuthAgentFieldEnabledDefault,
  isAuthAgentFieldRequiredDefault,
} from '../../../utils/submitFields';
import {
  AuthorizedAgentFieldNameType,
  AuthorizedAgentFieldType,
  CountriesOptionsType,
  fieldValidationService,
  RequestDefinitionLayoutType,
  SelectOptionsCountriesType,
} from '../../../services';
import { BigidMeRadioGroup } from '../../../components/BigidMeRadioGroup';
import { BigidMeSelect, BigidMeTextField } from '../../../components';
import { BigidMePhoneField } from '../../../components/BigidMePhoneField';
import { cloneDeep, isEmpty } from 'lodash';
import { BigidMeUploadDocumentsField } from '../../../components/BigidMeUpdalodDocumentsField';
import { getAuthorizedAgentFieldType } from '../../../utils/helpers';

type AutorizedAgentProps = {
  fieldValues: FieldValuesType;
  countriesOptions: CountriesOptionsType[];
  fields: AuthorizedAgentFieldType[];
  layout?: RequestDefinitionLayoutType;
};

export const useAuthorizedAgent = ({ fields, countriesOptions, fieldValues, layout }: AutorizedAgentProps) => {
  const { BigIdMeTranslate, language } = LanguageContainer.useContainer();

  const getCountryDivisionsByCountryCode = useCallback(
    (fieldValues: FieldValuesType) => {
      const country = countriesOptions.find(
        ({ value }: SelectOptionsCountriesType) => fieldValues[AuthorizedAgentFieldNameType.COUNTRY] === value,
      );

      return country ? country.divisions : [];
    },
    [countriesOptions],
  );

  const isFieldFullWidthDefault = layout === RequestDefinitionLayoutType.Default;

  const fieldTypes = {
    [AuthorizedAgentFieldNameType.AUTHORIZED_AGENT_TYPE]: {
      component: BigidMeRadioGroup,
      isVisible: isAuthAgentFieldEnabledDefault,
      isFullWidth: true,
      isRequired: isAuthAgentFieldRequiredDefault,
      optionsSource: ({ field }: { field?: AuthorizedAgentFieldType }) =>
        (field?.options || []).map(field => ({
          label: field.translations[language]?.title || '',
          value: field.name,
        })),
      group: undefined,
    },
    [AuthorizedAgentFieldNameType.AGENT_FIRST_NAME_INDIVIDUAL]: {
      component: BigidMeTextField,
      isVisible: isAuthAgentFieldEnabledDefault,
      isFullWidth: false,
      isRequired: isAuthAgentFieldRequiredDefault,
      optionsSource: () => [],
      group: 'agentNameIndividual',
    },
    [AuthorizedAgentFieldNameType.AGENT_LAST_NAME_INDIVIDUAL]: {
      component: BigidMeTextField,
      isVisible: isAuthAgentFieldEnabledDefault,
      isFullWidth: false,
      isRequired: isAuthAgentFieldRequiredDefault,
      optionsSource: () => [],
      group: 'agentNameIndividual',
    },
    [AuthorizedAgentFieldNameType.BUSINESS_NAME]: {
      component: BigidMeTextField,
      isVisible: isAuthAgentFieldEnabledDefault,
      isFullWidth: false,
      isRequired: isAuthAgentFieldRequiredDefault,
      optionsSource: () => [],
      group: 'businessName',
    },
    [AuthorizedAgentFieldNameType.BUSINESS_ENTITY_NUMBER]: {
      component: BigidMeTextField,
      isVisible: isAuthAgentFieldEnabledDefault,
      isFullWidth: false,
      isRequired: isAuthAgentFieldRequiredDefault,
      optionsSource: () => [],
      group: 'businessName',
    },
    [AuthorizedAgentFieldNameType.AGENT_FIRST_NAME_BUSINESS]: {
      component: BigidMeTextField,
      isVisible: isAuthAgentFieldEnabledDefault,
      isFullWidth: false,
      isRequired: isAuthAgentFieldRequiredDefault,
      optionsSource: () => [],
      group: 'agentBusinessName',
    },
    [AuthorizedAgentFieldNameType.AGENT_LAST_NAME_BUSINESS]: {
      component: BigidMeTextField,
      isVisible: isAuthAgentFieldEnabledDefault,
      isFullWidth: false,
      isRequired: isAuthAgentFieldRequiredDefault,
      optionsSource: () => [],
      group: 'agentBusinessName',
    },
    [AuthorizedAgentFieldNameType.PHONE_NUMBER]: {
      component: BigidMePhoneField,
      isVisible: isAuthAgentFieldEnabledDefault,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isAuthAgentFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
    [AuthorizedAgentFieldNameType.MOBILE_PHONE_NUMBER]: {
      component: BigidMePhoneField,
      isVisible: isAuthAgentFieldEnabledDefault,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isAuthAgentFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
    [AuthorizedAgentFieldNameType.EMAIL]: {
      component: BigidMeTextField,
      isVisible: isAuthAgentFieldEnabledDefault,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isAuthAgentFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
    [AuthorizedAgentFieldNameType.COUNTRY]: {
      component: BigidMeSelect,
      optionsSource: () => countriesOptions,
      isVisible: isAuthAgentFieldEnabledDefault,
      isFullWidth: false,
      isRequired: isAuthAgentFieldRequiredDefault,
      group: 'country',
    },
    [AuthorizedAgentFieldNameType.STATE]: {
      component: BigidMeSelect,
      isVisible: ({ fieldValues }: { fieldValues: FieldValuesType }) =>
        fieldValues.country && !isEmpty(getCountryDivisionsByCountryCode(fieldValues)),
      optionsSource: () => (fieldValues.country ? getCountryDivisionsByCountryCode(fieldValues) : []),
      isFullWidth: false,
      isRequired: () => true,
      group: 'country',
    },
    [AuthorizedAgentFieldNameType.NATIONAL_Id]: {
      component: BigidMeTextField,
      isVisible: isAuthAgentFieldEnabledDefault,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isAuthAgentFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
    [AuthorizedAgentFieldNameType.UPLOAD_DOCUMENTS]: {
      component: BigidMeUploadDocumentsField,
      isVisible: isAuthAgentFieldEnabledDefault,
      isFullWidth: true,
      isRequired: isAuthAgentFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
    [AuthorizedAgentFieldNameType.DEFAULT]: {
      component: BigidMeTextField,
      isVisible: isAuthAgentFieldEnabledDefault,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isAuthAgentFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
  };

  const validateFields = (values: FieldValuesType) =>
    fieldValidationService.validateAuthorizedAgentFields(
      (fields || []).map(field => {
        const fieldType = getAuthorizedAgentFieldType(field, fieldTypes);
        const newFieldDefinition = cloneDeep(field);
        if (
          fieldTypes[fieldType].isRequired({ field, fieldValues: values }) &&
          fieldTypes[fieldType].isVisible({ field, fieldValues: values })
        ) {
          newFieldDefinition.required = true;
        } else {
          newFieldDefinition.required = false;
        }

        return newFieldDefinition;
      }),
      values,
      BigIdMeTranslate,
      language,
    );

  return {
    validateFields,
    fieldTypes,
  };
};
