import { useCallback } from 'react';
import { FieldValuesType, isUserFieldEnabledDefault, isUserFieldRequiredDefault } from '../../../utils/submitFields';
import {
  CountriesOptionsType,
  DeliveryMethodTypes,
  FieldDefinition,
  FieldsType,
  fieldValidationService,
  RequestDefinition,
  RequestDefinitionLayoutType,
  SelectOptionsCountriesType,
} from '../../../services';
import { BigidMeSelect, BigidMeTextField } from '../../../components';
import { BigidMePhoneField } from '../../../components/BigidMePhoneField';
import { cloneDeep, isEmpty, map } from 'lodash';
import { BigidMePhotoField } from '../../../components/BigidMePhotoField';
import { DeliveryMethod } from '../DeliveryMethod';
import { BigidMeDatePicker } from '../../../components/BigidMeDatePicker';
import { LanguageContainer } from '../../../state/languageContainer';
import { UserProfile } from '../../../services/userprofile/types';
import { getFieldDefinitionType } from '../../../utils/helpers';
import { OriginalRequestIdField } from '../OriginalRequestIdField';

type UserDetailsType = {
  fieldValues: FieldValuesType;
  countriesOptions: CountriesOptionsType[];
  userProfiles: UserProfile[];
  fieldDefinitions: FieldDefinition[];
  selectedRequestDefinition?: RequestDefinition;
};

export const useUserDetails = ({
  countriesOptions,
  fieldValues,
  userProfiles,
  fieldDefinitions,
  selectedRequestDefinition,
}: UserDetailsType) => {
  const { BigIdMeTranslate, language } = LanguageContainer.useContainer();

  const getCountryDivisionsByCountryCode = useCallback(
    (fieldValues: FieldValuesType) => {
      const country = countriesOptions.find(
        ({ value }: SelectOptionsCountriesType) => fieldValues[FieldsType.USER_COUNTRY] === value,
      );

      return country ? country.divisions : [];
    },
    [countriesOptions],
  );

  const isFieldFullWidthDefault = selectedRequestDefinition?.layout === RequestDefinitionLayoutType.Default;

  const fieldTypes = {
    [FieldsType.USER_EMAIL]: {
      component: BigidMeTextField,
      isVisible: isUserFieldEnabledDefault,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isUserFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
    [FieldsType.USER_LINKEDIN_ACCOUNT]: {
      component: BigidMeTextField,
      isVisible: () => true,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isUserFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
    [FieldsType.USER_NAME]: {
      component: BigidMeTextField,
      isVisible: () => true,
      isFullWidth: false,
      isRequired: isUserFieldRequiredDefault,
      optionsSource: () => [],
      group: 'userName',
    },
    [FieldsType.USER_LASTNAME]: {
      component: BigidMeTextField,
      isVisible: () => true,
      isFullWidth: false,
      isRequired: isUserFieldRequiredDefault,
      optionsSource: () => [],
      group: 'userName',
    },
    [FieldsType.USER_PHONE]: {
      component: BigidMePhoneField,
      isVisible: isUserFieldEnabledDefault,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isUserFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
    [FieldsType.USER_COUNTRY]: {
      component: BigidMeSelect,
      optionsSource: () => countriesOptions,
      isVisible: () => true,
      isFullWidth: false,
      isRequired: isUserFieldRequiredDefault,
      group: 'country',
    },
    [FieldsType.USER_COUNTRY_DIVISION]: {
      component: BigidMeSelect,
      isVisible: ({ fieldValues }: { fieldValues: FieldValuesType }) =>
        fieldValues.userCountry && !isEmpty(getCountryDivisionsByCountryCode(fieldValues)),
      optionsSource: () => (fieldValues.userCountry ? getCountryDivisionsByCountryCode(fieldValues) : []),
      isFullWidth: false,
      isRequired: () => true,
      group: 'country',
    },
    [FieldsType.USER_TYPE]: {
      component: BigidMeSelect,
      optionsSource: () => {
        return (
          userProfiles?.map(p => ({
            value: p.name,
            label: BigIdMeTranslate(`consumer_web_general_${p.name.toLowerCase().replace(/\s/g, '-')}`) || p.name,
          })) || []
        );
      },
      isVisible: () => true,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isUserFieldRequiredDefault,
      group: undefined,
    },
    [FieldsType.SINGLE_SELECTION]: {
      component: BigidMeSelect,
      optionsSource: (def: FieldDefinition) =>
        def?.translations[language] &&
        map(def?.translations[language].options, o => ({
          value: o,
          label: o,
        })),
      isVisible: () => true,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isUserFieldRequiredDefault,
      group: undefined,
    },
    [FieldsType.USER_PHOTO]: {
      component: BigidMePhotoField,
      isVisible: () => true,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isUserFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
    [FieldsType.DELIVERY_METHOD]: {
      component: DeliveryMethod,
      isVisible: () => true,
      isFullWidth: true,
      isRequired: isUserFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
    [FieldsType.ADDRESS_LINE_1]: {
      component: BigidMeTextField,
      isVisible: () => true,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isUserFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
    [FieldsType.ADDRESS_LINE_2]: {
      component: BigidMeTextField,
      isVisible: () => true,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isUserFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
    [FieldsType.CITY]: {
      component: BigidMeTextField,
      isVisible: () => true,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isUserFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
    [FieldsType.ZIPCODE]: {
      component: BigidMeTextField,
      isVisible: () => true,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isUserFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
    [FieldsType.CORRECTION_FIELD]: {
      component: BigidMeTextField,
      isVisible: () => true,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isUserFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
    [FieldsType.DATE]: {
      component: BigidMeDatePicker,
      isVisible: () => true,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isUserFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
    [FieldsType.ORIGINAL_REQUEST_ID]: {
      component: OriginalRequestIdField,
      isVisible: () => true,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isUserFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
    [FieldsType.DEFAULT]: {
      component: BigidMeTextField,
      isVisible: () => true,
      isFullWidth: isFieldFullWidthDefault,
      isRequired: isUserFieldRequiredDefault,
      optionsSource: () => [],
      group: undefined,
    },
  };

  const validateFields = (values: FieldValuesType) =>
    fieldValidationService.validate(
      fieldDefinitions.map(field => {
        const fieldType = getFieldDefinitionType(field, fieldTypes);
        const newFieldDefinition = cloneDeep(field);
        if (
          fieldTypes[fieldType].isRequired({ fieldDefinitions, fieldValues: values, field }) &&
          fieldTypes[fieldType].isVisible({ fieldDefinitions, fieldValues: values, field })
        ) {
          if (values.deliveryMethod === DeliveryMethodTypes.physicalMail) {
            newFieldDefinition.type.constraints.physicalRequired = {};
          } else {
            newFieldDefinition.type.constraints.required = {};
          }
        } else {
          if (values.deliveryMethod === DeliveryMethodTypes.physicalMail) {
            delete newFieldDefinition.type.constraints.physicalRequired;
          } else {
            delete newFieldDefinition.type.constraints.required;
          }
        }

        return newFieldDefinition;
      }),
      values,
      BigIdMeTranslate,
      language,
    );

  return {
    validateFields,
    fieldTypes,
  };
};
