import React, { ChangeEvent, FC, InputHTMLAttributes } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import classnames from 'classnames';
import { WithTest, e2e } from '../../utils';
import { ReactComponent as BigidMeIconRadioEmpty } from '../../assets/icons/Radio_off_black.svg';
import { ReactComponent as BigidMeIconRadioSelected } from '../../assets/icons/Radio_on_black.svg';

import { useStyles } from './BigidMeOptOutCheckboxStyles';

export type BigidMeOptOutCheckboxType = WithTest & {
  id?: string;
  checked: boolean;
  isNegativeRadio?: boolean;
  onChange: (checked: boolean) => void;
  testId?: string;
  className?: string;
};

export const BigidMeOptOutCheckbox: FC<BigidMeOptOutCheckboxType> = ({
  id,
  checked,
  onChange,
  testId,
  className,
  isNegativeRadio,
}) => {
  const classes = useStyles();
  const props = {
    id,
    onChange: ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
      onChange(isNegativeRadio ? !checked : checked),
    className: classes.input,
    icon: <BigidMeIconRadioEmpty />,
    checkedIcon: <BigidMeIconRadioSelected />,
    inputProps: { ...e2e(testId), ['aria-labelledby']: `${id}-label` } as InputHTMLAttributes<HTMLInputElement>,
  };

  return (
    <FormControlLabel
      className={classnames(className, classes.item)}
      control={
        checked === null || checked === undefined ? (
          <Checkbox {...props} />
        ) : (
          <Checkbox checked={isNegativeRadio ? !checked : checked} {...props} />
        )
      }
      label=""
    />
  );
};
