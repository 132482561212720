import React, { FC, useEffect } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as BigidMeIconCancel } from '../../assets/icons/cancel-icon.svg';
import { BigidMeWireframe } from '../../components';
import { TenantContainer } from '../../state/tenantContainer';
import { useStylesAction } from './ErrorsStyles';
import { LanguageContainer } from '../../state/languageContainer';

export const RequestMessageError: FC = () => {
  const { tenant, loadTenantSettings } = TenantContainer.useContainer();
  const classes = useStylesAction();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  useEffect(() => {
    loadTenantSettings({});
  }, [loadTenantSettings]);

  return (
    <BigidMeWireframe {...tenant} pageTitle={BigIdMeTranslate('consumer_web_error')}>
      <div className={classes.container}>
        <BigidMeIconCancel title={BigIdMeTranslate('consumer_web_cancel')} />
        <Typography variant="h1" color="textPrimary" className={classes.title}>
          {BigIdMeTranslate('consumer_web_messages_this_request_is_closed')}
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center" className={classes.text}>
          {BigIdMeTranslate('consumer_web_messages_email_history_is_unavailable')}
        </Typography>
      </div>
    </BigidMeWireframe>
  );
};
