import React, { useState } from 'react';
import { FileDrop } from 'react-file-drop';
import { useStyles } from './FileUploaderLinkStyles';
import { BigidMeTooltip } from '../BigidMeTooltip';
import { useResponsive } from '../../hooks/useResponsive';
import { MeButton } from '../MeButton';
import { LanguageContainer } from '../../state/languageContainer';

const KILOBYTE = 1024;
export interface FileUploaderLinkPropsType {
  format: string;
  isDisabled?: boolean;
  tooltip?: string;
  maxSize?: number;
  maxFiles?: number;
  testId?: string;
  onSelect: (filename: string, file: File) => void;
}

export const FileUploaderLink = ({
  onSelect,
  format,
  isDisabled,
  maxSize = 10,
  maxFiles = 5,
  testId,
  tooltip,
}: FileUploaderLinkPropsType) => {
  const [uploadError, setUploadErrorStatus] = useState('');
  const classes = useStyles();
  const isMobile = useResponsive();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  const onSelectFile = (files: FileList | null) => {
    if (!files) return;

    if (files[0].size / (KILOBYTE * KILOBYTE) > maxSize) {
      const error = `Maximum file size: ${maxSize}MB per file.`;
      setUploadErrorStatus(error);
      return;
    }

    const formatRegExp = new RegExp(`(${format.replace(/,\s?/g, '|')})$`, 'i');
    if (!formatRegExp.test(files[0].name)) {
      const error = 'Wrong file format';
      setUploadErrorStatus(error);
      return;
    }
    onSelect(files[0].name, files[0]);
    setUploadErrorStatus('');
  };

  const onFileChange = (event: { target: { files: FileList | null; value: string } }) => {
    onSelectFile(event.target.files);
    event.target.value = '';
  };

  return (
    <label className={classes.root}>
      <FileDrop onDrop={files => onSelectFile(files)}>
        {isMobile ? (
          <MeButton type="secondary" data-aid="submit" bi={{ eventType: 'mobile_upload_file' }}>
            {BigIdMeTranslate('consumer_web_messages_upload_file')}
          </MeButton>
        ) : (
          <span className={classes.text}>{BigIdMeTranslate('consumer_web_messages_upload_file')}</span>
        )}
        {tooltip && !isMobile && <BigidMeTooltip title={tooltip} />}
        <input
          type="file"
          disabled={isDisabled}
          data-aid={testId || 'file-upload'}
          accept={format}
          multiple={false}
          className={classes.upload}
          onChange={onFileChange}
        />
        {uploadError && <div className={classes.error}>{uploadError}</div>}
        {isDisabled && <div className={classes.error}>Max. {maxFiles} files</div>}
      </FileDrop>
    </label>
  );
};
