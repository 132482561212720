import makeStyles from '@mui/styles/makeStyles';
import { linkText, gray } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.125rem',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: 800,
    },
  },
  divider: {
    backgroundColor: gray[275],
    margin: '20px 0',

    [theme.breakpoints.down('sm')]: {
      margin: '9px 0 16px 0',
    },
  },
  link: {
    color: linkText,
    fontWeight: 600,
  },
  select: {
    width: '100% !important',
    maxWidth: '50%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  otherReasonLabel: {
    margin: '20px 0 -20px',
  },
  otherReasonInput: {
    maxWidth: 475,
    width: '100% !important',
    height: '75px !important',
    borderRadius: 8,
    '& > textarea': {
      height: '60px !important',
    },
  },
  clear: {
    marginRight: 20,
    fontWeight: 700,
    color: `${gray[650]} !important`,
    fontSize: '0.8125rem',
    minWidth: 20,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.15) !important',
    },
  },
}));
