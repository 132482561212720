import React, { FC, useEffect } from 'react';
import { Typography } from '@mui/material';
import cx from 'classnames';

import { ReactComponent as BigidMeLocaleRed } from '../../assets/icons/icon-locale-red.svg';

import { useStylesSubmitted } from './BigidMeRequestStatusStyles';
import { OnScreenMessageTranslationDto } from '../../services';
import { LanguageContainer } from '../../state/languageContainer';
import { parseHTML } from '../../utils';

export type BigidMeAllowOutsideRequestorsPropsType = {
  onScreenMessage?: { [key: string]: OnScreenMessageTranslationDto };
  className?: string;
};

export const BigidMeAllowOutsideRequestors: FC<BigidMeAllowOutsideRequestorsPropsType> = ({
  onScreenMessage,
  className,
}) => {
  const classes = useStylesSubmitted();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  let resendBlockingTimerId: ReturnType<typeof setInterval>;

  useEffect(
    () => () => {
      resendBlockingTimerId && clearInterval(resendBlockingTimerId);
    },
    [],
  );
  const getLang = (): string => {
    const lang = localStorage.getItem('lang');
    return lang == null ? 'en' : lang;
  };
  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.layout}>
        <BigidMeLocaleRed title={BigIdMeTranslate('consumer_web_error')} />
        <div className={classes.title}>
          <Typography variant="h1" color="textPrimary" align="center" data-aid="onscreen-title">
            {onScreenMessage?.[getLang()].title}
          </Typography>
        </div>
        <div className={classes.text2}>
          <div>
            <Typography variant="subtitle2" color="textPrimary" align="center" gutterBottom data-aid="onscreen-message">
              {parseHTML(onScreenMessage?.[getLang()].message || '', true)}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
