import makeStyles from '@mui/styles/makeStyles';
import { gray, white } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles(theme => ({
  tooltip: {
    maxWidth: 200,
    whiteSpace: 'pre-line',
    filter: 'drop-shadow(0 6px 12px rgba(0,0,0,0.09)) drop-shadow(0 0 1px rgba(0,0,0,0.5))',
    borderRadius: 7,
    backgroundColor: white,
    fontWeight: 600,
    color: gray[730],
    fontSize: '0.75rem',
    textAlign: 'center',
    '& > span': {
      color: white,
    },
  },
  tooltipButton: {
    position: 'relative',
    padding: 0,
    marginLeft: 5,
    zIndex: 1,

    '&:focus': {
      outline: '1px solid #888',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 12,

      '& > span > svg': {
        width: 20,
        height: 20,
      },
    },
  },
}));
