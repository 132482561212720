import {
  AuthorizedAgentFieldNameType,
  AuthorizedAgentFieldType,
  DeliveryMethodTypes,
  FieldDefinition,
  FieldsType,
  FieldValue,
} from '../services';

export type SetTouchedFieldsCallback = (touchedFields: Record<string, boolean>) => Record<string, boolean>;

export type FieldValuesType = Record<string, FieldValue>;

export type UserFieldFunctionType = (params: {
  fieldDefinitions: FieldDefinition[];
  fieldValues: FieldValuesType;
  field: FieldDefinition;
}) => boolean;

export const isUserFieldRequiredDefault: UserFieldFunctionType = ({ field, fieldDefinitions, fieldValues }) => {
  const isDeliveryMethodEnabled = fieldDefinitions.some(field => field.id === FieldsType.DELIVERY_METHOD);

  if (isDeliveryMethodEnabled) {
    if (fieldValues.deliveryMethod === DeliveryMethodTypes.physicalMail)
      return !!field.type.constraints?.physicalRequired;
  }

  return !!field.type.constraints?.required;
};

export const isUserFieldEnabledDefault: UserFieldFunctionType = ({ field, fieldDefinitions, fieldValues }) => {
  if (field.enabled === undefined) return true;

  const isDeliveryMethodEnabled = fieldDefinitions.some(field => field.id === FieldsType.DELIVERY_METHOD);

  if (isDeliveryMethodEnabled) {
    if (fieldValues.deliveryMethod === DeliveryMethodTypes.physicalMail) return !!field.enabledForPhysicalMail;
  }

  return !!field.enabled;
};

export type AuthAgentFieldFunctionType = (params: {
  field: AuthorizedAgentFieldType;
  fieldValues: FieldValuesType;
}) => boolean;

export const isAuthAgentFieldRequiredDefault: AuthAgentFieldFunctionType = ({ field }) => {
  return !!field.required;
};

export const isAuthAgentFieldEnabledDefault: AuthAgentFieldFunctionType = ({ field, fieldValues }) => {
  const authorizedAgentType = fieldValues[AuthorizedAgentFieldNameType.AUTHORIZED_AGENT_TYPE];
  if (field.authorizedAgentType) {
    return field.authorizedAgentType === authorizedAgentType;
  }
  return !!field.enabled;
};
