import makeStyles from '@mui/styles/makeStyles';
import { white, gray, blue } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles({
  selectContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectInput: {
    margin: '0 !important',
    fontSize: '0.8125rem',
    fontWeight: 'bold',
    borderRadius: 20,
    '& > div:first-child': {
      backgroundColor: white,
      padding: '7px 35px 5px 10px',
    },
    '& > fieldset': {
      border: `1px solid ${blue[750]} !important`,
    },
    '& > svg': {
      fill: blue[750],
    },
  },
  selectMenu: {
    borderRadius: 12,
    marginTop: 44,
    backgroundColor: white,
  },
  placeholderMenuItem: {
    display: 'none',
  },
  selectMenuItem: {
    fontSize: '0.8125rem',
    color: gray[775],
  },
});
