export const statusDetails = {
  success: {
    title: 'consumer_web_status_identity-experian-verified',
    description: 'consumer_web_status_identity-experian-verified-description',
  },
  failed: {
    title: 'consumer_web_status_request-declined',
    description: 'consumer_web_status_request-declined-description',
  },
  manual: {
    title: 'consumer_web_status_request-received',
    description: 'consumer_web_status_experian-request-received-description',
  },
};
