import React, { FC } from 'react';
import { BigidMeRequestInfoWrapper } from '../../components/BigidMeRequestInfoWrapper';
import { LanguageContainer } from '../../state/languageContainer';

export const ConsumerDataAlreadyUpdated: FC = () => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  return (
    <BigidMeRequestInfoWrapper
      componentNaming="ConsumerDataAlreadyUpdated"
      pageTitle={BigIdMeTranslate('page_title_view_data_updated')}
    >
      {BigIdMeTranslate('consumer_web_errors_submit-another')}
    </BigidMeRequestInfoWrapper>
  );
};
