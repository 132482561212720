import React, { useEffect, useState } from 'react';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { BigidLoader, BigidTextField, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { Box, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { BigidMeWireframe } from '../../../components';
import { TenantContainer } from '../../../state/tenantContainer';
import { vendorService } from '../../../services/vendor/vendorService';
import { AppTheme } from '../../../theme/AppTheme';
import { VendorRequestView } from '../VendorRequestView/VendorRequestView';
import { ActiveViewType } from '../types';
import { views } from '../consts';
import { getViewByErrorCode } from '../utils';

const schema = yup
  .object({
    email: yup.string().email('Incorrect email').required('The field is required'),
  })
  .required();

export const VerifyEmail = () => {
  const { tenant, loadTenantSettings } = TenantContainer.useContainer();
  const { vendorRequestId = '', masterSecret = '' } = useParams();
  const [activeView, setActiveView] = useState<ActiveViewType>('form');
  const [verifiedEmail, setVerifiedEmail] = useState('');
  const [loading, setLoading] = useState(true);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<{ email: string }>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = handleSubmit(async ({ email }) => {
    try {
      await vendorService.verifyEmail(vendorRequestId, { masterSecret, email });
      setVerifiedEmail(email);
      setActiveView('success');
    } catch (error) {
      const code = (error as AxiosError<{ errorCode: number }>)?.response?.data?.errorCode;
      setActiveView(code ? getViewByErrorCode(code) : 'failed');
    }
  });

  useEffect(() => {
    const initLoad = async () => {
      try {
        setLoading(true);
        await loadTenantSettings({});
      } catch (error) {
        setActiveView('failed');
      } finally {
        setLoading(false);
      }
    };
    initLoad();
  }, [loadTenantSettings]);

  if (loading) {
    return (
      <CssVarsProvider theme={AppTheme}>
        <BigidLoader />
      </CssVarsProvider>
    );
  }

  return (
    <CssVarsProvider theme={AppTheme}>
      <BigidMeWireframe {...tenant} hideLanguageControls pageTitle="Verify email">
        <Stack alignItems="center" justifyContent="center" height="100%">
          <VendorRequestView
            title={views[activeView].title}
            description={views[activeView].description || views[activeView].generateDescription?.(verifiedEmail)}
            icon={views[activeView].icon}
          >
            {activeView === 'form' && (
              <>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState, formState }) => (
                    <BigidTextField
                      type="email"
                      placeholder="Email"
                      value={field.value}
                      disabled={formState.isSubmitting}
                      onBlur={field.onBlur}
                      onChange={e => field.onChange(e.target.value)}
                      errorMessage={fieldState.error?.message}
                    />
                  )}
                />
                {isSubmitting && <BigidLoader />}
                <Box width="100%" textAlign="center">
                  <PrimaryButton
                    dataAid="verifyEmailSubmitButton"
                    size="large"
                    width="fill"
                    text="Continue"
                    onClick={onSubmit}
                  />
                </Box>
              </>
            )}
            {views[activeView].showTryAgain && (
              <SecondaryButton
                size="large"
                text="Try again"
                onClick={() => setActiveView('form')}
                dataAid="verifyEmailTryAgainButton"
              />
            )}
          </VendorRequestView>
        </Stack>
      </BigidMeWireframe>
    </CssVarsProvider>
  );
};
