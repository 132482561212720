import makeStyles from '@mui/styles/makeStyles';
import { gray } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles({
  languageWrapper: {
    margin: '15px 0',
  },
  languageMenu: {
    paddingLeft: 40,
    margin: '10px 0',
    borderTop: `1px solid ${gray[275]}`,
    fontSize: '0.8125rem',
    background: gray[75],

    maxHeight: 0,
    transition: 'max-height 0.15s ease-out',
    overflow: 'scroll',
  },
  languageMenuOpen: {
    maxHeight: 200,
    transition: 'max-height 0.25s ease-in',
    borderBottom: `1px solid ${gray[275]}`,
  },
  langItem: {
    padding: '6px 0',
    listStyle: 'none',
    cursor: 'pointer',
    '&:first-child': {
      paddingTop: 12,
    },
    '&:last-child': {
      paddingBottom: 12,
    },
  },
  checked: {
    color: gray[730],
    fontWeight: 'bold',
  },
  currentLanguage: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
});
