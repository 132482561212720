import React, { FC, useCallback, useEffect, useState } from 'react';
import { Divider, FormGroup, RadioGroup, Typography } from '@mui/material';
import {
  ManagePreferencesSectionsType,
  ManagePreferencesSectionTypes,
  ManagePreferencesItemType,
  SectionDefaultType,
} from '../../services';
import { BigidMeTooltip } from '../BigidMeTooltip';
import { BigidMeCheckbox } from '../BigidMeCheckbox';
import { BigidMeToggle } from '../BigidMeToggle';
import { BigidMeRadio } from '../BigidMeRadio';
import { BigidMeOptOutRadio } from '../BigidMeOptOutRadio';
import {
  setRadioSectionSelectionForPreview,
  updateManagePreferencesDataOptOutSection,
  updateManagePreferencesDataSection,
} from '../../utils/helpers';
import { e2e } from '../../utils';

import { useStyles } from './BigidMePreferencesStyles';
import { BigidMeOptOutCheckbox } from '../BigidMeOptOutCheckbox';
import { parseHTML } from '../../utils';

export interface BigidMePreferencesType {
  sections: ManagePreferencesSectionsType[];
  isPreviewPP?: boolean;
  onChange: (data: ManagePreferencesSectionsType[]) => void;
}

export const BigidMePreferences: FC<BigidMePreferencesType> = ({ sections, isPreviewPP, onChange }) => {
  const [sectionsData, setSections] = useState(sections);
  const classes = useStyles();

  useEffect(() => {
    initSectionsDefault();
    function initSectionsDefault() {
      if (!isPreviewPP) {
        setSections(sections);
        return;
      }

      const previewSections = sections.map(section => ({
        ...section,
        items:
          section.type === ManagePreferencesSectionTypes.RADIO
            ? section.items
            : setRadioSectionSelectionForPreview(
                section.items,
                section.sectionDefault === SectionDefaultType.NONE
                  ? null
                  : section.sectionDefault === SectionDefaultType.POSITIVE,
              ),
      }));

      setSections(previewSections);
    }
  }, [sections, isPreviewPP]);

  const handleAllClick = (sectionIndex: number, checked: boolean) => {
    onChange(updateManagePreferencesDataSection(sections, sectionIndex, () => checked));
  };

  const handleChange = (sectionIndex: number, itemIndex: number, checked: boolean) => {
    onChange(
      updateManagePreferencesDataSection(sections, sectionIndex, (index, item) =>
        index === itemIndex ? checked : (item.selected as boolean),
      ),
    );
  };

  const handleRadioChange = (sectionIndex: number, itemIndex: number) => {
    onChange(updateManagePreferencesDataSection(sections, sectionIndex, index => index === itemIndex));
  };

  const handleOptOutChange = (sectionIndex: number, itemIndex: number, enabled: boolean) => {
    onChange(updateManagePreferencesDataOptOutSection(sections, sectionIndex, itemIndex, enabled));
  };

  const findRadioCheckedValue = useCallback((data: ManagePreferencesItemType[]) => {
    return data.findIndex(({ selected }) => selected) || 0;
  }, []);

  return (
    <div className={classes.root}>
      {sectionsData.map(
        (
          {
            id,
            title,
            subtitle,
            hint,
            type,
            items,
            positiveEnabled,
            negativeEnabled,
            selectAllEnabled,
            positive,
            negative,
            selectAllLabel,
          },
          sectionIndex,
        ) => (
          <div key={`section-${sectionIndex}`} id={`section-${sectionIndex}`} data-aid={`section-${sectionIndex}`}>
            <Typography
              variant="h4"
              color="textSecondary"
              className={classes.title}
              gutterBottom
              {...e2e(`section-${id}_title`)}
            >
              {title}
              {hint && <BigidMeTooltip title={hint} {...e2e(`section-${id}_hint`)} />}
            </Typography>
            <Divider className={classes.divider} />
            {subtitle && type !== ManagePreferencesSectionTypes.OPT_LIST && (
              <Typography
                variant="subtitle1"
                color="textSecondary"
                className={classes.subtitle}
                {...e2e(`${type}_subtitle`)}
              >
                {parseHTML(subtitle)}
              </Typography>
            )}
            {type === ManagePreferencesSectionTypes.MULTI_SELECT && (
              <FormGroup className={classes.itemGroup}>
                {selectAllEnabled && (
                  <BigidMeCheckbox
                    id="all"
                    title={selectAllLabel || ''}
                    checked={items.filter(({ selected }) => selected).length === items.length}
                    onChange={checked => handleAllClick(sectionIndex, checked)}
                    testId="all_checkbox"
                  />
                )}
                {items.map(({ id: itemId, label, selected }, itemIndex) => (
                  <BigidMeCheckbox
                    id={itemId?.toString()}
                    key={itemId}
                    title={parseHTML(label)}
                    checked={selected as boolean}
                    onChange={checked => handleChange(sectionIndex, itemIndex, checked)}
                    testId={`section-${id}-item-${itemId}`}
                  />
                ))}
              </FormGroup>
            )}
            {type === ManagePreferencesSectionTypes.TOGGLE && (
              <FormGroup className={classes.itemGroup}>
                {items.map(({ id, label, description, selected }, itemIndex) => (
                  <div className={classes.toggleWrapper} key={id} id={id?.toString()}>
                    <div>
                      <Typography variant="subtitle1" color="textPrimary">
                        <b>{label}</b>
                      </Typography>
                      {description && (
                        <Typography
                          variant="subtitle1"
                          color="textPrimary"
                          className={classes.optionDescription}
                          id={`toggle-label-${id}`}
                        >
                          {parseHTML(description)}
                        </Typography>
                      )}
                    </div>
                    <BigidMeToggle
                      id={id?.toString()}
                      checked={selected as boolean}
                      onChange={(_, checked) => handleChange(sectionIndex, itemIndex, checked)}
                      aria-labelledby={`toggle-label-${id}`}
                      {...e2e(`toggle_${itemIndex}`)}
                    />
                  </div>
                ))}
              </FormGroup>
            )}
            {type === ManagePreferencesSectionTypes.RADIO && (
              <RadioGroup name={title} value={findRadioCheckedValue(items)} className={classes.itemGroup}>
                {items.map(({ id, label, description }, itemIndex) => (
                  <BigidMeRadio
                    id={id?.toString()}
                    key={id}
                    title={label}
                    value={itemIndex}
                    onChange={() => handleRadioChange(sectionIndex, itemIndex)}
                    description={description && parseHTML(description)}
                    className={classes.item}
                    descriptionClassaName={classes.optionDescription}
                    {...e2e(`radio_${itemIndex}`)}
                  />
                ))}
              </RadioGroup>
            )}
            {type === ManagePreferencesSectionTypes.OPT_LIST && (
              <>
                <div>
                  {positiveEnabled && negativeEnabled ? (
                    <>
                      <div className={classes.optoutLabelsWrapper}>
                        <Typography
                          variant="subtitle1"
                          color="textSecondary"
                          className={classes.subtitle}
                          {...e2e(`${type}_subtitle`)}
                        >
                          {subtitle && parseHTML(subtitle)}
                        </Typography>
                        <div className={classes.optoutLabelsWrapperRight}>
                          <span className={classes.optoutLabel}>{positive}</span>
                          <span className={classes.optoutLabel}>{negative}</span>
                        </div>
                      </div>

                      {items.map(({ id, label, selected }, itemIndex) => (
                        <RadioGroup name={title} key={id} className={classes.optoutGroupDouble}>
                          <div className={classes.optoutRadioLabel}>{label}</div>
                          <BigidMeOptOutRadio
                            checked={selected as boolean}
                            value={itemIndex}
                            onChange={() => handleOptOutChange(sectionIndex, itemIndex, true)}
                            className={classes.optoutDouble}
                            {...e2e(`optout_radio_${itemIndex}`)}
                          />
                          <BigidMeOptOutRadio
                            isNegativeRadio
                            checked={selected as boolean}
                            value={itemIndex}
                            onChange={() => handleOptOutChange(sectionIndex, itemIndex, false)}
                            className={classes.optoutDouble}
                            {...e2e(`optout_radio_${itemIndex}`)}
                          />
                        </RadioGroup>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className={classes.optoutSingleLabel}>{positiveEnabled ? positive : negative}</div>
                      {items.map(({ id, label, selected }, itemIndex) => (
                        <div className={classes.optoutGroupSingle} key={id}>
                          <label htmlFor={id?.toString()} className={classes.optoutRadioLabel}>
                            {label}
                          </label>
                          <BigidMeOptOutCheckbox
                            isNegativeRadio
                            id={id?.toString()}
                            key={id}
                            checked={selected as boolean}
                            onChange={checked => handleOptOutChange(sectionIndex, itemIndex, checked)}
                            className={classes.optoutSingle}
                            {...e2e(`optout_checkbox_${itemIndex}`)}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        ),
      )}
    </div>
  );
};
