import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BigidMeWireframe } from '../../components/BigidMeWireframe';
import { TenantContainer } from '../../state/tenantContainer';

import { Root, classes } from './RequestMessagesStyles';
import { BigidMeMessage } from '../../components/BigidMeMessage';
import { BigidMeSendMessage } from '../../components/BigidMeSendMessage';
import { MessageDto, requestService } from '../../services';
import { Typography } from '@mui/material';
import { e2e } from '../../utils';
import { BigidMeIconSuccess } from '../../assets/icons/BigidMeIconSuccess';
import { ReactComponent as BigidMeIconLeft } from '../../assets/icons/icon_left.svg';
import { ReactComponent as BigidMeIconArrowBack } from '../../assets/icons/arrowBackIcon.svg';
import { RequestId } from '../../components/RequestId';
import { LanguageContainer, LanguageType } from '../../state/languageContainer';
import { ErrorCodes } from '../../constants/errorCodes';
import { RedirectUrls } from '../../constants/routes';
import { useResponsive } from '../../hooks/useResponsive';
import cx from 'classnames';

enum ScreenViewMode {
  REPLY = 'REPLY',
  LIST = 'LIST',
  SUCCESS = 'SUCCESS',
}

export const RequestMessages = () => {
  const { requestId, messagesSecret, loc } = useParams();
  const { tenant, loadTenantSettings } = TenantContainer.useContainer();
  const [messages, setMessages] = useState<MessageDto[]>([]);
  const [mode, setMode] = useState<ScreenViewMode>(ScreenViewMode.LIST);
  const { setLanguage, BigIdMeTranslate } = LanguageContainer.useContainer();
  const navigate = useNavigate();
  const isMobile = useResponsive();

  const fetchMessages = useCallback(async () => {
    if (tenant.tenantId) {
      if (requestId && messagesSecret) {
        requestService
          .getRequestMessages(requestId, messagesSecret)
          .then(res => {
            setMessages(res);
            setLanguage(loc! as LanguageType);
          })
          .catch(e => {
            if (e.response?.data?.errorCode === ErrorCodes.MESSAGES_UNAVAILABLE) {
              navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}${RedirectUrls.REQUEST_MESSAGE_ERROR}`);
            } else {
              navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}${RedirectUrls.ACTION_ERROR}`);
            }
          });
      }
    }
  }, [requestId, messagesSecret, setLanguage, loc, navigate, tenant.tenantId]);

  useEffect(() => {
    loadTenantSettings({ requestId });
    fetchMessages();
  }, [fetchMessages, loadTenantSettings, requestId]);

  useEffect(() => {
    if (messages?.[0]?.toConsumer) {
      setMode(ScreenViewMode.REPLY);
    } else {
      setMode(ScreenViewMode.LIST);
    }
  }, [messages]);

  const renderMessageReply = () => (
    <div className={classes.wrapper}>
      <div className={classes.messageReply}>
        <BigidMeMessage message={messages[0]} testId="message-reply" />
      </div>
      {isMobile && messages.length > 1 && (
        <div className={classes.allCommunicationsButton} onClick={() => setMode(ScreenViewMode.LIST)}>
          {BigIdMeTranslate('consumer_web_messages_see_previous_messages')}
        </div>
      )}
      <BigidMeSendMessage
        subject={messages[0]?.subject}
        title={messages[0]?.title}
        handleMessageSent={() => setMode(ScreenViewMode.SUCCESS)}
        testId={'message-send'}
      />
      {!isMobile && messages.length > 1 && (
        <div className={classes.allCommunicationsButton} onClick={() => setMode(ScreenViewMode.LIST)}>
          {BigIdMeTranslate('consumer_web_messages_see_previous_messages')}
        </div>
      )}
    </div>
  );

  const renderMessageList = () => (
    <div className={classes.wrapper} data-aid="messages-list">
      {messages?.[0]?.toConsumer &&
        (isMobile ? (
          <div className={classes.backButtonMobile} onClick={() => setMode(ScreenViewMode.REPLY)}>
            <BigidMeIconArrowBack title={BigIdMeTranslate('consumer_web_back')} />
          </div>
        ) : (
          <div className={classes.backButton} onClick={() => setMode(ScreenViewMode.REPLY)}>
            <BigidMeIconLeft title={BigIdMeTranslate('consumer_web_back')} /> Back
          </div>
        ))}
      {messages.map(item => (
        <BigidMeMessage key={item.id} message={item} testId="message-list" inList />
      ))}
    </div>
  );

  const renderMessageSuccess = () => (
    <div className={classes.sentContainer}>
      <div className={classes.sentLayout}>
        <div className={classes.sentIcon}>{<BigidMeIconSuccess />}</div>
        <div className={classes.sentStatus}>
          <Typography variant="h1" color="textSecondary" {...e2e('request_status_title')}>
            {BigIdMeTranslate('consumer_web_messages_your_reply_was_sent')}
          </Typography>
        </div>
        <div className={classes.sentText}>
          <span
            className={classes.allCommunicationsButton}
            onClick={async () => {
              await fetchMessages();
              setMode(ScreenViewMode.LIST);
            }}
          >
            {BigIdMeTranslate('consumer_web_messages_see_previous_messages')}
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <BigidMeWireframe {...tenant}>
      <Root>
        <div className={classes.header}>
          <RequestId requestId={requestId || ''} />
        </div>
        <Typography variant="h3" className={classes.title}>
          {BigIdMeTranslate('consumer_web_messages_conversation_with')} {`${tenant.tenantName}`}
        </Typography>
        <div className={cx(classes.hideView, { [classes.showView]: mode === ScreenViewMode.REPLY })}>
          {renderMessageReply()}
        </div>
        <div className={cx(classes.hideView, { [classes.showView]: mode === ScreenViewMode.LIST })}>
          {renderMessageList()}
        </div>
        <div className={cx(classes.hideView, { [classes.showView]: mode === ScreenViewMode.SUCCESS })}>
          {renderMessageSuccess()}
        </div>
      </Root>
    </BigidMeWireframe>
  );
};
