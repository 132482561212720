import React, { ChangeEvent, FC, InputHTMLAttributes, ReactText } from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import classnames from 'classnames';
import { e2e, WithTest } from '../../utils';
import { ReactComponent as BigidMeIconRadioEmpty } from '../../assets/icons/Radio_off_black.svg';
import { ReactComponent as BigidMeIconRadioSelected } from '../../assets/icons/Radio_on_black.svg';

import { useStyles } from './BigidMeOptOutRadioStyles';

export type BigidMeOptOutRadioType = WithTest & {
  id?: string;
  isNegativeRadio?: boolean;
  onChange: (value: ReactText) => void;
  value?: ReactText;
  checked?: boolean;
  className?: string;
};

export const BigidMeOptOutRadio: FC<BigidMeOptOutRadioType> = ({
  id,
  checked,
  value,
  isNegativeRadio,
  className,
  onChange,
  ...rest
}) => {
  const classes = useStyles();

  const props = {
    disableRipple: true,
    onChange: ({ target: { value } }: ChangeEvent<HTMLInputElement>) => onChange(value),
    className: classes.input,
    icon: <BigidMeIconRadioEmpty />,
    checkedIcon: <BigidMeIconRadioSelected />,
    inputProps: { ...e2e(rest['data-aid']) } as InputHTMLAttributes<HTMLInputElement>,
    ...rest,
  };

  return (
    <FormControlLabel
      id={id}
      label=""
      control={
        checked === null || checked === undefined ? (
          <Radio value={isNegativeRadio ? !value : value} {...props} aria-labelledby={`${id}-label`} />
        ) : (
          <Radio checked={isNegativeRadio ? !checked : checked} {...props} aria-labelledby={`${id}-label`} />
        )
      }
      className={classnames(className, classes.item)}
    />
  );
};
